import { ICustomControl } from '@controls/interfaces/customcontrol';
import { ManifestMapper } from '@mappers/ManifestMapper';
import { ScriptLoader } from './ScriptLoader';
import { Manifest } from '@controls/interfaces/manifest';
import * as PlatformLibraryLoader from './PlatformLibraryLoader';

import * as TalxisPcfPortalTextField from '@controls/native/TextField';
import * as TalxisPcfPortalDateTime from '@controls/native/DateTime';
import * as TalxisPcfPortalLookup from '@controls/native/Lookup';
import * as TalxisPcfPortalView from '@controls/native/View';
import * as TalxisPcfPortalOptionSet from '@controls/native/OptionSet';
import * as TalxisPcfPortalMultiSelectOptionSet from '@controls/native/MultiSelectOptionSet';
import * as TalxisPcfPortalForm from '@controls/native/Form';
import * as TalxisPcfPortalTwoOptions from '@controls/native/TwoOptions';
import * as TalxisPcfPortalDecimal from '@controls/native/Decimal';
import * as TalxisPcfPortalLabel from '@controls/native/Label';
import * as TalxisPcfPortalWebPage from '@controls/native/WebPage';
import * as TalxisPcfPortalDuration from '@controls/native/Duration';
import * as TalxisPcfPortalFile from '@controls/native/File';

import resx from 'resx';
import { metadataRetrieveMultiple, getWebResourceUrl } from '@definitions/MetadataApi';
import { cachedWrapper } from '../utilities/MemoryCachingHelpers';
import { Exception } from '@talxis/client-libraries';

const TalxisPcfPortalTextFieldManifest = require('../../../components/controls/native/TextField/ControlManifest.Input.xml');
const TalxisPcfPortalMultilineTextManifest = require('../../../components/controls/native/MultilineText/ControlManifest.Input.xml');
const TalxisPcfPortalUrlManifest = require('../../../components/controls/native/Url/ControlManifest.Input.xml');
const TalxisPcfPortalDateTimeManifest = require('../../../components/controls/native/DateTime/ControlManifest.Input.xml');

const TalxisPcfPortalLookupManifest = require('../../../components/controls/native/Lookup/ControlManifest.Input.xml');
const TalxisPcfPortalLookupResxCZ = require('../../../components/controls/native/Lookup/localization/translations.1029.resx');
const TalxisPcfPortalLookupResxEN = require('../../../components/controls/native/Lookup/localization/translations.1033.resx');

const TalxisPcfPortalViewManifest = require('../../../components/controls/native/View/ControlManifest.Input.xml');
const TalxisPcfPortalViewResxCZ = require('../../../components/controls/native/View/localization/translations.1029.resx');
const TalxisPcfPortalViewResxEN = require('../../../components/controls/native/View/localization/translations.1033.resx');
const TALXISPcfPortalEmptyViewImg = require('../../../components/controls/native/View/img/view_empty.svg');

const TalxisPcfPortalOptionSetManifest = require('../../../components/controls/native/OptionSet/ControlManifest.Input.xml');
const TalxisPcfPortalMultiSelectOptionSetManifest = require('../../../components/controls/native/MultiSelectOptionSet/ControlManifest.Input.xml');

const TalxisPcfPortalFormManifest = require('../../../components/controls/native/Form/ControlManifest.Input.xml');
const TalxisPcfPortalFormResxCZ = require('../../../components/controls/native/Form/localization/translations.1029.resx');
const TalxisPcfPortalFormResxEN = require('../../../components/controls/native/Form/localization/translations.1033.resx');

const TalxisPcfPortalDecimalManifest = require('../../../components/controls/native/Decimal/ControlManifest.Input.xml');
const TalxisPcfPortalWholeNumberManifest = require('../../../components/controls/native/WholeNumber/ControlManifest.Input.xml');
const TalxisPcfPortalEmailManifest = require('../../../components/controls/native/Email/ControlManifest.Input.xml');

const TalxisPcfPortalTwoOptionsManifest = require('../../../components/controls/native/TwoOptions/ControlManifest.Input.xml');

const TalxisPcfPortalCurrencyManifest = require('../../../components/controls/native/Currency/ControlManifest.Input.xml');

const TalxisPcfPortalLabelManifest = require('../../../components/controls/native/Label/ControlManifest.Input.xml');

const TalxisPcfPortalWebPageManifest = require('../../../components/controls/native/WebPage/ControlManifest.Input.xml');

const TalxisPcfPortalDurationManifest = require('../../../components/controls/native/Duration/ControlManifest.Input.xml');
const TalxisPcfPortalDurationResxCZ = require('../../../components/controls/native/Duration/localization/translations.1029.resx');
const TalxisPcfPortalDurationResxEN = require('../../../components/controls/native/Duration/localization/translations.1033.resx');

const TalxisPcfPortalFileManifest = require('../../../components/controls/native/File/ControlManifest.Input.xml');
const TalxisPcfPortalFileResxCZ = require('../../../components/controls/native/File/localization/translations.1029.resx');
const TalxisPcfPortalFileResxEN = require('../../../components/controls/native/File/localization/translations.1033.resx');

export interface ControlRegistration {
    name: string;
    registration: Promise<ICustomControl> | null;
    manifest: null | Manifest.Control;
    manifestLoad: Promise<Manifest.Control>;
    load: () => Promise<ICustomControl>;
    resolve: ((value: ICustomControl | PromiseLike<ICustomControl>) => void) | null;
    reject: ((reason?: any) => void) | null;
}
export class ControlLoader {
    private static _nativeControlList: string[] = [
        "TALXIS.PCF.Portal.TextField",
        "TALXIS.PCF.Portal.MultilineText",
        "TALXIS.PCF.Portal.DateTime",
        "TALXIS.PCF.Portal.SimpleLookup",
        "TALXIS.PCF.Portal.View",
        "TALXIS.PCF.Portal.OptionSet",
        "TALXIS.PCF.Portal.MultiSelectOptionSet",
        "TALXIS.PCF.Portal.Form",
        "TALXIS.PCF.Portal.TwoOptions",
        "TALXIS.PCF.Portal.Decimal",
        "TALXIS.PCF.Portal.WholeNumber",
        "TALXIS.PCF.Portal.Button",
        "TALXIS.PCF.Portal.Currency",
        "TALXIS.PCF.Portal.Label",
        "TALXIS.PCF.Portal.Duration",
        "TALXIS.PCF.Portal.File",
        "TALXIS.PCF.Portal.Email",
        "TALXIS.PCF.Portal.Url",
        "TALXIS.PCF.WebPage",
        "MscrmControls.Grid.GridControl",
        "TALXIS.PCF.UrlPicker",
    ];

    private static _nativeControlsCache: { [name: string]: ControlRegistration } = {};
    private static _customControlsCache: { [name: string]: ControlRegistration } = {};
    private static _loadedLibrariesCache: { [name: string]: Promise<void> } = {};

    public static async getAsync(name: string): Promise<ControlRegistration> {
        if (ControlLoader._nativeControlsCache[name]) {
            await ControlLoader._nativeControlsCache[name].manifestLoad;

            return ControlLoader._nativeControlsCache[name];
        }
        if (ControlLoader._nativeControlList.includes(name)) {
            let registrationResolve: (value: ICustomControl | PromiseLike<ICustomControl>) => void;
            let registrationReject: (reason?: any) => void;
            const registration = new Promise<ICustomControl>(async (resolve, reject) => {
                registrationResolve = resolve;
                registrationReject = reject;
                switch (name) {
                    case "TALXIS.PCF.Portal.TextField":
                    case "TALXIS.PCF.Portal.MultilineText":
                    case "TALXIS.PCF.UrlPicker":
                    case "TALXIS.PCF.Portal.Email":
                    case "TALXIS.PCF.Portal.Url": {
                        let manifest;
                        switch (name) {
                            case 'TALXIS.PCF.Portal.MultilineText': {
                                manifest = await fetch(TalxisPcfPortalMultilineTextManifest);
                                break;
                            }
                            case 'TALXIS.PCF.UrlPicker':
                            case 'TALXIS.PCF.Portal.Url': {
                                manifest = await fetch(TalxisPcfPortalUrlManifest);
                                break;
                            }
                            case 'TALXIS.PCF.Portal.Email': {
                                manifest = await fetch(TalxisPcfPortalEmailManifest);
                                break;
                            }
                            default: {
                                manifest = await fetch(TalxisPcfPortalTextFieldManifest);
                            }
                        }
                        resolve({
                            code: TalxisPcfPortalTextField.TextField as any,
                            manifest: ManifestMapper.map(await manifest.text())
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.DateTime": {
                        const manifest = await fetch(TalxisPcfPortalDateTimeManifest);
                        resolve({
                            code: TalxisPcfPortalDateTime.DateTime as any,
                            manifest: ManifestMapper.map(await manifest.text())
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.SimpleLookup": {
                        const manifest = await fetch(TalxisPcfPortalLookupManifest);
                        resolve({
                            code: TalxisPcfPortalLookup.Lookup as any,
                            manifest: ManifestMapper.map(await manifest.text()),
                            resx: window.Xrm.Utility.getGlobalContext().userSettings.languageId == 1033 ? await resx.resx2js(await (await fetch(TalxisPcfPortalLookupResxEN)).text()) : await resx.resx2js(await (await fetch(TalxisPcfPortalLookupResxCZ)).text())
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.View":
                    case "MscrmControls.Grid.GridControl": {
                        const manifest = await fetch(TalxisPcfPortalViewManifest);
                        resolve({
                            code: TalxisPcfPortalView.View as any,
                            manifest: ManifestMapper.map(await manifest.text()),
                            img: {
                                //should come from getWebresouce when standalone
                                'view_empty.svg': TALXISPcfPortalEmptyViewImg
                            },
                            resx: window.Xrm.Utility.getGlobalContext().userSettings.languageId == 1033 ? await resx.resx2js(await (await fetch(TalxisPcfPortalViewResxEN)).text()) : await resx.resx2js(await (await fetch(TalxisPcfPortalViewResxCZ)).text())
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.OptionSet": {
                        const manifest = await fetch(TalxisPcfPortalOptionSetManifest);
                        resolve({
                            code: TalxisPcfPortalOptionSet.OptionSet as any,
                            manifest: ManifestMapper.map(await manifest.text())
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.MultiSelectOptionSet": {
                        const manifest = await fetch(TalxisPcfPortalMultiSelectOptionSetManifest);
                        resolve({
                            code: TalxisPcfPortalMultiSelectOptionSet.MultiSelectOptionSet as any,
                            manifest: ManifestMapper.map(await manifest.text())
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.Form": {
                        const manifest = await fetch(TalxisPcfPortalFormManifest);
                        resolve({
                            code: TalxisPcfPortalForm.Form as any,
                            manifest: ManifestMapper.map(await manifest.text()),
                            resx: window.Xrm.Utility.getGlobalContext().userSettings.languageId == 1033 ? await resx.resx2js(await (await fetch(TalxisPcfPortalFormResxEN)).text()) : await resx.resx2js(await (await fetch(TalxisPcfPortalFormResxCZ)).text())
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.TwoOptions": {
                        const manifest = await fetch(TalxisPcfPortalTwoOptionsManifest);
                        resolve({
                            code: TalxisPcfPortalTwoOptions.TwoOptions as any,
                            manifest: ManifestMapper.map(await manifest.text())
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.Decimal":
                    case "TALXIS.PCF.Portal.Currency":
                    case "TALXIS.PCF.Portal.WholeNumber": {
                        let manifest = null;
                        switch (name) {
                            case 'TALXIS.PCF.Portal.WholeNumber': {
                                manifest = await fetch(TalxisPcfPortalWholeNumberManifest);
                                break;
                            }
                            case 'TALXIS.PCF.Portal.Currency': {
                                manifest = await fetch(TalxisPcfPortalCurrencyManifest);
                                break;
                            }
                            default: {
                                manifest = await fetch(TalxisPcfPortalDecimalManifest);
                            }
                        }
                        resolve({
                            code: TalxisPcfPortalDecimal.Decimal as any,
                            manifest: ManifestMapper.map(await manifest.text()),
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.Button": {
                        resolve(null);
                        break;
                    }
                    case "TALXIS.PCF.Portal.Label": {
                        const manifest = await fetch(TalxisPcfPortalLabelManifest);
                        resolve({
                            code: TalxisPcfPortalLabel.Label as any,
                            manifest: ManifestMapper.map(await manifest.text())
                        });
                        break;
                    }
                    case "TALXIS.PCF.Portal.Duration": {
                        const manifest = await fetch(TalxisPcfPortalDurationManifest);
                        resolve({
                            code: TalxisPcfPortalDuration.Duration as any,
                            manifest: ManifestMapper.map(await manifest.text()),
                            resx: window.Xrm.Utility.getGlobalContext().userSettings.languageId == 1033 ? await resx.resx2js(await (await fetch(TalxisPcfPortalDurationResxEN)).text()) : await resx.resx2js(await (await fetch(TalxisPcfPortalDurationResxCZ)).text())
                        });
                        break;
                    }

                    case "TALXIS.PCF.Portal.File": {
                        const manifest = await fetch(TalxisPcfPortalFileManifest);
                        resolve({
                            code: TalxisPcfPortalFile.FileControl as any,
                            manifest: ManifestMapper.map(await manifest.text()),
                            resx: window.Xrm.Utility.getGlobalContext().userSettings.languageId == 1033 ? await resx.resx2js(await (await fetch(TalxisPcfPortalFileResxEN)).text()) : await resx.resx2js(await (await fetch(TalxisPcfPortalFileResxCZ)).text())
                        });
                        break;
                    }

                    case "TALXIS.PCF.WebPage": {
                        const manifest = await fetch(TalxisPcfPortalWebPageManifest);
                        resolve({
                            code: TalxisPcfPortalWebPage.WebPage as any,
                            manifest: ManifestMapper.map(await manifest.text())
                        });
                        break;
                    }
                }
            });
            ControlLoader._nativeControlsCache[name] = {
                name: name,
                registration: registration,
                manifest: null,
                load: async () => { return ControlLoader._nativeControlsCache[name].registration; },
                manifestLoad: new Promise<Manifest.Control>(async (resolve, reject) => {
                    const manifest = (await registration)?.manifest;
                    // TODO: This is not perfect solution, because it starts executing immediatelly and if for whatever reason manifest was loaded synchronously, ControlLoader._customControlsCache[name] would be undefined
                    ControlLoader._nativeControlsCache[name].manifest = manifest;
                    return resolve(manifest);
                }),
                resolve: registrationResolve,
                reject: registrationReject
            };

            await ControlLoader._nativeControlsCache[name].manifestLoad;

            return ControlLoader._nativeControlsCache[name];
        }

        if (ControlLoader._customControlsCache[name]) {
            // Make sure that the manifest has been loaded before returning the control
            await ControlLoader._customControlsCache[name].manifestLoad;

            return ControlLoader._customControlsCache[name];
        }

        const manifestLoad = new Promise<Manifest.Control>(async (resolve, reject) => {
            try {
                const manifest = await ControlLoader._fetchControlManifestAsync(name);
                // TODO: This is not perfect solution, because it starts executing immediatelly and if for whatever reason manifest was loaded synchronously, ControlLoader._customControlsCache[name] would be undefined
                ControlLoader._customControlsCache[name].manifest = manifest;
                return resolve(manifest);
            }
            catch (err) {
                reject(err);
            }
        });

        ControlLoader._customControlsCache[name] = {
            name: name,
            manifest: null,
            manifestLoad: manifestLoad,
            registration: null,
            load: async () => {
                if (ControlLoader._customControlsCache[name].registration != null) {
                    return ControlLoader._customControlsCache[name].registration;
                }
                else {
                    ControlLoader._customControlsCache[name].registration = new Promise<ICustomControl>(async (resolve, reject) => {
                        ControlLoader._customControlsCache[name].resolve = resolve;
                        ControlLoader._customControlsCache[name].reject = reject;

                        const manifest = await manifestLoad;
                        const isReactRequired = manifest.resources.platformLibraries
                            .find(lib =>
                                lib.name === 'React' && lib.version === '16.8.6'
                            );

                        if (window.React === undefined && isReactRequired) {
                            await PlatformLibraryLoader.loadReact();
                        }

                        const isFluent8Required = manifest.resources.platformLibraries
                            .find(lib =>
                                lib.name === 'Fluent' && lib.version.startsWith('8.')
                            );

                        const isFluent9Required = manifest.resources.platformLibraries
                            .find(lib =>
                                lib.name === 'Fluent' && lib.version.startsWith('9.')
                            );

                        if (isFluent8Required) {
                            await PlatformLibraryLoader.loadFluentUiV8();
                        }
                        if (isFluent9Required) {
                            await PlatformLibraryLoader.loadFluentUiV9();
                        }

                        for (const library of manifest.resources.libraries) {
                            console.log(`Loading ${library.name}@${library.version} for control ${name}`);
                            await cachedWrapper(library.name, async () => {
                                // Power Apps are replacing the file name with the library name
                                const path = library.path.substring(0, library.path.lastIndexOf('/') + 1) + library.name + library.path.substring(library.path.lastIndexOf('.'));
                                await ScriptLoader.loadAsync(library.name, getWebResourceUrl(`webresources/cc_shared/${library.name}/${library.version}/${path}`));
                            }, ControlLoader._loadedLibrariesCache);
                        }

                        await ScriptLoader.loadAsync(name, getWebResourceUrl(`webresources/cc_${manifest.namespace}.${manifest.constructor}/bundle.js`));
                    });
                    return ControlLoader._customControlsCache[name].registration;
                }
            },
            resolve: null,
            reject: null
        };

        const manifest = await ControlLoader._customControlsCache[name].manifestLoad;

        // We duplicate the control registration to match control's internal name which is assembled from namespace and constructor
        if (`${manifest.namespace}.${manifest.constructor}` !== name) {
            ControlLoader._customControlsCache[`${manifest.namespace}.${manifest.constructor}`] = ControlLoader._customControlsCache[name];
        }

        return ControlLoader._customControlsCache[name];
    }

    /**
     * This method is called from compiled PCF when the code is loaded to the page.
     * @param name 
     * @param control 
     */
    public static async registerControl(name: string, control: ComponentFramework.StandardControl<any, any>): Promise<void> {
        try {
            const timeStart = performance.now();

            const manifest = await ControlLoader._customControlsCache[name].manifestLoad;
            const resxPromise = ControlLoader._fetchControlResxAsync(name, manifest.resources.resx, window.Xrm.Utility.getGlobalContext().userSettings.languageId);

            const cssPromises: Promise<void>[] = [];
            for (const css of manifest.resources.css) {
                cssPromises.push(ControlLoader._loadCssAsync(name, css.path));
            }
            await Promise.all(cssPromises);
            const customControl: ICustomControl = {
                manifest: manifest,
                code: control,
                resx: await resxPromise
            };

            console.log(`Control ${name} loaded in ${performance.now() - timeStart} ms`);

            ControlLoader._customControlsCache[name].resolve(customControl);
        }
        catch (err) {
            console.error(name, `Failed to register control`, err);
            ControlLoader._customControlsCache[name].reject('Control registration failed!');
        }
    }
    private static async _loadCssAsync(controlName: string, path: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = getWebResourceUrl(`webresources/cc_${controlName}/${path}`);
            link.onload = () => resolve();
            link.onerror = () => reject();
            document.head.appendChild(link);
        });
    }
    private static async _fetchControlResxAsync(controlName: string, resxPaths: Manifest.Resx[], languageId: number): Promise<{ [key: string]: string }> {
        let resxPathOfRequestedLanguage = resxPaths.filter(x => x.path.endsWith(`.${languageId}.resx`));
        if (resxPathOfRequestedLanguage.length === 0) {
            resxPathOfRequestedLanguage = resxPaths.filter(x => x.path.endsWith(`.resx`));
        }
        if (resxPathOfRequestedLanguage.length > 0) {
            const response = await metadataRetrieveMultiple(`v9.1/webresourceset?$select=contentjson&$filter=webresourcetype eq 12 and contains(name, 'cc_${controlName}/${resxPathOfRequestedLanguage[0].path}')`);
            return JSON.parse(response.entities[0]["contentjson"]);
        }
        else return {};
    }
    private static async _fetchControlManifestAsync(name: string): Promise<Manifest.Control> {
        const response = await metadataRetrieveMultiple(`v9.1/customcontrols?$select=manifest&$filter=contains(name,'${name}')`);

        if (response.entities.length === 0) {
            throw new Exception(`Control ${name} not found on the environment. Did you deploy the solution?`);
        }

        console.log(`Loading ${name} PCF manifest`);
        return ManifestMapper.map(response.entities[0].manifest);
    }
}
