import { DomParser } from '../../Constants';
import { AppComponents } from '@configuration/AppComponents';
import { ILocalizedLabel, LocalizeLabel } from '@localization/helpers';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';
import { metadataRetrieveMultiple, sendMetadataGetRequest } from './MetadataApi';
import { sanitizeGuid } from '../../Functions';

export enum FormType {
    Main = 2,
    Dialog = 8,
    QuickCreate = 7
}
export enum ErrorType {
    MissingEntityName,
    MissingQuickCreateForm,
    MissingDefaultForm
}
export class FormDefinition {
    private static _formDefinitionCache: IPromiseCache<FormDefinition> = {};
    private static _defaultQuickCreateDefinitionCache: IPromiseCache<FormDefinition> = {};
    private static _defaultEntityFormCache: IPromiseCache<string> = {};
    private static _entityFormIdNameCache: {
        [entityName: string]: Promise<{
            formid: string,
            name: string
        }[]>
    } = {};

    public Id: string;
    public Type: FormType;
    public FormXml: Document;
    public Labels: { [key: string]: ILocalizedLabel[] };

    /**
     * This method performs a lookup for form ID if it is not know and caches results.
     * Use one of parameters to identify the form you want to retrieve
     * @param entityName If you don't know a specific form you can use a defaut form for an entity
     * @param formId Use this parameter when you know ID of the form you want
     * @param formUniqueName Form names are usually used when opening dialogs from the cluent SDK
     * @returns 
     */
    static async getAsync(entityName?: string, formId?: string, formUniqueName?: string): Promise<FormDefinition> {
        if (formId) {
            formId = sanitizeGuid(formId);
        }
        if (!entityName && !formId && !formUniqueName) {
            throw Error("You need to specify an entity name, form ID or form unique name!");
        }
        else if (entityName && !formId && !formUniqueName) {
            formId = await this.getEntityDefaultFormId(entityName);
        }

        return cachedWrapper(formId ?? formUniqueName, () => new Promise<FormDefinition>(async (resolve, reject) => {
            if (!formId && !formUniqueName && entityName) { // Get a default form ID
                formId = await this.getEntityDefaultFormId(entityName);
            }
            else if (formUniqueName) {
                const formQueryResponse = (await metadataRetrieveMultiple(`v9.1/systemforms?$select=formid&$filter=uniquename eq '${formUniqueName}'`))?.entities;
                if (formQueryResponse && formQueryResponse.length === 1) {
                    formId = formQueryResponse[0]?.["formid"];
                }
                else {
                    throw Error(`Form with unique name ${formUniqueName} was not found in metadata!`);
                }
            }

            resolve(await this._getFormDefinitionAsync(formId));
        }), this._formDefinitionCache);
    }

    static async getDefaultQuickCreateAsync(entityName?: string): Promise<FormDefinition> {
        if (!entityName) {
            throw { code: ErrorType.MissingEntityName, message: "You need to specify an entity name!" };
        }

        return cachedWrapper(entityName, () => new Promise<FormDefinition>(async (resolve, reject) => {
            const formIds = await AppComponents.filterAppComponents(entityName, 60);
            let filter = `type eq 7  and objecttypecode eq '${entityName}' and contains(formxml, 'Order="1"')`;
            if (formIds.length > 0) {
                filter += ` and (${formIds.map(x => `formid eq ${x}`).join(' or ')})`;
            }
            // $top to make sure we only get a single response, multiple Order="1" quick creates can return when set via XML manually
            const formQueryResponse = (await metadataRetrieveMultiple(`v9.1/systemforms?$filter=${filter}&$select=formid&$top=1`))?.entities;
            let formId = null;
            if (formQueryResponse && formQueryResponse.length === 1) {
                formId = formQueryResponse[0]?.["formid"];
            }
            else {
                reject({ code: ErrorType.MissingQuickCreateForm, message: `Default quick create form for entity ${entityName} was not found in metadata!` });
            }

            resolve(await this._getFormDefinitionAsync(formId));
        }), this._defaultQuickCreateDefinitionCache);
    }

    private static async _getFormDefinitionAsync(formId: string): Promise<FormDefinition> {
        const [formXml, labels, type] = await this._getFormXmlAsync(formId);

        return {
            Id: formId,
            FormXml: formXml,
            Type: type,
            Labels: labels ?? {}
        };
    }

    private static async _getFormXmlAsync(formId: string): Promise<[Document, { [key: string]: ILocalizedLabel[] }, FormType]> {
        const response = await sendMetadataGetRequest(`v9.1/systemforms(${formId})?$select=formxml,type`);
        const formData = await response.json();
        return [DomParser.parseFromString(formData['formxml'], "text/xml"), formData["__labels"], formData["type"]];
    }
    public static async getEntityDefaultFormId(entityName: string): Promise<string> {
        return cachedWrapper(entityName, () => new Promise<string>(async (resolve, reject) => {
            const formIds = await AppComponents.filterAppComponents(entityName, 60);
            let filter = `?$select=name,formid&$filter=objecttypecode eq '${entityName}'`;
            if (formIds.length > 0) {
                filter = `?$select=name,formid&$filter=(${formIds.map(x => `formid eq ${x}`).join(' or ')})`;
            }
            filter += ` and type eq 2`;

            const form = await metadataRetrieveMultiple(`v9.1/systemforms${filter}`);
            if (form.entities.length > 0) {
                resolve(form.entities[0]["formid"]);
                return;
            }
            reject({ code: ErrorType.MissingDefaultForm, message: `Unable to find default form for entity ${entityName}` });
        }), this._defaultEntityFormCache);
    }
    public static async getFormNamesAndIds(entityName: string, formType?: FormType): Promise<{ formid: string; name: string }[]> {
        let cacheKey = entityName;
        if (formType) {
            cacheKey += `_${formType}`;
        }
        return cachedWrapper(cacheKey, () => new Promise(async (resolve) => {
            const formIds = await AppComponents.filterAppComponents(entityName, 60);
            let filter = `?$select=name,formid&$filter=objecttypecode eq '${entityName}'`;
            if (formIds.length > 0) {
                filter = `?$select=name,formid&$filter=(${formIds.map(x => `formid eq ${x}`).join(' or ')})`;
            }
            if (formType) {
                filter += ` and type eq ${formType}`;
            }
            const result = await metadataRetrieveMultiple(`v9.1/systemforms${filter}`);
            const forms: { formid: string; name: string }[] = result.entities.map(entity => {
                return {
                    formid: entity.formid,
                    name: LocalizeLabel(entity["__labels"]?.["name"]) ?? entity.name
                };
            });
            resolve(forms);
        }), this._entityFormIdNameCache);
    }
}