import { mergeStyles, getTheme } from "@fluentui/react/lib/Styling";
const theme = getTheme();
export const rootStyles = mergeStyles(({
    selectors: {
        '.ms-Dialog-title': {
            paddingBottom: 10,
        },
        '.ms-Dialog-main': {
            maxWidth: 'calc(100% - 32px)'
        },
        '.ms-Dialog-subText': {
            fontSize: 16,
            fontWeight: 600,
            marginBottom: 5,
            color: theme.palette.black
        },
        '.TALXIS-Portal-confirmationDialog-text': {
            color: theme.palette.black
        },
        '.ms-Dialog-content, .ms-Dialog-content>div, .ms-Dialog-content>div>div, .ms-Dialog-content>div>div>div:not(.ms-Spinner), .ms-Dialog-content>div>div>div>div, .ms-Dialog-content>div>div>div>div>.TALXIS__form': {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            minWidth: 0,
            minHeight: 0
        },
        '.ms-Dialog-content>div>div>.ms-Spinner': {
            display: 'block',
            alignSelf: 'center',
            flexGrow: 1
        },
        '.TALXIS__form__tab-content': {
            padding: 2,
            paddingTop: 15,
            paddingBottom: 15
        },
        '.ms-Dialog-content>div>div>div>div>.TALXIS__form>.TALXIS__form__tab-wrapper': {
            marginBottom: 0
        },
        '.TALXIS__form__tab-wrapper': {
            '.TALXIS__Multistage__body': {
                display: 'none'
            },
            '.TALXIS__Multistage__header': {
                borderBottom: 'none'
            },
            '.TALXIS__Multistage': {
                boxShadow: 'none'
            }
        }
    }
}));