import { SiteMap } from "../../interfaces/sitemap";
import { IconLoader } from "@loaders/IconLoader";
import { AppModule } from "@configuration/AppModule";
import * as queryString from 'query-string';
import { QueryData } from "@pages/Control/interfaces";
import { Exception } from "../exceptions/Exception";
import { ILocalizedLabel, LocalizeLabel } from "@localization/helpers";
import { EntityDefinition } from "@definitions/EntityDefinition";
import { DomParser } from "../../Constants";

export class SiteMapMapper {
    static async parseAsync(siteMapXml: any): Promise<SiteMap.Root> {
        const xmlDoc = DomParser.parseFromString(siteMapXml, "text/xml");
        const siteMap: SiteMap.Root = {
            areas: await this._parseAreas(xmlDoc.getElementsByTagName('Area'))
        };
        return siteMap;
    };
    private static async _parseAreas(areasCollection: HTMLCollectionOf<Element>): Promise<SiteMap.Area[]> {
        const areas: SiteMap.Area[] = [];
        for (const area of areasCollection) {
            areas.push({
                id: area.getAttribute('Id'),
                showGroups: area.getAttribute('ShowGroups') == 'true',
                groups: await this._parseGroups(area.getElementsByTagName('Group')),
                title: await this._getTitle(area),
                icon: await IconLoader.getAsync(area.getAttribute('Icon')),
                disabled: false,
                visible: true
            });
        }
        return areas;
    };

    private static async _parseGroups(groupsCollection: HTMLCollectionOf<Element>): Promise<SiteMap.Group[]> {
        const groups: SiteMap.Group[] = [];
        for (const group of groupsCollection) {
            const title = await this._getTitle(group);
            groups.push({
                id: group.getAttribute('Id'),
                subAreas: await this._parseSubAreas(title, group.getElementsByTagName('SubArea')),
                title: title,
                icon: await IconLoader.getAsync(group.getAttribute('Icon')),
                visible: true
            });
        }
        return groups;
    }

    private static async _parseSubAreas(parentGroupTitle: string, subAreasCollection: HTMLCollectionOf<Element>): Promise<SiteMap.SubArea[]> {
        const subAreas: SiteMap.SubArea[] = [];
        for (const _subArea of subAreasCollection) {
            const id = _subArea.getAttribute('Id');
            const url = this._unescapeXML(_subArea.getAttribute('Url'));
            let entity = _subArea.getAttribute('Entity');
            if (!url && !entity) {
                throw new Error(`Either URL or entity name has to be specified for sub area ${id} in the sitemap.`);
            }

            let portalUrl: string = `/${AppModule.get().uniquename}`;
            const dataQuery: QueryData = {};

            if (url) {
                const queryParams = queryString.parse(url.substring(url.indexOf("?")));
                if (!queryParams?.pagetype) {
                    portalUrl = url; //Opens a page that is outside the dataverse environment.
                } else {
                    switch (queryParams.pagetype as string) {
                        case "entityrecord":
                            dataQuery.entityName = queryParams.etn as string;
                            dataQuery.entityId = queryParams.id as string;
                            dataQuery.formId = queryParams.formid as string;
                            portalUrl += `/control/form?data=${JSON.stringify(dataQuery)}`;
                            entity = queryParams.etn as string;
                            break;
                        case "entitylist": {
                            dataQuery.entityName = queryParams.etn as string;
                            dataQuery.viewId = queryParams.viewid as string;
                            dataQuery.viewType = queryParams.viewtype as string;
                            portalUrl += `/control/view?data=${JSON.stringify(dataQuery)}`;
                            entity = queryParams.etn as string;
                            break;
                        }
                        case "control": {
                            portalUrl += `/control/${queryParams.controlName}?data=${queryParams.data}`;
                            break;
                        }
                        case "inlinedialog": {
                            dataQuery.name = queryParams.name as string;
                            portalUrl += `/control/dialog?data=${JSON.stringify(dataQuery)}`;
                            break;
                        }
                        default:
                            //clear the local storage so user can switch to different app on error
                            localStorage.removeItem('appModuleName');
                            throw new Exception(`Unsupported sitemap URL. Current value: ${url}`);
                    }
                }
            }
            else if (!url && entity) {
                dataQuery.entityName = entity;
                portalUrl += `/control/view?data=${JSON.stringify(dataQuery)}`;
            }

            const subArea: SiteMap.SubArea = {
                id: id,
                url: portalUrl,
                entity: entity,
                title: await this._getTitle(_subArea, entity),
                icon: await IconLoader.getAsync(_subArea.getAttribute('Icon')),
                disabled: false,
                visible: true,
                parentGroupTitle: parentGroupTitle
            };
            subAreas.push(subArea);
        }
        return subAreas;
    };

    private static _unescapeXML(input?: string) {
        return input?.replace('&amp;', '&')
            .replace('&lt;', '<')
            .replace('&gt;', '>')
            .replace('&quot;', '"')
            .replace('&apos;', "'");
    }

    private static async _getTitle(element: Element, entity?: string): Promise<string> {
        const titlesWrap = element.getElementsByTagName('Titles')[0];
        if (!titlesWrap) {
            if (entity) {
                const entityDefinition = await EntityDefinition.getAsync(entity);
                return LocalizeLabel(entityDefinition.DisplayCollectionName.LocalizedLabels) ?? "Unknown";
            }
            return null;
        }

        const labels: ILocalizedLabel[] = [];
        for (const title of titlesWrap.getElementsByTagName('Title')) {
            labels.push({
                Label: title.getAttribute('Title'),
                LanguageCode: parseInt(title.getAttribute('LCID'))
            });
        }

        let label = LocalizeLabel(labels);
        // If we can't find label and are pointing to a view, fetch the label from collection name
        if (!label && entity) {
            const entityDefinition = await EntityDefinition.getAsync(entity);
            label = LocalizeLabel(entityDefinition.DisplayCollectionName.LocalizedLabels);
        }

        return label ?? "Unknown";
    };
};