export enum FormControlType {
    Field = "field",
    DataSet = "dataset",
    Button = "button"
}

export enum ParticipationTypeMasktoType {
    "from" = 1,
    "to" = 2,
    "cc" = 3,
    "bcc" = 4,
    "requiredattendees" = 5,
    "optionalattendees" = 6,
    "organizer" = 7,
    "resources" = 10,
    "customers" = 11,
    "partners" = 12
}