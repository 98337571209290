import { cloneDeep } from "lodash";
import { ISessionState } from "./HistoryManager";

export class State {
    private _id: string;
    private _nativeValues: ComponentFramework.Dictionary = {};
    private _controlValues: ComponentFramework.Dictionary = {};
    private _childStates: State[] = [];
    private _saveStateValuesChanges: () => void;

    constructor(id: string, sessionState?: ISessionState) {
        if (sessionState) {
            this._createStateFromSession(sessionState);
            return;
        }
        this._id = id;
    }
    public get id() {
        return this._id;
    }
    public get saveStateValuesChanges() {
        return this._saveStateValuesChanges;
    }
    /**
     * Tries to find a child control state for the given control id. If the control id is not provided, the 
     * method will return the same state object that it's being invoked on.
 */
    public get(controlId?: string): State | null {
        if (!controlId || controlId === this._id) {
            return this;
        }
        for (const childState of this._childStates) {
            if (childState._id === controlId) {
                return childState;
            }
        }

        return null;
    }
    public addChildState(state: State) {
        this._childStates.push(state);
    }
    public getValues<T>(valueType: 'native' | 'pcf'): T {
        if (valueType === 'native') {
            return cloneDeep(this._nativeValues as T);
        }
        return cloneDeep(this._controlValues as T);
    }
    public setValues<T>(valueType: 'native' | 'pcf', values: T) {
        if (!values) {
            return;
        }
        if (valueType === 'native') {
            this._nativeValues = values;
            return;
        }
        this._controlValues = values;
    }
    private _createStateFromSession(sessionState: ISessionState) {
        this._id = sessionState._id;
        this._nativeValues = sessionState._nativeValues ?? {};
        this._controlValues = sessionState._controlValues ?? {};
        if (!sessionState._childStates) {
            return;
        }
        for (const childSessionState of sessionState._childStates) {
            this._childStates.push(new State(childSessionState._id, childSessionState));
        }
    }
    public setSaveStateValuesChangesCallback(callback: () => void) {
        this._saveStateValuesChanges = callback;
    }
}