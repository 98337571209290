import { getTheme, mergeStyles } from "@fluentui/react/lib/Styling";

export const getDetailsListStyles = (): string => {
    const theme = getTheme();
    return mergeStyles({
        '.ms-DetailsRow': {
            minHeight: 49,
            width: '100%',
            '.ms-DetailsRow-cell': {
                display: 'flex',
                alignItems: 'center',
                '.ms-CommandBar-primaryCommand': {
                    display: 'none'
                },
                '.ms-CommandBar-secondaryCommand': {
                    justifyContent: 'flex-end',
                    width: '100%',
                    minWidth: 'max-content'
                },
            },
            '.ms-DetailsRow-cell > div:first-child': {
                width: '100%'
            },
            '.ms-DetailsRow-check': {
                width: '48px !important'
            },
            '.ms-CommandBar, .ms-Button--commandBar': {
                backgroundColor: 'transparent'
            },
            '.ms-Button--commandBar:hover': {
                backgroundColor: theme.semanticColors.buttonBackgroundPressed,
            }
        }
    });
};