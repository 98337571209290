interface IAppModuleThemes {
  appModuleUniqueName: string;
  themeId: string;
}
interface IAppModuleConnectionDTO { _talxis_themeid_value: string, talxis_appmoduleid: { talxis_uniquename: string } }

export class Website {
  public fallBackThemeId?: string;
  public allowedAppModules?: string[];
  public appModuleThemes: IAppModuleThemes[] = [];

  constructor(entity?: ComponentFramework.WebApi.Entity) {
    this.fallBackThemeId = entity?.["_talxis_themeid_value"] as string;
    this.allowedAppModules = (entity?.["talxis_allowedappmodules"] as string)?.trim()?.split(',');
    entity?.['talxis_talxis_website_talxis_appmodulewebsiteconnection_websiteid'].map((appModuleTheme: IAppModuleConnectionDTO) => {
      this.appModuleThemes.push({
        themeId: appModuleTheme._talxis_themeid_value,
        appModuleUniqueName: appModuleTheme.talxis_appmoduleid.talxis_uniquename
      });
    });
  }
}