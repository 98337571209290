import * as Msal from '@azure/msal-browser';
import { CommonSilentFlowRequest } from '@azure/msal-common';
import { StringDict } from '@azure/msal-common';
import { User } from '../interfaces/general';
import { SpaConfiguration } from '@configuration/SpaConfiguration';
import { MultitenantProvider } from './MultitenantProvider';
import { getClient, getUserProvisioningEndpointOverrideHeader } from '../Functions';
import { getAppInsights } from '@providers/TelemetryProvider/TelemetryService';

export enum AuthenticationSource {
    B2B = "b2b",
    B2C = "b2c",
    TokenInQuery = "tokenInQuery",
    Anonymous = "anonymous"
}

export class Authentication {
    private static _msalInstance: Msal.PublicClientApplication;
    private static _user: User = null;
    private static _msalUser: Msal.AccountInfo = null;
    private static _tokenPromise: Promise<string>;
    private static _authenticationSource: AuthenticationSource = AuthenticationSource.Anonymous;
    private static _authenticationSourceSet: boolean = false;
    private static _loginRequest: Msal.RedirectRequest = {
        scopes: ["openid", "profile"]
    };
    private static readonly _redirectUrl: string = "https://client.talxis.com/shared/sso.html";
    public static getUser(): User {
        return this._user;
    }
    public static isAuthenticated(): boolean {
        return this._user !== null;
    }
    // state property is not supported in BaseAuthRequest or SilentRequest, but it is used internally and we need to pass it for iframe token pickup to work
    private static async _getTokenRequest(): Promise<Omit<CommonSilentFlowRequest, "authority" | "correlationId"> & { state: string }> {
        return {
            scopes: SpaConfiguration.get().apiScopes,
            state: this._createState(window.location.href),
            account: this._msalUser,
            forceRefresh: false
        };
    }
    private static _setAppInsightsUserContext(): void {
        const ai = getAppInsights();
        if (ai && this._user) {
            ai.setAuthenticatedUserContext(this._user.accessPrincipalId, null, true);
        }
    }
    private static _clearAppInsightsUserContext(): void {
        const ai = getAppInsights();
        if (ai) {
            ai.clearAuthenticatedUserContext();
        }
    }
    private static _filterAccounts(accounts: Msal.AccountInfo[]): Msal.AccountInfo[] {
        const spaConfiguration = SpaConfiguration.get();
        const environment = new URL(spaConfiguration.authority);
        const allowedEnvironments = [environment.hostname];
        if (environment.hostname === "login.microsoftonline.com") {
            allowedEnvironments.push("login.windows.net");
        }
        let matchPolicy = false;
        if (this._isB2c()) {
            matchPolicy = true;
        }
        return accounts.filter(account => {
            return (
                allowedEnvironments.includes(account.environment) &&
                (!matchPolicy || account.idTokenClaims["tfp"] == spaConfiguration.b2cLoginPolicy)
            );
        });
    }
    public static async trySilentLoginAsync(): Promise<boolean> {
        await this._getMsalInstanceAsync();

        let tokenResponse = await this._msalInstance.handleRedirectPromise();
        // We are returning from authorization server, run the proper login pipeline
        if (tokenResponse) {
            return this.loginAsync();
        }
        let accounts = this._filterAccounts(this._msalInstance.getAllAccounts());
        let token: Msal.AuthenticationResult;

        if (isTokenInQuery()) {
            this._authenticationSource = AuthenticationSource.TokenInQuery;

            if (accounts.length > 0) {
                await Xrm.Navigation.openAlertDialog({
                    title: "Multiple sign-in sessions detected!",
                    text: "You are currently signed-in with an account, however a token has been provided explicitly in the URL. If you proceed further, the token in the URL will be used.",
                    confirmButtonLabel: "OK"
                });
            }

            try {
                this._user = await this._callWhoAmI();
            }
            catch (error) {
                // TODO: Display gracefully error coming from whoami endpoint
                throw error;
            }

            return true;
        }

        if (accounts.length === 1) {
            try {
                token = await this._msalInstance.acquireTokenSilent({
                    ...await this._getTokenRequest(),
                    account: accounts[0]
                });
            }
            catch (err) {
            }
        }
        else if (accounts.length > 1) {
            // Attempt to match one of many accounts to the current authority
            let foundAccount = false;
            const spaConfiguration = SpaConfiguration.get();
            const environment = new URL(spaConfiguration.authority);
            for (const account of accounts) {
                if (account.environment === environment.hostname) {
                    try {
                        token = await this._msalInstance.acquireTokenSilent({
                            ...await this._getTokenRequest(),
                            account: account
                        });
                        accounts = [account];
                        foundAccount = true;
                        break;
                    }
                    catch (err) {
                    }
                }
            }
            if (!foundAccount) {
                // If there are multiple accounts, log all the accounts out locally (clear cache)
                for (const account of accounts) {
                    await this._msalInstance.logoutRedirect({
                        onRedirectNavigate: (url) => {
                            return false;
                        },
                        account: account
                    });
                }
            }
        }

        if (token) {
            this._msalUser = accounts[0];

            try {
                this._user = await this._callWhoAmI(this._msalUser);
            } catch (error) {
                console.error(error);
                return false;
            }

            Authentication._setAppInsightsUserContext();
            return true;
        }

        return false;
    }
    public static async loginAsync(popup?: boolean): Promise<boolean> {
        if (popup) {
            throw new Error('Popup login is currently not supported!');
        }
        await this._getMsalInstanceAsync();
        let tokenResponse = await this._msalInstance.handleRedirectPromise();
        const accounts = this._filterAccounts(this._msalInstance.getAllAccounts());
        let token: Msal.AuthenticationResult;
        // Check if we can successfully obtain a token
        if (accounts.length === 1) {
            this._msalUser = accounts[0];
            try {
                token = await this._msalInstance.acquireTokenSilent({
                    ...await this._getTokenRequest(),
                    account: accounts[0]
                });
            }
            catch (err) {
            }
        }
        else if (accounts.length > 1) {
            // If there are multiple accounts, log all the accounts out locally (clear cache)
            for (const account of accounts) {
                await this._msalInstance.logoutRedirect({
                    onRedirectNavigate: (url) => {
                        return false;
                    },
                    account: account
                });
            }
        }
        if (!tokenResponse && !token) {
            const result = await this._handleLogin({ ...this._loginRequest, state: this._createState(window.location.href) });
            if (!result) return false;

            tokenResponse = result;
        }
        if (tokenResponse) {
            let originalUrl = this._getState(tokenResponse.state ?? "");
            if (originalUrl && originalUrl != "" && originalUrl != window.location.href) {
                window.location.replace(originalUrl);
            }
            else {
                if (!this._msalUser) this._msalUser = tokenResponse.account;

                try {
                    this._user = await this._callWhoAmI(this._msalUser);
                } catch (error) {
                    console.error(error);
                    throw error;
                }
                Authentication._setAppInsightsUserContext();
            }
            return true;
        }
        else if (token) {
            this._msalUser = accounts[0];

            try {
                this._user = await this._callWhoAmI(this._msalUser);
            } catch (error) {
                console.error(error);
                await this._handleLogin({ ...this._loginRequest, state: this._createState(window.location.href) });
                return false;
            }

            Authentication._setAppInsightsUserContext();
            return true;
        }
    };
    /**
     * Internal force login handle - either redirect, attempt silent SSO or create a pop-up
     */
    private static async _handleLogin(request?: Msal.RedirectRequest): Promise<Msal.AuthenticationResult> {
        if (getClient() !== "Outlook") {
            window.TALXIS.Portal.Context?.SetLoading(true);
            await this._msalInstance.loginRedirect(request);
            return null;
        }
        else {
            // TODO: This should be UPN, however EXO returns primary email address, in case it is different, we will need to go with pop-up since the SSO will fail
            // @ts-ignore - Office types are not resolved for some reason
            const username = Office.context.mailbox.userProfile.emailAddress;
            try {
                const result = await this._msalInstance.ssoSilent({ ...request, loginHint: username });
                return result;
            }
            catch (err) {
                console.error("Failed to obtain token via ssoSilent!", err);
                // TODO: Attempt to login via pop-up
                throw err;
            }
        }
    }
    public static async loginRedirect(extraQueryParameters?: StringDict, redirectStartPage?: string, domainHint?: string, authority?: string): Promise<void> {
        await this._getMsalInstanceAsync();

        const tokenResponse = await this._msalInstance.handleRedirectPromise();

        if (tokenResponse) {
            let originalUrl = this._getState(tokenResponse.state ?? "");
            if (originalUrl && originalUrl != "" && originalUrl != window.location.href) {
                window.location.replace(originalUrl);
            }
        }
        else {
            const request: Msal.RedirectRequest = {
                ...this._loginRequest,
                state: this._createState(redirectStartPage ? redirectStartPage : null),
                extraQueryParameters: extraQueryParameters,
                authority: authority,
                account: this._msalUser
            };

            if (domainHint) {
                request.domainHint = domainHint;
            }

            return this._msalInstance.loginRedirect(request);
        }
    };
    public static async getAuthorizationHeader(requireToken: boolean = false): Promise<RequestInit> {
        const request: RequestInit = {
            headers: {
                ...((this.isAuthenticated() || requireToken) && { 'Authorization': `Bearer ${await this._getTokenAsync()}` })
            }
        };
        return request;
    }
    public static async logout(): Promise<void> {
        if (this.getAuthenticationSource() === AuthenticationSource.TokenInQuery) {
            window.location.replace(window.location.origin + window.location.pathname + window.location.search);
        }
        else if ([AuthenticationSource.B2B, AuthenticationSource.B2C].includes(this.getAuthenticationSource())) {
            this._clearAppInsightsUserContext();
            await this._msalInstance.logoutRedirect({
                account: this._msalUser
            });
        }
    }
    public static async profileEditRedirect(): Promise<void> {
        if (this.getAuthenticationSource() === AuthenticationSource.TokenInQuery) {
            await Xrm.Navigation.openAlertDialog({
                title: "Profile edit is not available",
                text: "You are currently signed-in with a token provided explicitly in the URL. Profile edit is not available in this mode.",
                confirmButtonLabel: "OK"
            });
        }
        else if ([AuthenticationSource.B2B, AuthenticationSource.B2C].includes(this.getAuthenticationSource())) {
            await this._getMsalInstanceAsync();
            if (this._filterAccounts(this._msalInstance.getAllAccounts()).length === 1) {
                if (this._isB2c()) {
                    const spaConfiguration = SpaConfiguration.get();
                    this._msalInstance.loginRedirect({
                        ...this._loginRequest,
                        state: this._createState(window.location.href),
                        authority: spaConfiguration.authority + spaConfiguration.b2cProfileEditPolicy,
                        account: this._msalUser
                    });
                }
                else {
                    window.open("https://myaccount.microsoft.com", "_blank");
                }
            }
        }

        await Xrm.Navigation.openAlertDialog({
            title: "Profile edit is not available",
            text: "You are not currently signed-in. Profile edit is not available in this mode.",
            confirmButtonLabel: "OK"
        });
    }
    public static async isProfileEditRedirectAvailable(): Promise<boolean> {
        if ([AuthenticationSource.B2B, AuthenticationSource.B2C].includes(this.getAuthenticationSource())) {
            await this._getMsalInstanceAsync();
            if (this._filterAccounts(this._msalInstance.getAllAccounts()).length === 1) {
                if (this._isB2c()) {
                    const spaConfiguration = SpaConfiguration.get();
                    if (spaConfiguration.b2cProfileEditPolicy && spaConfiguration.b2cProfileEditPolicy !== "") {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return true;
                }
            }
        }
        else {
            return false;
        }
    }
    public static async passwordChangeRedirect(): Promise<void> {
        if (this.getAuthenticationSource() === AuthenticationSource.TokenInQuery) {
            await Xrm.Navigation.openAlertDialog({
                title: "Password change is not available",
                text: "You are currently signed-in with a token provided explicitly in the URL. Password change is not available in this mode.",
                confirmButtonLabel: "OK"
            });
            return;
        }
        else if ([AuthenticationSource.B2B, AuthenticationSource.B2C].includes(this.getAuthenticationSource())) {
            await this._getMsalInstanceAsync();
            if (this._filterAccounts(this._msalInstance.getAllAccounts()).length === 1) {
                if (this._isB2c()) {
                    const spaConfiguration = SpaConfiguration.get();
                    this._msalInstance.loginRedirect({
                        ...this._loginRequest,
                        prompt: "login",
                        state: this._createState(window.location.href),
                        authority: spaConfiguration.authority + spaConfiguration.b2cPasswordChangePolicy,
                        account: this._msalUser
                    });

                }
                else {
                    window.open("https://account.activedirectory.windowsazure.com/ChangePassword.aspx", "_blank");
                }
            }
        }
        else {
            await Xrm.Navigation.openAlertDialog({
                title: "Password change is not available",
                text: "You are not currently signed-in. Password change is not available in this mode.",
                confirmButtonLabel: "OK"
            });
        }
    }
    private static _getMsalInstanceAsync(): void {
        if (this._msalInstance) return;

        const spaConfiguration = SpaConfiguration.get();
        const msalLogging = localStorage.getItem('msalLogging') === "true";
        const msalConfig: Msal.Configuration = {
            auth: {
                clientId: spaConfiguration.clientId,
                authority: spaConfiguration.authority + (this._isB2c() ? spaConfiguration.b2cLoginPolicy : ""),
                redirectUri: this._redirectUrl,
                knownAuthorities: [spaConfiguration.authority],
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            },
            system: {
                // https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-logging-js
                ...msalLogging && {
                    loggerOptions: {
                        logLevel: Msal.LogLevel.Trace,
                        loggerCallback: (level, message, containsPii) => {
                            // if (containsPii) {
                            //     return;
                            // }
                            switch (level) {
                                case Msal.LogLevel.Error:
                                    console.error(message);
                                    return;
                                case Msal.LogLevel.Info:
                                    console.info(message);
                                    return;
                                case Msal.LogLevel.Verbose:
                                case Msal.LogLevel.Trace:
                                    console.debug(message);
                                    return;
                                case Msal.LogLevel.Warning:
                                    console.warn(message);
                                    return;
                            }
                        },
                        piiLoggingEnabled: true
                    }
                }
            }
        };
        this._msalInstance = new Msal.PublicClientApplication(msalConfig);
    }
    private static async _getTokenAsync(): Promise<string> {
        try {
            if (isTokenInQuery()) {
                return getTokenFromQuery();
            }
            else {
                const response = await this._msalInstance.acquireTokenSilent(await this._getTokenRequest());
                return response.accessToken;
            }
        }
        catch (err) {
            console.error("Failed to obtain token silently!", err);
            if (!this._tokenPromise) {
                this._tokenPromise = new Promise(async (resolve) => {
                    window.TALXIS.Portal.Translations.getLocalizedString('');
                    await Xrm.Navigation.openAlertDialog({
                        text: window.TALXIS.Portal.Translations.getLocalizedString('@app/classes/Authentication/SessionExpiredDialogText'),
                        title: window.TALXIS.Portal.Translations.getLocalizedString('@app/classes/Authentication/SessionExpiredDialogTitle'),
                        confirmButtonLabel: window.TALXIS.Portal.Translations.getLocalizedString('@app/classes/Authentication/SessionExpiredDialogButton')
                    });
                    const authenticationResult = await this._msalInstance.loginPopup({
                        scopes: SpaConfiguration.get().apiScopes,
                        state: this._createPopupState(window.location.href),

                    });
                    resolve(authenticationResult.accessToken);
                    return;
                });
            }
            const result = await this._tokenPromise;
            this._tokenPromise = null;
            return result;
        }
    }
    private static _isB2c(): boolean {
        const spaConfiguration = SpaConfiguration.get();
        if (spaConfiguration.b2cLoginPolicy) {
            return true;
        }
        else {
            return false;
        }
    }
    private static _createState(customData: any): string {
        const state: IPortalState = {
            __ssoRedirectUrl: `${window.location.protocol}//${window.location.host}`,
            __customData: customData
        };
        return btoa(JSON.stringify(state));
    }
    private static _getState(state: string): any {
        try {
            return (JSON.parse(atob(state)) as IPortalState).__customData;
        }
        catch (e) {
            console.warn("Failed to parse and deserialize incoming state", state);
            return null;
        }
    }
    private static _createPopupState(customData: any): string {
        const state: IPortalState = {
            __ssoRedirectUrl: `${window.location.protocol}//${window.location.host}/sso/sso.html`,
            __customData: customData
        };
        return btoa(JSON.stringify(state));
    }

    public static getAuthenticationSource(): AuthenticationSource {
        // TODO: If user logs in during an anonymous session, we should handle this change, but probably via refresh to reload every dependency (like pickers)
        if (!this._authenticationSourceSet) {
            if (isTokenInQuery()) {
                this._authenticationSource = AuthenticationSource.TokenInQuery;
            }
            else if (this._user && this._msalUser) {
                if (this._isB2c()) {
                    this._authenticationSource = AuthenticationSource.B2C;
                }
                else {
                    this._authenticationSource = AuthenticationSource.B2B;
                }
            }
            else {
                this._authenticationSource = AuthenticationSource.Anonymous;
            }
            this._authenticationSourceSet = true;
        }

        return this._authenticationSource;
    }

    private static async _callWhoAmI(msalUser?: Msal.AccountInfo): Promise<User> {
        let response = await fetch(SpaConfiguration.get().edsApi + '/v9.1/whoami', {
            headers: {
                ...MultitenantProvider.getFetchHeader().headers,
                ...(await this.getAuthorizationHeader(true)).headers,
                ...getUserProvisioningEndpointOverrideHeader().headers
            }
        });

        if (!response.ok) {
            const contentType = response.headers.get("content-type");
            let errorMessage = response.statusText;
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const errorDetails = await response.json();
                errorMessage = errorDetails?.message ?? errorMessage;
            }
            else {
                errorMessage = await response.text();
            }
            throw new Error(`Authentication error! ${response.status}: ${response.statusText}\n\n${errorMessage}`);
        }

        var responseContent = await response.json();
        const user: User = {
            accessPrincipalId: responseContent.accessPrincipalId,
            userPrincipalName: responseContent.userPrincipalName,
            displayName: responseContent.displayName
        };

        return user;
    }
};

export const requireAuthentication = async (): Promise<boolean> => {
    if (Authentication.isAuthenticated()) {
        return false;
    }
    else {
        return Authentication.loginAsync();
    }
};

const TOKEN_QUERY_PARAM = "token";
const isTokenInQuery = (): boolean => {
    const searchParams = new URLSearchParams(window.location.hash.substring(1));
    return searchParams.has(TOKEN_QUERY_PARAM);
};
const getTokenFromQuery = (): string => {
    const searchParams = new URLSearchParams(window.location.hash.substring(1));
    return searchParams.get(TOKEN_QUERY_PARAM);
};

interface IPortalState {
    __ssoRedirectUrl: string;
    __customData: any;
}