import React, { useEffect, useState, useContext } from 'react';
import { ITabsProps } from './interfaces';
import { getTabCommandBarItems } from './Functions';
import { ICommandBarItemProps } from '@fluentui/react/lib/components/CommandBar/CommandBar.types';
import { IFormContext } from '../../interfaces/IFormContext';
import { FormContext } from '../../Form';
import TabCommandBar from '../../../../../navigation/commandbars/TabCommandBar';
import { mergeStyles, useTheme } from '@fluentui/react';

const Tabs: React.FC<ITabsProps> = (props) => {
    const [items, setItems] = useState<ICommandBarItemProps[]>([]);
    const [currentTabId, setCurrentTabId] = useState<string>(null);
    const formContext: IFormContext = useContext(FormContext);

    const getTabStyles = () => {
        return mergeStyles({
            '.ms-CommandBar-primaryCommand > .ms-OverflowSet-item:first-child .ms-Button--commandBar': {
                marginLeft: 0
            },
        });
    };

    const showTabs = () => {
        //hide the tabs if there is only one tab with the name 'Tab'
        //MS hides this tab by default, so we hide it as well for consistency

        if (items.length == 1 && items[0].text === 'Tab') {
            return false;
        }
        if (items.length > 0) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        const items = getTabCommandBarItems(props, formContext.isMobile);
        setItems(items);
        if (props.currentTab) setCurrentTabId(props.currentTab.id);
    }, [props]);

    return (
        <>
            {showTabs() &&
                <TabCommandBar
                    className={`TALXIS__form__tab ${getTabStyles()}`}
                    selectedKey={currentTabId}
                    items={items} />
            }
        </>
    );
};
export default Tabs;