import { ILocalizedLabel, LocalizeLabel } from '@localization/helpers';
import { APPLICATION_RIBBON_ENTITY_NAME, DomParser } from '../../../Constants';
import { IconLoader } from '@loaders/IconLoader';
import { RibbonButtonOverrider } from './RibbonButtonOverrider';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';
import { sendMetadataGetRequest, metadataRetrieveMultiple } from '../MetadataApi';
import { XrmFormContext } from '@src/components/controls/native/Form/Form';

export enum RibbonLocationFilters {
    All = "All",
    Default = "Default",
    Form = "Form",
    HomepageGrid = "HomepageGrid",
    SubGrid = "SubGrid",
}
export enum RibbonLocation {
    HomePageGrid = "HomepageGrid",
    SubGrid = "SubGrid",
    Form = "Form",
    Global = "Global"
}
export enum ButtonType {
    Button = 'Button',
    SplitButton = 'SplitButton',
    FlyoutAnchor = 'FlyoutAnchor',
    PCF = 'PCF'
}
interface ILocalizableButtonCacheItem {
    button: Ribbon.Definition.ILocalizableButton;
    element: Element;
}
export class RibbonDefinition {
    private static _ribbonDefinitionCache: IPromiseCache<Ribbon.Definition.Root> = {};
    private static _ribbonDefinitionAll: IPromiseCache<object> = {};
    private static _ribbonLabelsResolutionCache: { [key: string]: ILocalizableButtonCacheItem[] } = {};

    static async get(entityName: string, filter: RibbonLocation): Promise<Ribbon.Definition.Root> {
        const key = `${entityName}_${filter}`;
        return cachedWrapper(key, () => new Promise(async (resolve) => {
            let compressedXmlName = 'EntityXml';
            let result: any = null;
            if (entityName === APPLICATION_RIBBON_ENTITY_NAME) {
                compressedXmlName = 'ApplicationRibbonXml';
                const response = await sendMetadataGetRequest("v9.1/RetrieveApplicationRibbonUnzip");
                result = await response.json();
            }
            else {
                result = await this._getRibbonAll(entityName);
            }
            if (this._ribbonLabelsResolutionCache[key] === undefined) {
                this._ribbonLabelsResolutionCache[key] = [];
            }
            const ribbonDefinition = await this._parseRibbon(key, DomParser.parseFromString(result[compressedXmlName], "text/xml"), result["__labels"], entityName, filter);
            if (this._ribbonLabelsResolutionCache[key].length > 0) {
                await this._localizeGlobalRibbonLabels(this._ribbonLabelsResolutionCache[key]);
            }
            resolve(ribbonDefinition);
        }), this._ribbonDefinitionCache);
    };
    private static async _parseButtons(key: string, ribbonDefinition: Document, labels: { [key: string]: ILocalizedLabel[] }, parent: Element, entityName: string): Promise<Ribbon.Definition.Button[]> {
        const buttons = parent.querySelectorAll(this._createSelector());
        return Promise.all([...buttons].map(async button => {
            const id = button.getAttribute('Id');
            const command = button.getAttribute('Command');
            const commandDefinition = ribbonDefinition.querySelector(`CommandDefinition[Id='${button.getAttribute('Command')}' i]`);
            const enableRules = commandDefinition?.querySelectorAll('EnableRule');
            const javascriptFunction = commandDefinition?.querySelector('JavaScriptFunction');
            const isInline = this._isInlineButton(enableRules, ribbonDefinition);
            const ribbonButton: Ribbon.Definition.Button = {
                id: id,
                command: command,
                label: this._getLabel(button, labels),
                isInline: isInline,
                type: this._getRibbonButtonType(button),
                rules: await this._parseRules(ribbonDefinition, enableRules, command, entityName),
                function: await this._getFunction(javascriptFunction, command),
                visible: !this._isButtonHidden(button, ribbonDefinition),
                icon: await IconLoader.getAsync(button.getAttribute('ModernImage')),
                menuSections: await (async (): Promise<Ribbon.Definition.ButtonMenuSection[]> => {
                    const menuSections = button.querySelectorAll(':scope > Menu > MenuSection');
                    return Promise.all([...menuSections].map(async menuSection => {
                        const section: Ribbon.Definition.ButtonMenuSection = {
                            id: menuSection.getAttribute('Id'),
                            label: this._getLabel(menuSection, labels),
                            buttons: await this._parseButtons(key, ribbonDefinition, labels, menuSection, entityName)
                        };
                        if (section.label === undefined) {
                            this._ribbonLabelsResolutionCache[key].push({
                                button: section,
                                element: menuSection
                            });
                        }
                        return section;
                    }));
                })()
            };
            RibbonButtonOverrider.override(ribbonButton, javascriptFunction);
            if (ribbonButton.label === undefined) {
                this._ribbonLabelsResolutionCache[key].push({
                    button: ribbonButton,
                    element: button
                });
            }
            return ribbonButton;
        }));
    }
    private static async _parseLibraries(groups: Ribbon.Definition.Group[]): Promise<string[]> {
        const librarySet = new Set<string>();
        const isNative = (element: Ribbon.Definition.Button | Ribbon.Definition.ButtonRule) => {
            return element.function.libraryName === 'Main_system_library.js' && element.function.command?.startsWith('XrmCore');
        };
        const parseButtons = (buttons: Ribbon.Definition.Button[]): void => {
            for (const button of buttons) {
                if (button.function?.libraryName) {
                    if (!isNative(button)) {
                        librarySet.add(button.function.libraryName);
                    }
                }
                for (const rule of button.rules) {
                    if (rule.function?.libraryName) {
                        if (!isNative(rule)) {
                            librarySet.add(rule.function.libraryName);
                        }
                    }
                }
                if (button.menuSections) {
                    for (const section of button.menuSections) {
                        parseButtons(section.buttons);
                    }
                }
            }
        };
        for (const group of groups) {
            if (group.buttons) {
                parseButtons(group.buttons);
            }
        }
        return [...librarySet];
    };
    private static _getRibbonButtonType(button: Element, label?: string): ButtonType {
        if (!label?.startsWith('__pcf')) {
            switch (button.tagName) {
                case 'Button':
                    return ButtonType.Button;
                case 'SplitButton':
                    return ButtonType.SplitButton;
                case 'FlyoutAnchor':
                    return ButtonType.FlyoutAnchor;
                default:
                    throw new Error(`Unsupported button type: ${button.tagName}`);
            }
        }
        return ButtonType.PCF;
    }
    private static _createSelector() {
        let customBtnSelector = "";
        let nativeBtnSelector = "";
        let topLevelSelector = "";
        topLevelSelector = ':scope>Controls>';
        ['Button', 'SplitButton', 'FlyoutAnchor'].forEach((buttonType) => {
            for (const command of RibbonButtonOverrider.supportedNativeButtons) {
                nativeBtnSelector += `${topLevelSelector}${buttonType}[Command="${command}"],`;
            }
            customBtnSelector += `${topLevelSelector}${buttonType}:not([Id^="mscrm" i]):not([Id^="AIBuilder" i]):not([Id^="adx." i]):not([Id^="AccessChecker." i]):not([Id^="MailApp" i]):not([Id^="Microsoft" i]):not([Id^="msdyn_" i]),`;
        });
        return nativeBtnSelector + customBtnSelector.slice(0, -1);
    }
    private static async _parseRibbon(key: string, xmlDoc: Document, labels: { [key: string]: ILocalizedLabel[] }, entityName: string, filter: RibbonLocation): Promise<any> {
        const timeStart = RibbonDefinition.logGroupStart(entityName, 'Application Ribbon Loading');
        const ribbon: Ribbon.Definition.Root = {
            libraries: [],
            groups: await (async (): Promise<Ribbon.Definition.Group[]> => {
                let tabSelector = `Tab[Id^="Mscrm.${filter}."] Group`;
                if (entityName === APPLICATION_RIBBON_ENTITY_NAME) {
                    tabSelector = 'Tab[Id="Mscrm.GlobalTab"] Group';
                }
                const groups = xmlDoc.querySelectorAll(tabSelector);
                return Promise.all([...groups].map(async (group) => {
                    return {
                        buttons: await this._parseButtons(key, xmlDoc, labels, group, entityName)
                    };
                }));
            })()
        };
        ribbon.libraries = await this._parseLibraries(ribbon.groups);
        RibbonDefinition.logGroupEnd(entityName, "Loading of application ribbon took", timeStart);
        return ribbon;
    };
    private static _isButtonHidden = (button: Element, xmlDoc: Document) => {
        const hideActions = xmlDoc.querySelectorAll('HideCustomAction');
        for (const hideAction of hideActions) {
            if (hideAction.getAttribute('Location') === button.getAttribute('Id')) {
                return true;
            };
        };
        return false;
    };

    private static async _getRibbonAll(entityName: string): Promise<object> {
        return cachedWrapper(entityName, () => new Promise(async (resolve, reject) => {
            const response = await sendMetadataGetRequest(`v9.1/RetrieveEntityRibbonUnzip(EntityName='${entityName}', RibbonLocationFilter=Microsoft.Dynamics.CRM.RibbonLocationFilters'${RibbonLocationFilters.All}')`);
            resolve(await response.json());
        }), this._ribbonDefinitionAll);
    }
    private static async _parseRules(fullRibbon: Document, enableRules: NodeListOf<Element> | undefined, command: string, entityName: string): Promise<Ribbon.Definition.ButtonRule[]> {
        const rules: Ribbon.Definition.ButtonRule[] = [];
        if (!enableRules) {
            return [];
        }
        for (const enableRule of enableRules) {
            const id = enableRule.getAttribute('Id');
            const enableRuleDefinition = fullRibbon.querySelector('RuleDefinitions').querySelector(`EnableRule[Id='${id}']`);
            if (enableRuleDefinition?.querySelector('CustomRule')) {
                const timeStart = RibbonDefinition.logStart(entityName);
                const CustomRule = enableRuleDefinition.querySelector('CustomRule');
                const rule: Ribbon.Definition.ButtonRule = {
                    type: "custom",
                    id: id,
                    function: await this._getFunction(CustomRule, command),
                    default: CustomRule.getAttribute('Default') === 'true',
                    invertResult: CustomRule.getAttribute('InvertResult') === 'true'
                };
                RibbonDefinition.logEnd(entityName, `Enable Rule ${id} loaded in`, timeStart);
                rules.push(rule);
            }
            else if (enableRuleDefinition?.querySelector('ValueRule')) {
                const CustomRule = enableRuleDefinition.querySelector('ValueRule');
                const rule: Ribbon.Definition.ButtonRule = {
                    type: "value",
                    id: id,
                    function: {
                        command: null,
                        parameters: [{
                            type: "CrmParameter",
                            value: "entity"
                        }],
                        action: (arg: XrmFormContext | ComponentFramework.PropertyHelper.DataSetApi.EntityRecord) => {
                            if (!arg) {
                                return false;
                            }
                            const field = CustomRule.getAttribute('Field');
                            const value = CustomRule.getAttribute('Value');
                            if (arg instanceof XrmFormContext) {
                                const formContext = arg as XrmFormContext;
                                return formContext.getAttribute(field).getValue() == value;
                            }
                            const entity = arg as ComponentFramework.PropertyHelper.DataSetApi.EntityRecord;
                            return entity.getValue(field) == value;
                        }
                    },
                    default: CustomRule.getAttribute('Default') === 'true',
                    invertResult: CustomRule.getAttribute('InvertResult') === 'true'
                };
                rules.push(rule);
            }
            else if (enableRuleDefinition?.querySelector("SelectionCountRule")) {
                const CustomRule = enableRuleDefinition.querySelector('SelectionCountRule');
                const rule: Ribbon.Definition.ButtonRule = {
                    type: "selectionCount",
                    id: id,
                    function: {
                        command: null,
                        parameters: [{
                            type: "CrmParameter",
                            value: "SelectedControlSelectedItemIds"
                        }],
                        action: (selectedControlSelectedItemIds: string[]) => {
                            const minimum = parseInt(CustomRule.getAttribute('Minimum'));
                            const maximum = parseInt(CustomRule.getAttribute('Maximum'));

                            if (!isNaN(minimum) && isNaN(maximum)) {
                                return selectedControlSelectedItemIds.length >= minimum;
                            }
                            else if (isNaN(minimum) && !isNaN(maximum)) {
                                return selectedControlSelectedItemIds.length <= maximum;
                            }
                            else if (!isNaN(minimum) && !isNaN(maximum)) {
                                return selectedControlSelectedItemIds.length >= minimum && selectedControlSelectedItemIds.length <= maximum;
                            }
                            else {
                                //throw new Error(`Unsupported SelectionCountRule values! Min: ${minimum}, Max: ${maximum}`);
                            }
                        }
                    },
                    default: CustomRule.getAttribute('Default') === 'true',
                    invertResult: CustomRule.getAttribute('InvertResult') === 'true'
                };
                rules.push(rule);
            }
        }
        return rules;
    }

    private static _getLabel(button: Element, labels: { [key: string]: ILocalizedLabel[] }): string {
        const result = this._getLabelOrDiffId(button, labels);
        if (!result[1]) {
            return result[0];
        }
        else {
            return undefined;
        }
    }

    // TODO: Once updated to TS4.0, use named Tuple for label: string, isDiffId: boolean
    private static _getLabelOrDiffId(button: Element, labels: { [key: string]: ILocalizedLabel[] }): [label: string, isDiffId: boolean] {
        const labelId = button.getAttribute('LabelText') ?? button.getAttribute('Title');
        if (!labelId) {
            return [null, false];
        }
        const labelMatches = [...labelId?.matchAll(/#(.*)#(.*)/g) ?? []];
        const labelMatchResult: string[] = labelMatches.length === 1 ? labelMatches[0] : [];
        if (labelMatchResult.length > 2) {
            return [labelMatchResult[2], false];
        }
        const parts = labelId.split(':');
        const labelIdPart = parts[1];
        if (labels && labels[labelIdPart]) {
            return [LocalizeLabel(labels[labelIdPart]), false];
        }

        return [parts[1] ?? labelId, true];
    }

    private static async _localizeGlobalRibbonLabels(buttons: ILocalizableButtonCacheItem[]): Promise<void> {
        const diffIdsMap: { [key: string]: ILocalizableButtonCacheItem[] } = {};
        for (const button of buttons) {
            const labelId = this._getLabelOrDiffId(button.element, {});
            if (labelId[1]) {
                if (diffIdsMap[labelId[0]] === undefined) {
                    diffIdsMap[labelId[0]] = [];
                }
                diffIdsMap[labelId[0]].push(button);
            }
        }

        const labelFromApi = await metadataRetrieveMultiple(`v9.1/ribbondiffs?$filter=tabid eq 'Mscrm.LocLabels' and (${Object.keys(diffIdsMap).map(x => `diffid eq '${x}'`).join(" or ")})&$select=rdx,diffid`);
        for (const label of labelFromApi.entities) {
            const labels: ILocalizedLabel[] = [];
            const labelsXml = DomParser.parseFromString(label["rdx"], "text/xml");
            for (const labelElement of labelsXml.getElementsByTagName("Title")) {
                labels.push({
                    Label: labelElement.getAttribute("description"),
                    LanguageCode: parseInt(labelElement.getAttribute("languagecode"))
                });
            }

            for (const button of diffIdsMap[label["diffid"]]) {
                const labelId = button.element.getAttribute('LabelText') ?? button.element.getAttribute('Title');
                button.button.label = LocalizeLabel(labels) ?? labelId;
            }
        }

        for (const key of Object.keys(diffIdsMap)) {
            for (const button of diffIdsMap[key]) {
                // Since we detect whether button is PCF based on label, we need to do the update here
                button.button.type = this._getRibbonButtonType(button.element, button.button.label ?? key);

                if (button.button.type === "PCF") {
                    button.button.label = (button.button.label ?? key).split('__pcf_')[1];
                }
                else {
                    button.button.label = button.button.label ?? key;
                }
            }
        }
    }

    private static async _getFunction(functionElement: Element, command: string): Promise<Ribbon.Definition.ButtonFunction> {
        if (!functionElement) {
            return null;
        }
        const library = functionElement.getAttribute('Library').split(':')[1];
        const functionName = functionElement.getAttribute('FunctionName');
        const parameters: Ribbon.Definition.ButtonFunctionParameter[] = [];

        for (const parameter of functionElement.children) {
            if (parameter.tagName !== "CrmParameter" && parameter.tagName !== "StringParameter") {
                console.warn("Encountered unknown parameter type when parsing function parameters!", parameter.tagName);
                continue;
            }
            parameters.push({
                type: parameter.tagName,
                value: parameter.getAttribute('Value')
            });
        }

        return {
            command: functionName,
            parameters: parameters,
            libraryName: library
        };
    }
    private static _isInlineButton(enableRules: NodeListOf<Element> | undefined, fullRibbon: Document): boolean | null {
        if (!enableRules) {
            return false;
        }
        for (const enableRule of enableRules) {
            const rule = fullRibbon.querySelector('RuleDefinitions').querySelector(`EnableRule[Id='${enableRule.getAttribute('Id')}']`);
            const id = rule?.getAttribute('Id');
            //some native MS rules generate the exact same definition we use for detecting inline buttons => we need to skip the inline check: https://dev.azure.com/thenetworg/INT0015/_wiki/wikis/INT0015.wiki/4078/Differences?anchor=rendering-of-inline-ribbon-buttons
            if (id?.startsWith('Mscrm')) {
                continue;
            }
            const selectionCountRule = rule?.querySelector('SelectionCountRule');
            const min = selectionCountRule?.getAttribute('Minimum');
            const max = selectionCountRule?.getAttribute('Maximum');
            if (min === '1' && max === '1') {
                return true;
            }
        }
        return false;
    }

    public static logGroupStart(entityName: string, title: string): number {
        if (entityName !== APPLICATION_RIBBON_ENTITY_NAME) {
            return;
        }
        console.groupCollapsed(title);
        return performance.now();

    }
    public static logGroupEnd(entityName: string, title: string, timeStart: number): void {
        if (entityName !== APPLICATION_RIBBON_ENTITY_NAME) {
            return;
        }
        console.log(`${title} ${(performance.now() - timeStart)}ms`);
        console.groupEnd();
    }
    public static logStart(entityName: string): number {
        if (entityName !== APPLICATION_RIBBON_ENTITY_NAME) {
            return;
        }
        return performance.now();
    }
    public static logError(message?: any, ...optionalParams: any[]) {
        console.error(message, ...optionalParams);
    }
    public static logWarning(message?: any, ...optionalParams: any[]) {
        console.warn(message, ...optionalParams);
    }
    public static logEnd(entityName: string, title: string, timeStart?: number): void {
        if (entityName !== APPLICATION_RIBBON_ENTITY_NAME) {
            return;
        }
        if (timeStart) {
            console.log(`${title} ${(performance.now() - timeStart)}ms`);
        }
        else {
            console.log(title);
        }
    }
}