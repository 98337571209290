import { FontSizes, ITheme, mergeStyleSets } from "@fluentui/react";

export const getStyles = (theme: ITheme, resultsOnPage: number) => {
    return mergeStyleSets({
        persona: {
            height: "unset",
            padding: 10,
            selectors: {
                ':hover': {
                    backgroundColor: theme.semanticColors.listItemBackgroundHovered,
                    cursor: 'pointer'
                },
            }
        },
        root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minWidth: 350,
            minHeight: 0,
        },
        overflowset: {
            background: theme.semanticColors.menuBackground,
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
        },
        header: {
            margin: '10px 35px',
            fontSize: FontSizes.size20,
            color: theme.semanticColors.disabledText,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: `${resultsOnPage === 1 ? 'wrap' : 'nowrap'}`
        },
        searchResult: {
            color: theme.semanticColors.disabledText
        },
        listHeader: {
            background: theme.semanticColors.listBackground,
            padding: "10px 15px",
            borderTop: `3px solid ${theme.palette.themeTertiary}`
        },
        body: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            minWidth: 0,
            minHeight: 350,
            padding: "0 35px",
            marginBottom: "35px"
        },
        lists: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "row",
            minWidth: 0,
            minHeight: 0,
            gap: "20px",
        },
        list: {
            flex: 1,
            overflow: "auto",
            background: theme.semanticColors.listBackground,
        },
        listWrapper: {
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            minHeight: 0,
            flex: `${1 / resultsOnPage}`,
        },
        pagination: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 10,
            alignItems: 'center',
            marginTop: '20px'
        },
        norecords: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        },
        noresulticon: { fontSize: 100, color: theme.semanticColors.infoIcon },
        invisible: { visibility: 'hidden' }
    });
};