import { IManyToManyRelationship, IRelationship } from "@src/app/interfaces/entitydefinition";
import { IFormContext } from "../native/Form/interfaces/IFormContext";
import { Form } from "../native/Form/interfaces/form";
import { IDataSetColumn } from "../native/View/interfaces/viewdefinition";
import { Grid } from "./Grid";

// @types/xrm don't expose getFetchXml https://docs.microsoft.com/en-us/power-apps/developer/model-driven-apps/clientapi/reference/grids/gridcontrol/getfetchxml
export interface IExtendedXrmGridControl extends Partial<Xrm.Controls.GridControl> {
    getFetchXml(): string;
    getViewColumns(): IDataSetColumn[];
    getGridType(): XrmGridType;
    getRelationship(): IXrmGridRelationship | undefined;
    //internal method that is not exposed by the interface
    getParentForm?: () => IFormContext;
    getGrid: () => Xrm.Controls.Grid;
}

interface IExtendedManyToManyRelationship extends IManyToManyRelationship {
    CurrentEntityIntersectAttribute: string;
    RelatedEntityIntersectAttribute: string;
}

export interface IXrmGridRelationship {
    entityName: string; // not present in Xrm getRelationship()
    recordId: string; // not present in Xrm getRelationship()
    attributeName: string;
    relationshipType: XrmRelationshipType;
    name: string;
    metadata: {
        oneToMany?: IRelationship
        many2Many?: IExtendedManyToManyRelationship
    }
}
export interface IXrmViewColumn {
    name: string,
    //internal type, unrelated to Xrm
    __displayName: string;
    relatedEntityName: string,
}

export interface IDatasetControlProps extends Omit<Form.Control, 'bindings'> {
    name: string;
    id: string;
    bindings: IGridBindings;
}

export interface IInternalDatasetControlProps extends IDatasetControlProps {
    onGridLoaded: (grid: Grid) => void;
    onGridLoadFailed: () => void;
    switchingView: boolean;
}

export interface IGridBindings {
    EnableFiltering?: Form.ParameterBinding;
    RelationshipName?: Form.ParameterBinding;
    RecordsPerPage?: Form.ParameterBinding;
    ViewIds?: Form.ParameterBinding;
    SearchPlaceholder?: Form.ParameterBinding;
    EnableQuickFind?: Form.ParameterBinding;
    //TODO: there might be other parameter for this 
    EnableViewPicker?: Form.ParameterBinding;
    EnableNavigation?: Form.ParameterBinding;
    EnableEditColumns?: Form.ParameterBinding;
    SelectableRows?: Form.ParameterBinding;
    TargetEntityType?: Form.ParameterBinding
    ViewId?: Form.ParameterBinding;
    IsViewPage?: Form.ParameterBinding;
    DisplayCommandBar?: Form.ParameterBinding;
    DisplayPaging?: Form.ParameterBinding;
    UseContainerAsHeight?: Form.ParameterBinding;
    DisableNewGrid?: Form.ParameterBinding;
    //represents the optional dataset parameter when using virtual grids
    [dataset: string]: any;
}

export interface ICdsOptions {
    displayQuickFind: boolean;
    displayIndex: boolean;
    displayCommandBar: boolean;
    displayViewSelector: boolean;
    displayPaging: boolean;
}

export interface IGridNativeStateValues {
    pageNumber: number;
    pageSize: number;
    selectedRecordIds: string[];
    previousPages: string[];
    fetchxml: string;
    layoutjson: string;
    searchValue: string;
    userFilterExpression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression;
    userSorting: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[];
    userLinkedEntities: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression[]
}

export enum XrmGridType {
    HomepageGrid = 1,
    Subgrid = 2
}

export enum XrmRelationshipType {
    OneToMany = 0,
    ManyToMany = 1
}