import React, { useEffect, useState } from 'react';
import { Text, ContextualMenuItemType, DefaultButton, mergeStyles, Stack, IContextualMenuListProps, IRenderFunction, Checkbox } from '@fluentui/react';
import { MultitenantProvider } from '@app/classes/MultitenantProvider';
import { IDevKitProps } from './interfaces';
import EntityMenu from './menus/EntityMenu';
import { AppModule } from '@configuration/AppModule';
import { QueryData } from '@pages/Control/interfaces';
import * as queryString from 'query-string';
import { sanitizeGuid } from '@app/Functions';
import { RingProvider } from '@app/classes/RingProvider';
import { AppSwitcherDialog } from '@src/components/dialogs/AppSwitcherDialog';

const DevKit: React.FC<IDevKitProps> = (props) => {
    interface IRepoContent {
        items: {
            path: string,
            file: FileSystemFileHandle
        }[]
    }
    const [appSwitcherDialogVisible, setAppSwitcherDialogVisible] = useState<boolean>(false);
    const [returnPermissionHeaderInResponse, setReturnPermissionHeaderInResponse] = useState<boolean>(false);

    useEffect(() => {
        localStorage.setItem('RETURN_PERMISSION_HEADER_IN_RESPONSE', returnPermissionHeaderInResponse.toString());
    }, [returnPermissionHeaderInResponse]);

    const onOpenRepoFolder = async (dirHandle: any) => {
        var output: IRepoContent = { items: [] };

        await handleDirectoryEntry(dirHandle, output);

        var formXmlFiles = output.items.filter(item => item.path.includes('FormXml') || item.path.includes('Dialogs'));
        alert(formXmlFiles.length + " forms found in the repository");

        formXmlFiles.forEach(formItem => {
            window.TALXIS.DevKit.LocalMachineDebugging.setFormDefinitionRedirection(sanitizeGuid(formItem.file.name.toLocaleLowerCase().replace('.xml', '').replace('_managed', '')), formItem.path, formItem.file);
        });
        console.log(window.TALXIS.DevKit.LocalMachineDebugging._formDefinitionRedirectionEntries);
    };

    const handleDirectoryEntry = async (dirHandle: any, output: IRepoContent, path: string = "/") => {
        for await (const entry of dirHandle.values()) {
            if (entry.kind === "file") {
                const file = await entry.getFile();
                output.items.push({ path: path + file.name, file: file });
            }
            if (entry.kind === "directory" && entry.name !== ".git") {
                const newHandle = await dirHandle.getDirectoryHandle(entry.name, { create: false });
                //const newOut = output[ entry.name ] = {};
                await handleDirectoryEntry(newHandle, output, path + entry.name + "/");
            }
        }
    };

    return (
        <div style={{
            height: '60px',
            width: '180px',
            bottom: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            left: '0',
            textAlign: 'left',
            zIndex: 1000020,
        }}>
            <DefaultButton
                id="developmentModeButton"
                styles={{
                    root: {
                        backgroundColor: '#f44336 !important',
                        color: '#ffffff !important'
                    }
                }}
                text="DEVKIT"
                iconProps={{ iconName: 'DeveloperTools' }}
                menuProps={{
                    calloutProps: {
                        preventDismissOnLostFocus: true,
                    },
                    //directionalHint: DirectionalHint.rightBottomEdge,
                    shouldFocusOnMount: true,
                    items: [
                        {
                            key: 'ConnectionDetails',
                            text: 'Connection Details',
                            onRender: () => {
                                return <Stack className={mergeStyles({
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    padding: "12px 24px 12px 34px",
                                })}><Text block variant="small">
                                        <b>Environment:</b> {props.connectedEnvironmentDetails?.environmentUrl}<br />
                                        <b>Backend:</b> {props.connectedEnvironmentDetails?.backendUrl}<br />
                                        <b>Metadata:</b> {props.connectedEnvironmentDetails?.metadataServiceUrl}<br />
                                        <b>Ring:</b> {RingProvider.get() ?? "Default"}
                                    </Text>
                                </Stack>;
                            }
                        },
                        {
                            key: 'ConnectionHeader',
                            itemType: ContextualMenuItemType.Header,
                            text: 'Connection'
                        },
                        {
                            key: 'ClearCache', text: 'Clear Cache', onClick: () => {
                                fetch(`${props.connectedEnvironmentDetails.metadataServiceUrl}/v9.1/ClearCache`, {
                                    method: 'POST',
                                    headers: {
                                        ...MultitenantProvider.getFetchHeader().headers
                                    }
                                }).then(
                                    response => response.text()).then(text => alert(`Metadata cache cleared. Response: ${text}`));
                            },
                            iconProps: { iconName: 'Refresh' }
                        },
                        {
                            key: 'Disconnect',
                            text: 'Disconnect',
                            onClick: () => {
                                localStorage.removeItem('METADATA_HOST_OVERRIDE');
                                localStorage.removeItem('BACKEND_HOST_OVERRIDE');
                                localStorage.removeItem('HOSTNAME_OVERRIDE');
                                localStorage.removeItem('USER_PROVISIONING_ENDPOINT_OVERRIDE');
                                RingProvider.clear();
                                window.location.reload();
                            },
                            iconProps: { iconName: 'PlugDisconnected' }
                        },
                        {
                            key: 'Actions',
                            itemType: ContextualMenuItemType.Header,
                            text: 'Actions'
                        },
                        {
                            key: 'OpenInPowerApps',
                            text: 'Open in Power Apps',
                            onClick: () => {
                                const paramsData = queryString.parse(window.location.search.substring(1))?.data as string;
                                let data: QueryData = (paramsData) ? JSON.parse(paramsData) : null;
                                let powerAppsUrl = `${props.connectedEnvironmentDetails.environmentUrl}/main.aspx?appid=${AppModule.get().appmoduleid}`;
                                if (data?.viewId) powerAppsUrl += `&pagetype=entitylist&viewid=${data.viewId}`;
                                else { powerAppsUrl += `&pagetype=entityrecord`; }
                                if (data?.entityName) powerAppsUrl += `&etn=${data.entityName}`;
                                if (data?.entityId) powerAppsUrl += `&id=${data.entityId}`;
                                if (data?.formId) powerAppsUrl += `&formid=${data.formId}`;
                                if (data?.extraqs) powerAppsUrl += `&extraqs=${encodeURIComponent(data.extraqs)}`;
                                window.open(powerAppsUrl, '_blank').focus();
                            },
                            iconProps: { iconName: 'PowerApps' }
                        },
                        {
                            key: 'RelatedRecords',
                            iconProps: { iconName: 'Relationship' },
                            subMenuProps: {
                                calloutProps: {
                                    preventDismissOnLostFocus: true,
                                },
                                items: [
                                    {
                                        key: 'CopyAccessPrincipalId',
                                        text: 'Access Principal',
                                        iconProps: { iconName: 'Permissions' },
                                        onClick: () => {
                                            var accessPrincipalId = Xrm.Utility.getGlobalContext().userSettings.userId;
                                            window.open(`${props.connectedEnvironmentDetails.environmentUrl}/api/data/v9.2/talxis_accessprincipals(${accessPrincipalId})`, '_blank').focus();
                                        }
                                    },
                                    {
                                        key: 'CopyUserContactId',
                                        text: 'User Contact',
                                        iconProps: { iconName: 'ContactCard' },
                                        onClick: () => {
                                            (Xrm.WebApi.retrieveMultipleRecords('contact', `?$select=contactid&$filter=(talxis_contact_talxis_accessprincipal_contactid/any(o1:(o1/talxis_accessprincipalid eq ${Xrm.Utility.getGlobalContext().userSettings.userId})))`))
                                                .then(resp => window.open(`${props.connectedEnvironmentDetails.environmentUrl}/api/data/v9.2/contacts(${resp?.entities?.[0].contactid})`, '_blank').focus());
                                        }
                                    },
                                    {
                                        key: 'CopyUserAccountId',
                                        text: 'User Account',
                                        iconProps: { iconName: 'DocumentSet' },
                                        onClick: () => {
                                            (Xrm.WebApi.retrieveMultipleRecords('contact', `?$select=contactid&$expand=parentcustomerid_account($select=accountid)&$filter=(talxis_contact_talxis_accessprincipal_contactid/any(o1:(o1/talxis_accessprincipalid eq ${Xrm.Utility.getGlobalContext().userSettings.userId})))`))
                                                .then(resp => window.open(`${props.connectedEnvironmentDetails.environmentUrl}/api/data/v9.2/accounts(${resp?.entities?.[0].parentcustomerid_account?.accountid})`, '_blank').focus());
                                        }
                                    }

                                ],
                            },
                            text: 'Related Records'
                        },
                        {
                            key: 'switchApp',
                            text: 'Switch Application',
                            iconProps: {
                                iconName: 'WaffleOffice365'
                            },
                            onClick: () => {
                                setAppSwitcherDialogVisible(true);
                            }
                        },
                        { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
                        {
                            key: 'OpenAdmin',
                            text: 'Admin App',
                            onClick: () => {
                                window.open(`${props.connectedEnvironmentDetails.environmentUrl}/main.aspx?appname=talxis_environmentadministration`, '_blank').focus();
                            },
                            iconProps: { iconName: 'Settings' }
                        },
                        {
                            key: 'Security',
                            iconProps: { iconName: 'Lock' },
                            subMenuProps: {
                                calloutProps: {
                                    preventDismissOnLostFocus: true,
                                },
                                items: [
                                    {
                                        key: 'ImpersonateAnotherUser',
                                        text: 'Impersonate Another User',
                                        disabled: true,
                                        iconProps: { iconName: 'Glasses' }
                                    },
                                    {
                                        key: 'ReturnUsedPermissions',
                                        text: 'Return Used Permissions',
                                        iconProps: { iconName: 'Key' },
                                        onRender: () => {
                                            return <Checkbox
                                                label='Return Used Permissions in response'
                                                onChange={(event, checked) => { setReturnPermissionHeaderInResponse(checked); }}
                                                checked={returnPermissionHeaderInResponse} />;
                                        }
                                    }
                                ],
                            },
                            text: 'Security'
                        },
                        { key: 'divider_2', itemType: ContextualMenuItemType.Divider },
                        {
                            key: 'OpenRepo',
                            text: 'Open Local Repository',
                            onClick: () => {
                                (window as any).showDirectoryPicker().then((result: any) => {
                                    onOpenRepoFolder(result);
                                });
                            },
                            iconProps: { iconName: 'GitGraph' }
                        },
                        {
                            key: 'CodeEditor',
                            text: 'Code Editor',
                            subMenuProps: {
                                calloutProps: {
                                    preventDismissOnLostFocus: true,
                                },
                                items: [
                                    {
                                        key: 'EditFormXml',
                                        text: 'Edit Form',
                                        disabled: true,
                                        iconProps: { iconName: 'PageList' }
                                    },
                                    {
                                        key: 'EditSavedQuery',
                                        text: 'Edit View',
                                        disabled: true,
                                        iconProps: { iconName: 'Folder' }
                                    }
                                ],
                            },
                            iconProps: { iconName: 'CodeEdit' }
                        },
                        {
                            key: 'Metadata',
                            text: 'Metadata',
                            subMenuProps: {
                                calloutProps: {
                                    preventDismissOnLostFocus: true,
                                },
                                items: [
                                    {
                                        key: 'AppModules',
                                        text: 'AppModules',
                                        disabled: true,
                                        iconProps: { iconName: 'PreviewLink' }
                                    },
                                    {
                                        key: 'Entities',
                                        text: 'Entities',
                                        subMenuProps: {
                                            calloutProps: {
                                                preventDismissOnLostFocus: true,
                                            },
                                            onRenderMenuList: (menuListProps: IContextualMenuListProps, defaultRender: IRenderFunction<IContextualMenuListProps>) => {
                                                return (<EntityMenu {...props} menuListProps={menuListProps} defaultRender={defaultRender} />);
                                            },
                                            shouldFocusOnMount: true,
                                            items: [{
                                                key: 'PlaceholderItemEntities',
                                                text: 'Loading...',
                                                subMenuProps: {
                                                    items: [
                                                        {
                                                            key: 'PlaceholderItemEntitiesContent',
                                                            text: 'Loading...'
                                                        }
                                                    ]
                                                }
                                            }],
                                        },
                                        iconProps: { iconName: 'TableGroup' }
                                    },
                                    {
                                        key: 'OptionSets',
                                        text: 'OptionSets',
                                        disabled: true,
                                        iconProps: { iconName: 'BulletedList' }
                                    }
                                ]
                            },
                            iconProps: { iconName: 'ModelingView' }
                        }

                    ],
                }}
            />
            {appSwitcherDialogVisible &&
                <AppSwitcherDialog appSwitcherDialogVisible={appSwitcherDialogVisible} setAppSwitcherDialogVisible={setAppSwitcherDialogVisible} onlyAllowedApps={false} />
            }
        </div>
    );
};
export default DevKit;