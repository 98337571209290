import { ICommandBarItemProps, ICommandBarProps, mergeStyles, Persona, PersonaSize, useTheme, IconButton, Callout } from "@fluentui/react";
import { CommandBar } from '@talxis/react-components';
import { Text } from '@fluentui/react/lib/Text';
import React, { useContext, useMemo, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { FormDefinition, FormType } from "@definitions/FormDefinition";
import { ControlLoader, ControlRegistration } from "@loaders/ControlLoader";
import { FieldControl } from "@controls/FieldControl";
import { FormControlType } from "@controls/native/Form/interfaces/enums";
import { Form } from "@controls/native/Form/interfaces/form";
import { AppContext } from '@providers/AppProvider';
import { history } from '@providers/HistoryProvider/HistoryProvider';
import * as QueryString from 'query-string';
import styles from './FormPage.module.css';
import { QueryData } from "../interfaces";
import { EntityDefinition } from "@definitions/EntityDefinition";
import { LocalizeLabel } from "@localization/helpers";
import { DomParser } from "@app/Constants";
import { IFormNotification } from "@controls/native/Form/Form";
import { Notifications } from "../../Layout/components/Notifications";
import { IGlobalNotification } from "../../Layout/components/GlobalNotification/interfaces/IGlobalNotification";
import { ThemeDefinition } from "@definitions/ThemeDefinition";
import Cell from "@controls/native/Form/components/Cell";
import { IFormContext } from "../../../components/controls/native/Form/interfaces/IFormContext";
import { cloneDeep } from "lodash";
import { IAppContext } from "@src/providers/AppProvider/interfaces";
import { FormLoading } from "@src/components/loadings/FormLoading/FormLoading";
import { HistoryManager } from "@src/providers/HistoryProvider/HistoryManager";
import { State } from "@src/providers/HistoryProvider/State";
import { useControlledNativeStateValues } from "@src/components/controls/useControlState";
import { FormRibbon } from "@src/app/classes/models/Ribbon/FormRibbon";
import { RibbonController } from "@src/components/navigation/ribbon/RibbonController";

export interface IFormNativeStateValues {
    headerExpanded: boolean;
}

interface IFormPageProps {
    bindings?: Form.ControlBindings;
}

export const FormPage: React.FC<IFormPageProps> = (props) => {
    const [initialized, setIsInitialized] = useState<boolean>(false);
    const [formDirty, setFormDirty] = useState<boolean>(false);
    const [ribbonCommandBarProps, setRibbonCommandBarProps] = useState<ICommandBarProps>(null);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [entityName, setEntityName] = useState<string>(null);
    const [formId, setFormId] = useState<string>(null);
    const [formPickerItems, setFormPickerItems] = useState<ICommandBarItemProps[]>([]);
    const [formName, setFormName] = useState<string>(null);
    const [primaryName, setPrimaryName] = useState<string>(null);
    const [formNotifications, setFormNotifications] = useState<IFormNotification[]>([]);
    const [entityDisplayName, setEntityDisplayName] = useState<string>(null);
    const [disabledNotification, setDisabledNotification] = useState<string>(null);
    const nativeStateRef = useRef<State>(HistoryManager.getCurrentPage().state);
    const [nativeStateValuesRef] = useControlledNativeStateValues<IFormNativeStateValues>(nativeStateRef, {
        headerExpanded: window.innerWidth > 768 ? true : false
    });
    const [headerExpanded, setHeaderExpanded] = useState<boolean>(nativeStateValuesRef.current.headerExpanded);
    const formPageRef = useRef<HTMLElement>(null);
    const ribbonRef = useRef<FormRibbon>(null);
    const controlDefinitionRef = useRef<ControlRegistration>(null);
    const theme = useTheme();
    const ribbonTheme = ThemeDefinition.get().ribbon;
    const [formHeader, setFormHeader] = useState<Form.Header>(null);
    const [headerFieldsCalloutVisible, setHeaderFieldsCalloutVisibility] = useState<boolean>(false);
    const [formContext, setFormContext] = useState<IFormContext>(null);
    const headerFieldsChevronIconId = useMemo(() => `prefix_${window.self.crypto.randomUUID()}`, []);
    const appContext: IAppContext = useContext(AppContext);
    const [entityImage, setEntityImage] = useState<string>(null);
    const isPortalPage = entityName === 'talxis_portalpage';

    const getSelectedFormStyles = () => {
        return mergeStyles({
            backgroundColor: theme.semanticColors.menuItemBackgroundPressed,
            '.ms-ContextualMenu-itemText': {
                fontWeight: 600
            }
        });
    };
    const getFormPageStyles = () => {
        return `${styles.root} ${mergeStyles({
            backgroundColor: theme.semanticColors.bodyBackground,
            '[class*="FormPage_backBtnRibbonWrap"]': {
                borderBottom: `1px solid ${ribbonTheme.semanticColors.bodyDivider}`
            },
            '[class*="FormPage_headerFieldSeperator"]': {
                backgroundColor: theme.semanticColors.bodyDivider
            },
            '[class*="FormPage_formHeaderFieldsWrapper"] .TALXIS__form__cell::after': {
                backgroundColor: theme.semanticColors.bodyDivider,
            }
        })}`;
    };

    const getCurrentFormId = async () => {
        let formId;
        //get form id on nested forms
        if (props.bindings.QuickForms) {
            formId = getQuickFormId().textContent;
        }
        //get form id from url
        else if (props.bindings.formId) {
            formId = props.bindings.formId.value;
        }
        if (!formId) {
            formId = FormDefinition.getEntityDefaultFormId(entityName);
        }
        return formId;
    };

    const getQuickFormId = () => {
        const quickForms = props.bindings.QuickForms.value;
        const quickFormsXml = DomParser.parseFromString(quickForms, "text/xml");
        return quickFormsXml.getElementsByTagName("QuickFormId")[0];
    };

    const getFormPickerItems = async (): Promise<ICommandBarItemProps[]> => {
        const formNameIds = await FormDefinition.getFormNamesAndIds(entityName, FormType.Main);
        const formName = formNameIds.find(x => formId.toLocaleLowerCase().includes(x.formid.toLocaleLowerCase()))?.name;
        //TODO: redo how sitemap higlighting is done
        const result: ICommandBarItemProps[] = formNameIds.map(form => {
            return {
                key: form.formid,
                text: form.name,
                className: formId.toLocaleLowerCase().includes(form.formid.toLocaleLowerCase()) && getSelectedFormStyles(),
                onClick: () => {
                    //nested form
                    if (props.bindings.QuickForms) {
                        //TODO: inject the new form id into the quickform
                        setFormId(form.formid);
                        return;
                    }
                    //top level form
                    const paramsData = QueryString.parse(window.location.search.substring(1), {
                        parseBooleans: true,
                        parseNumbers: true
                    })?.data as string;
                    const data: QueryData = (paramsData) ? JSON.parse(paramsData) : null;
                    data.formId = form.formid;
                    const url = `${window.location.pathname}?data=${JSON.stringify(data)}`;
                    history.push(url);
                }
            };
        });
        setFormName(formName);
        return result;
    };

    const getPersonaItems = (): ICommandBarItemProps[] => {
        const items: ICommandBarItemProps[] = [{
            key: 'entityName',
            text: entityDisplayName
        }];
        if (formPickerItems?.length > 1) {
            items.push({
                key: 'formPicker',
                text: formName,
                subMenuProps: {
                    items: formPickerItems
                }
            });
        }
        return items;
    };

    const onFormUpdated = async (arg: { formContext: Xrm.FormContext }) => {
        const formContext = arg.formContext;
        HistoryManager.getCurrentPage().setFormContext(arg.formContext);
        const entityDefinition = await EntityDefinition.getAsync(entityName);
        setFormLoaded(true);
        setPrimaryName(formContext.data.entity.getPrimaryAttributeValue());
        setEntityDisplayName(LocalizeLabel(entityDefinition.DisplayName.LocalizedLabels));
        if (!entityImage) {
            if (entityDefinition?.PrimaryImageAttribute) {
                setEntityImage(formContext?.getAttribute(entityDefinition?.PrimaryImageAttribute)?.getValue());
            }
        }
        if (!ribbonEnabled()) {
            return;
        }
        if (arg.formContext.data.getIsDirty()) {
            setFormDirty(true);
            return;
        }
        setFormDirty(false);
    };

    const refreshRibbon = async (arg: { formContext: Xrm.FormContext }) => {
        ribbonRef.current?.refresh();
    };

    const refreshForm = async (arg: { entityId: string }) => {
        appContext.setShouldRefreshMain(true);
    };

    const onFormNotificationsChanged = (formNotifications: IFormNotification[]) => {
        setFormNotifications(formNotifications);
    };

    const onFormProviderUpdated = (formContext: IFormContext) => {
        if (!ribbonRef.current && formContext.xrmExecutionContext) {
            ribbonRef.current = new FormRibbon(formContext.xrmExecutionContext.getFormContext(), true);
        }
        setFormContext(formContext);
    };

    const onFormHeaderChanged = (header: Form.Header) => {
        if (header) {
            setFormHeader({ ...header });
        }
    };

    const onSetFormDisabled = (params: ComponentFramework.Dictionary) => {
        if (params.disabled) {
            setDisabledNotification(params.message);
        }
        else {
            setDisabledNotification(null);
        }
    };

    const init = async () => {
        setIsInitialized(false);
        const controlDefinitionPromise = ControlLoader.getAsync("TALXIS.PCF.Portal.Form");
        let formPickerPromise;
        if (formPickerEnabled()) {
            formPickerPromise = getFormPickerItems();
        }
        const [controlDefinition, _formPickerItems] = await Promise.all([controlDefinitionPromise, formPickerPromise]);
        controlDefinitionRef.current = controlDefinition;
        setFormPickerItems(_formPickerItems);
        setIsInitialized(true);

    };

    const getBindings = (): Form.ControlBindings => {
        const _bindings = { ...props.bindings };
        _bindings.isMainTopLevel = {
            isStatic: true,
            value: 'true'
        };
        if (!_bindings.QuickForms) {
            return _bindings;
        }
        // Needed for the view to update if view switcher changes the view
        const quickForms = _bindings.QuickForms.value;
        const quickFormsXml = DomParser.parseFromString(quickForms, "text/xml");
        quickFormsXml.getElementsByTagName("QuickFormId")[0].textContent = formId;
        const s = new XMLSerializer();
        _bindings.QuickForms.value = s.serializeToString(quickFormsXml);
        return _bindings;
    };

    const headerEnabled = () => {
        if (entityName === 'talxis_portalpage') {
            return false;
        }
        if (!formHeader || !formContext) {
            return false;
        }
        const cells = formHeader.rows.flatMap(row => row?.cells).filter(cell => cell?.control?.visible);
        if (cells.length === 0) {
            return false;
        }
        return true;
    };

    const ribbonEnabled = () => {
        if (entityName === 'talxis_portalpage') {
            return false;
        }
        if (props.bindings.EnableFormPageRibbon?.value !== 'true') {
            return false;
        }
        return true;
    };

    const formPickerEnabled = () => {
        if (props.bindings.EnableFormPicker?.value !== 'true') {
            return false;
        }
        return true;
    };

    const renderHeader = () => {
        const cells = formHeader.rows.flatMap(row => row.cells).filter(cell => cell.control?.visible);
        return (
            <>
                <Persona
                    text={primaryName || '---'}
                    size={entityImage ? PersonaSize.size72 : PersonaSize.size56}
                    //TODO: this hides the coin until proper implemetation
                    onRenderPersonaCoin={formHeader.showImage ? undefined : () => <></>}
                    imageUrl={entityImage ? `data:image/png;base64, ${entityImage}` : undefined}
                    className={styles.persona}
                    onRenderSecondaryText={() =>
                        <CommandBar className={styles.formPicker} items={getPersonaItems()} />
                    } />
                <div className={styles.formHeaderFieldsWrapper}>
                    <div className={styles.formHeaderFields}>
                        {cells.slice(0, 4).map((cell, i) => {
                            const _cell = cloneDeep(cell);
                            _cell.control.disabled = true;
                            return (
                                <Cell theme={ThemeDefinition.get().formHeaderControlV9} key={cell.id} index={i} formContext={formContext} cell={_cell} onFormUpdated={undefined} />
                            );
                        })}
                        {headerFieldsCalloutVisible &&
                            <Callout
                                gapSpace={0}
                                role="dialog"
                                className={styles.headerFieldsCallout}
                                target={`#${headerFieldsChevronIconId}`}
                                onDismiss={() => setHeaderFieldsCalloutVisibility(false)}>
                                {cells.map(cell => {
                                    return (
                                        <Cell formContext={formContext} cell={cell} onFormUpdated={undefined} />
                                    );
                                })}
                            </Callout>
                        }
                    </div>
                    <IconButton onClick={() => setHeaderFieldsCalloutVisibility(!headerFieldsCalloutVisible)} id={headerFieldsChevronIconId} iconProps={{
                        iconName: 'ChevronDown',
                        styles: {
                            root: {
                                color: theme.semanticColors.infoIcon,
                                transition: 'transform 0.2s',
                                transform: headerFieldsCalloutVisible && 'rotate(180deg)'
                            }
                        }
                    }}
                    />
                </div>
            </>
        );
    };
    useEffect(() => {
        if (props.bindings.entityName) {
            setEntityName(props.bindings.entityName.value);
        }
        //nested form
        else if (props.bindings.QuickForms) {
            setEntityName(getQuickFormId().getAttribute('entityname'));
        }
    }, []);
    useEffect(() => {
        if (entityName) {
            (async () => {
                setFormId(await getCurrentFormId());
            })();
        }
    }, [entityName]);

    useEffect(() => {
        return () => {
            ribbonRef.current?.destroy();
        };
    }, []);

    useEffect(() => {
        if (formId) {
            init();
        }
    }, [formId]);
    return (
        <section ref={formPageRef} style={headerExpanded !== undefined ? {
            '--form-header-display': headerExpanded ? 'flex' : 'none',
            '--form-header-expand-rotation': headerExpanded ? '0deg' : '180deg'
        } as React.CSSProperties : undefined} className={getFormPageStyles()}>
            {(!formLoaded || !initialized) &&
                <FormLoading
                    style={{
                        '--ribbon-background': ribbonTheme.semanticColors.bodyBackground,
                        '--ribbon-border': ribbonTheme.semanticColors.bodyDivider
                    } as React.CSSProperties}
                    ribbonPlaceholder={ribbonEnabled() ? { backButton: true } : undefined}
                    headerPlaceholder={!isPortalPage ? {
                        showPersonaPlaceholder: true
                    } : undefined} />
            }
            <RibbonController
                showBackButton
                forceHidden={!ribbonEnabled()}
                hasUnsavedChanges={formDirty}
                theme={ThemeDefinition.get().ribbon}
                ribbon={ribbonRef.current} />
            {initialized &&
                <>
                    {formLoaded &&
                        <>
                            {(formNotifications.length > 0 || disabledNotification !== null) &&
                                <div className={styles.formNotifications}>
                                    {disabledNotification !== null &&
                                        <Notifications
                                            collapseCount={2}
                                            notifications={[{
                                                // __disabledNotification is referenced in GlobalNotification to display the lock
                                                id: "__disabledNotification",
                                                notification: {
                                                    level: 4,
                                                    message: disabledNotification,
                                                    type: 2
                                                }
                                            }]}
                                        />
                                    }
                                    {formNotifications.length > 0 &&
                                        <Notifications
                                            collapseCount={2}
                                            notifications={formNotifications.map(x => {
                                                const globalNotification: IGlobalNotification = {
                                                    id: x.uniqueId,
                                                    notification: {
                                                        message: x.message,
                                                        level: x.level === "WARNING" ? 3 :
                                                            x.level === "ERROR" ? 2 : 4,
                                                        type: 2
                                                    }
                                                };
                                                return globalNotification;
                                            })}
                                        />
                                    }
                                </div>
                            }
                            {headerEnabled() &&
                                <div className={styles.formHeader}>
                                    {renderHeader()}
                                </div>
                            }
                        </>
                    }
                    <FieldControl name="TALXIS.PCF.Portal.Form"
                        bindings={getBindings()}
                        childeventlisteners={[
                            {
                                eventname: "__onFormUpdated",
                                eventhandler: onFormUpdated
                            },
                            {
                                eventname: "__formNotificationsChanged",
                                eventhandler: onFormNotificationsChanged
                            },
                            {
                                eventname: "__setFormDisabled",
                                eventhandler: onSetFormDisabled
                            },
                            {
                                eventname: "__onFormHeaderChanged",
                                eventhandler: onFormHeaderChanged
                            },
                            {
                                eventname: "__onFormProviderUpdated",
                                eventhandler: onFormProviderUpdated
                            },
                            {
                                eventname: "__refreshRibbon",
                                eventhandler: refreshRibbon
                            },
                            {
                                eventname: "__refreshForm",
                                eventhandler: refreshForm
                            },
                            {
                                eventname: "__toggleHeader",
                                eventhandler: () => {
                                    nativeStateValuesRef.current.headerExpanded = !nativeStateValuesRef.current.headerExpanded;
                                    setHeaderExpanded(nativeStateValuesRef.current.headerExpanded);
                                }
                            }
                        ]}
                        disableLoading
                        id=""
                        classId=""
                        datafieldname={null}
                        disabled={false}
                        type={FormControlType.Field}
                        visible={true}
                        isUnbound={true}
                        isRequired={false}
                        definition={controlDefinitionRef.current}
                    />
                </>
            }
        </section>
    );
};