import { IWebResource } from "../../interfaces/IWebResource";
import { metadataRetrieveMultiple, getWebResourceUrl } from "@definitions/MetadataApi";
import { DomParser } from "../../Constants";
import { IPromiseCache, cachedWrapper } from "@utilities/MemoryCachingHelpers";

export class ScriptLoader {
    private static _script: IPromiseCache<boolean> = {};
    private static _libraryWithDependencies: IPromiseCache<IWebResource> = {};
    private static _libraryDepdencies: IPromiseCache<string[]> = {};
    private static _resx: IPromiseCache<{ [key: string]: string }> = {};

    static loadAsync(name: string, url: string, targetDocument?: HTMLIFrameElement): Promise<boolean> {
        let cache = this._script;
        if (targetDocument) {
            if (!targetDocument.contentWindow._cachedScripts) {
                targetDocument.contentWindow._cachedScripts = {};
            }
            cache = targetDocument.contentWindow._cachedScripts;
        }

        return cachedWrapper(name, () => new Promise((resolve, reject) => {
            const script: HTMLScriptElement = document.createElement("script");
            script.async = true;

            if (!url.startsWith('/') && !url.startsWith('http')) {
                //TODO: Scripts should be loaded from metadata service.
                // script.src = `data:text/javascript;base64,${url}`
                script.innerHTML = this._b64DecodeUnicode(url);
                resolve(true);
            }
            else {
                script.src = url;
            }

            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                reject(new Error(`Unable to load script ${name} from ${url}!`));
            };

            if (targetDocument) {
                targetDocument.contentWindow.document.head?.appendChild(script);
            }
            else {
                document.body.appendChild(script);
            }

            console.log(`Loading remote webresource ${name} to the DOM.`, targetDocument);
        }), cache);
    }

    private static async _getDependencies(libraryName: string): Promise<string[]> {
        return cachedWrapper(libraryName, () => new Promise(async (resolve, reject) => {
            const dependencies: string[] = [];

            const webResourceDefinitions = await metadataRetrieveMultiple(`v9.1/webresourceset?$select=dependencyxml&$filter=name eq '${libraryName}' and webresourcetype eq 3`);

            if (webResourceDefinitions?.entities && webResourceDefinitions.entities.length === 1) {
                const webResourceDefinition = webResourceDefinitions.entities[0];

                if (webResourceDefinition.dependencyxml) {
                    const dependencyXmlDoc = DomParser.parseFromString(webResourceDefinition.dependencyxml, "text/xml");
                    const libraries = dependencyXmlDoc.querySelectorAll('Library');

                    for (const libDependency of libraries) {
                        const libDependencyName = libDependency.getAttribute('name');
                        dependencies.push(libDependencyName);
                    }
                }
                resolve(dependencies);
            }
            else {
                reject(`Web Resource Library ${libraryName} could not be loaded! Check if it is available in the system.`);
            }
        }), this._libraryDepdencies);
    }

    static async loadWebResourceLibraryAndDependencies(libraryName: string, targetDocument?: HTMLIFrameElement): Promise<IWebResource> {
        const webResource: IWebResource = {
            translations: {}
        };
        const name = targetDocument ? `${targetDocument.getAttribute('id')}: ${libraryName}` : libraryName;

        let cache = this._libraryWithDependencies;
        if (targetDocument) {
            if (!targetDocument.contentWindow._cachedWebResources) {
                targetDocument.contentWindow._cachedWebResources = {};
            }
            cache = targetDocument.contentWindow._cachedWebResources;
        }

        return cachedWrapper(name, () => new Promise(async (resolve, reject) => {
            try {
                const dependencies = await ScriptLoader._getDependencies(libraryName);
                for (const dependency of dependencies) {
                    if (dependency.endsWith(".resx")) {
                        const resxNameWithLanguageId = dependency.substr(0, dependency.indexOf(".resx"));

                        if (resxNameWithLanguageId.endsWith(`${window.Xrm.Utility.getGlobalContext().userSettings.languageId}`)) {
                            const resxName = dependency.substr(0, resxNameWithLanguageId.lastIndexOf("."));
                            const resx = await this._fetchResxAsync(dependency);
                            webResource.translations[resxName] = resx;
                        }
                    } else {
                        const nestedWebResource = await this.loadWebResourceLibraryAndDependencies(dependency, targetDocument);
                        webResource.translations = { ...webResource.translations, ...nestedWebResource.translations };
                    }
                }

                window.TALXIS.Portal.WebResouces = { ...window.TALXIS.Portal.WebResouces, ...webResource.translations };
                await this.loadAsync(libraryName, getWebResourceUrl(`webresources/${libraryName}`), targetDocument);
                resolve(webResource);
            } catch (error) {
                reject(error);
            }
        }), cache);
    }

    private static async _fetchResxAsync(name: string): Promise<{ [key: string]: string }> {
        return cachedWrapper(name, () => new Promise(async (resolve, reject) => {
            const response = await metadataRetrieveMultiple(`v9.1/webresourceset?$select=contentjson&$filter=webresourcetype eq 12 and name eq '${name}'`);
            resolve(JSON.parse(response.entities[0]["contentjson"]));
        }), this._resx);
    }

    public static _b64DecodeUnicode(str: string): string {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
}