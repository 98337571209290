import { IParameterDefinitionMap, IVirtualComponentProps } from "@ComponentFramework/interfaces/NestedPcf";
import { LiquidService } from "./LiquidService";
import { sendMetadataGetRequest } from "@definitions/MetadataApi";
import React from "react";
import ReactDOM from "react-dom";
import { FormLoading } from "@src/components/loadings/FormLoading/FormLoading";

interface IInputs {
    Id: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
}

interface ITalxisWebPage {
    talxis_name: string;
    talxis_body: string;
    talxis_requiresauthentication: boolean;
}
export class WebPage implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    constructor() {
    }
    private _context: ComponentFramework.Context<IInputs>;
    private _container: HTMLDivElement;
    private _liquidService: LiquidService;
    private _renderedPCFsIds: string[] = [];

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement): void {
        (async () => {
            this._context = context;
            this._container = container;
            ReactDOM.render(React.createElement(FormLoading), this._container);
            await this._fetchAndRenderPage();
            this._renderPCFs();
        })();
    }

    /**
     * Called when any value in the property bag has changed. This includes field values, data-sets, global values such as container height and width, offline status, control metadata values such as label, visible, etc.
     * @param context The entire property bag available to control via Context Object; It contains values as set up by the customizer mapped to names defined in the manifest, as well as utility functions
     */
    public updateView(context: ComponentFramework.Context<IInputs>): void {
        // Add code to update control view
    }

    /**
     * It is called by the framework prior to a control receiving new data.
     * @returns an object based on nomenclature defined in manifest, expecting object[s] for property marked as “bound” or “output”
     */
    public getOutputs(): IOutputs {
        return {};
    }

    /**
     * Called when the control is to be removed from the DOM tree. Controls should use this call for cleanup.
     * i.e. cancelling any pending remote calls, removing listeners, etc.
     */
    public destroy(): void {
        for (const id of this._renderedPCFsIds) {
            (this._context as any).factory.unbindDOMComponent(id);
        }
    }
    private async _fetchAndRenderPage() {
        this._liquidService = new LiquidService();
        const response = await sendMetadataGetRequest(`v9.1/talxis_webpages(${this._context.parameters.Id.raw})?$select=talxis_name,talxis_body`);
        const page: ITalxisWebPage = await response.json();
        const renderedHTML = await this._liquidService.parseAndRender(page.talxis_body, {
            portalContext: {
                userSettings: this._context.userSettings
            }
        });
        this._container.innerHTML = renderedHTML;
    }
    private _getParametersFromBindings(bindings: { [key: string]: string }): IParameterDefinitionMap {
        const parameters: IParameterDefinitionMap = {};
        for (const [key, value] of Object.entries(bindings)) {
            parameters[key] = {
                Static: true,
                Value: value
            };
        }
        return parameters;
    }
    private _renderPCFs(): void {
        const pcfContainers = this._container.querySelectorAll('[data-pcf-control]');
        for (const pcfContainer of pcfContainers) {
            ;
            const data = JSON.parse(pcfContainer.getAttribute('data-pcf-control'));
            const bindings = data.bindings;
            const properties: IVirtualComponentProps = {
                parameters: this._getParametersFromBindings(bindings)
            };
            const id = `pcf_${window.self.crypto.randomUUID()}`;
            this._renderedPCFsIds.push(id);
            const component = (this._context as any).factory.createComponent(`${data.name}`, id, properties);
            (this._context as any).factory.bindDOMElement(component, pcfContainer);
        }
    }
}
