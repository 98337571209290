import { FRONTEND_VERSION } from "@src/app/Constants";
import { useEffect, useState } from "react";

export const useLayoutStyles = (isVertical: boolean) => {
    const [stylesLoaded, setStylesLoaded] = useState<boolean>();

    useEffect(() => {
        const head = document.head;
        const link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('href', `/css/${isVertical ? 'vertical' : 'horizontal'}.css?v=${FRONTEND_VERSION}`);
        link.onload = () => {
            setStylesLoaded(true);
        };
        head.appendChild(link);
    }, []);

    return stylesLoaded;
};