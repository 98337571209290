import { INavLink, IPanelProps, mergeStyles, Nav, Panel, useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AppModule } from '@configuration/AppModule';
import styles from './AppPanel.module.css';

export const AppPanel = (props: IPanelProps) => {
    const [navLinks, setNavLinks] = useState<INavLink[]>([]);
    const theme = useTheme();
    const getNavClassNames = () => {
        return `${styles.root} ${mergeStyles({
            '.ms-Nav-linkText': {
                color: theme.semanticColors.menuItemText
            }
        })}`;
    };
    const loadIcons = async () => {
        const appModules = await AppModule.getAllAppModules();
        const iconPromises: Promise<void>[] = [];
        for (const appModule of appModules) {
            iconPromises.push(appModule.loadIcon());
        }
        await Promise.all(iconPromises);
    };
    const getNavLinks = async (): Promise<INavLink[]> => {
        const links: INavLink[] = [];
        const appModules = await AppModule.getAllAppModules();
        for (const appModule of appModules) {
            links.push({
                key: appModule.appmoduleid,
                name: appModule.name,
                title: appModule.name,
                url: `/${appModule.uniquename}`,
                iconProps: {
                    imageProps: {
                        src: appModule.icon?.value
                    }
                },
                onClick: (e) => {
                    e.preventDefault();
                    localStorage.setItem('appModuleName', appModule.uniquename);
                    window.location.href = `/${appModule.uniquename}`;
                }
            });
        }
        return links;
    };
    const init = async () => {
        setNavLinks(await getNavLinks());
        await loadIcons();
        setNavLinks(await getNavLinks());
    };
    useEffect(() => {
        if (!props.isOpen) {
            return;
        }
        init();
    }, [props.isOpen]);
    return (
        <Panel className={getNavClassNames()} {...props}>
            <Nav selectedKey={AppModule.get().appmoduleid} groups={[
                {
                    links: navLinks
                }
            ]} />
        </Panel>
    );
};