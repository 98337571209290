
import { FC, useRef } from 'react';
import { useState } from 'react';
import React from 'react';
import { DefaultButton, IconButton, IPanelProps, mergeStyles, PrimaryButton, useTheme, Text, Panel, ThemeProvider } from '@fluentui/react';
import styles from './EditColumnsPanel.module.css';
import { useMemo } from 'react';
import { Autocomplete, IAutoCompleteItemProps } from '@talxis/react-components';
import { EntityDefinition } from '@definitions/EntityDefinition';
import { LocalizeLabel } from '@localization/helpers';
import produce from "immer";
import { useEffect } from 'react';
import { ribbonButtonsColumnName } from '@app/Constants';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Grid } from '../Grid';
import { IDataSetColumn } from '../../native/View/interfaces/viewdefinition';
import { cloneDeep } from 'lodash';
import { ThemeDefinition } from '@src/app/classes/definitions/ThemeDefinition';

interface IColumnListItem extends Partial<IDataSetColumn> {
    newlyAdded?: boolean;
    removed?: boolean;
}

interface IEditColumnsPanelProps extends IPanelProps {
    grid: Grid
};
export const EditColumnsPanel: FC<IEditColumnsPanelProps> = (props) => {
    const gridContext = props.grid.gridContext;
    const entityName = gridContext.getEntityName();
    const theme = useTheme();
    const columnChangesRef = useRef<IColumnListItem[]>(cloneDeep(gridContext.getViewColumns()));
    const [panelHeaderText, setPanelHeaderText] = useState<string>();
    const [visibleColumns, setVisibleColumns] = useState<IColumnListItem[]>(gridContext.getViewColumns().filter(column => column.name !== ribbonButtonsColumnName));
    const [dirtyChanges, setDirtyChanges] = useState<boolean>(false);
    const panelStyles = useMemo(() => {
        return `${styles.root} ${mergeStyles({
            '[class*="EditColumnsPanel_selectedAttribute"]': {
                backgroundColor: theme.semanticColors.buttonBackgroundPressed,
            },
            '[class*="EditColumnsPanel_selectedAttribute"][data-disabled="true"]': {
                backgroundColor: theme.semanticColors.buttonBackgroundDisabled,
                '> span': {
                    color: theme.semanticColors.disabledText
                }
            },
            '.ms-Panel-footer': {
                borderTop: `1px solid ${theme.semanticColors.bodyDivider}`,
                background: theme.semanticColors.bodyBackground
            }
        })}`;
    }, []);

    useEffect(() => {
        (async () => {
            const entityDefinition = await EntityDefinition.getAsync(entityName);
            setPanelHeaderText(LocalizeLabel(entityDefinition.DisplayCollectionName.LocalizedLabels));
        })();
    }, []);

    const filterEntityAttributes = async (filter?: string): Promise<IAutoCompleteItemProps[]> => {
        const attributes = (await EntityDefinition.getAsync(entityName)).Attributes;
        const filteredAttributes = attributes.filter(
            attribute => {
                const lowerCaseLabel = LocalizeLabel(attribute.DisplayName.LocalizedLabels)?.toLowerCase();
                const lowerCaseFilter = filter?.toLowerCase();
                if (!visibleColumns.find(columnState => columnState.name === attribute.LogicalName)
                    && (lowerCaseLabel?.includes(lowerCaseFilter) || (!filter && lowerCaseLabel))
                    //Difference from the PowerApps
                    //We use IsValidForGrid to filter out system attributes
                    && attribute.IsValidForGrid
                    //There is no way in PowerApps to filter out attributes we want, but in Portal we reuse IsValidForAdvancedFind to have better UX
                    && attribute.IsValidForAdvancedFind.Value
                )
                    return attribute;
            }
        );
        return filteredAttributes.sort((a, b) => {
            const labelA = LocalizeLabel(a.DisplayName.LocalizedLabels);
            const labelB = LocalizeLabel(b.DisplayName.LocalizedLabels);
            return labelA.localeCompare(labelB);
        }).map(attribute => {
            const label = LocalizeLabel(attribute.DisplayName.LocalizedLabels);
            return {
                key: attribute.LogicalName,
                text: label,
                tooltipHostProps: {
                    tooltipProps: {
                        onRenderContent: () => <>
                            <Text>{label}</Text>
                            <br />
                            <Text variant='small'>{attribute.LogicalName}</Text>
                        </>
                    }
                }
            };
        });
    };

    const addColumn = (item?: string | IAutoCompleteItemProps) => {
        if (!item || typeof item === 'string') {
            return;
        }
        const nextVisibleColumnState = produce(visibleColumns, draftVisibleColumns => {
            draftVisibleColumns.push({
                name: item.key,
                displayName: item.text
            });
        });
        setVisibleColumns(nextVisibleColumnState);
        //find out if changes have been previously applied to this column
        const columnToBeAdded = columnChangesRef.current.find(column => column.name === item.key);
        if (columnToBeAdded) {
            const presentInOriginalGrid = gridContext.getViewColumns().find(column => column.name === columnToBeAdded.name);
            columnToBeAdded.removed = false;
            if (!presentInOriginalGrid) {
                columnToBeAdded.newlyAdded = true;
            }
            return;
        }
        columnChangesRef.current.push({
            name: item.key,
            newlyAdded: true,
        });
        setDirtyChanges(true);
    };

    const markAttributeForDeletion = (deletedColumn: IColumnListItem) => {
        const columnToBeRemoved = columnChangesRef.current.find(column => column.name === deletedColumn.name);
        const presentInOriginalGrid = gridContext.getViewColumns().find(column => column.name === deletedColumn.name);
        const nextVisibleColumnState = produce(visibleColumns, draftVisibleColumns => {
            draftVisibleColumns.splice(visibleColumns.indexOf(deletedColumn), 1);
        });
        columnToBeRemoved.newlyAdded = false;
        if (presentInOriginalGrid) {
            columnToBeRemoved.removed = true;
        }
        setVisibleColumns(nextVisibleColumnState);
        setDirtyChanges(true);
    };

    const saveChanges = async () => {
        const attributesToAdd = columnChangesRef.current.filter(attribute => attribute.newlyAdded);
        for (const attributeToAdd of attributesToAdd) {
            await props.grid.addColumn(attributeToAdd.name,);
        }
        const attributesToRemove = columnChangesRef.current.filter(attribute => attribute.removed);
        for (const attributeToRemove of attributesToRemove) {
            await props.grid.removeColumn(attributeToRemove.name);
        }
        props.grid.updateColumnOrder(visibleColumns.map(column => column.name));
        props.grid.refresh();
        props.onDismiss();
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(visibleColumns, result.source.index, result.destination.index);
        setVisibleColumns(items);
        setDirtyChanges(true);
    };

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
        return {
            ...draggableStyle,
            userSelect: 'none',
            opacity: isDragging && 0.6,
        };
    };

    const reorder = (list: IColumnListItem[], startIndex: number, endIndex: number) => {
        const nextVisibleColumnState = produce(list, draftVisibleColumns => {
            const [removed] = draftVisibleColumns.splice(startIndex, 1);
            draftVisibleColumns.splice(endIndex, 0, removed);
        });

        return nextVisibleColumnState;
    };

    return (
        <Panel {...props} layerProps={{ eventBubblingEnabled: true }} className={panelStyles}
            isFooterAtBottom
            isOpen
            headerText={`${window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns")}: ${panelHeaderText}`}
            onRenderFooterContent={() => {
                return (
                    <div className={styles.footer}>
                        <PrimaryButton text={window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/Save")} disabled={!dirtyChanges} onClick={saveChanges} />
                        <DefaultButton text={window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/Cancel")} onClick={() => props.onDismiss()} />
                    </div>
                );
            }}>
            <ThemeProvider theme={ThemeDefinition.get().controlV8}>
                <Autocomplete
                    clearInputOnSelection
                    suggestionsProps={{
                        loadingText: window.TALXIS.Portal.Translations.getLocalizedString("@controls/loadings/MainLoading"),
                        suggestionRowHeight: 35
                    }}
                    placeholder={window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/SearchPlaceholder")}
                    searchButtonProps={{
                        key: 'search',
                        iconProps: {
                            iconName: 'Search'
                        }
                    }}
                    deleteButtonProps={{
                        key: 'delete',
                        showOnlyOnHover: true,
                        iconProps: {
                            iconName: 'ChromeClose',
                            styles: {
                                root: {
                                    fontSize: 12
                                }
                            }
                        }
                    }}
                    onChange={addColumn}
                    onResolveSuggestions={filterEntityAttributes} />
            </ThemeProvider>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {visibleColumns.map((column, i) => (
                                <Draggable key={column.name} draggableId={column.name} index={i}>
                                    {(provided, snapshot) => {
                                        const isColumnDisabled = column.name.includes('.');
                                        return (
                                            <div
                                                key={i}
                                                data-disabled={isColumnDisabled}
                                                className={styles.selectedAttribute}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <Text title={column.displayName}>{column.displayName}</Text>
                                                {visibleColumns?.length > 1 &&
                                                    <IconButton disabled={isColumnDisabled} onClick={() => markAttributeForDeletion(column)} iconProps={{
                                                        iconName: 'Delete'
                                                    }}
                                                    />
                                                }
                                            </div>
                                        );
                                    }}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </Panel>
    );
};
