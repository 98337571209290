import React from 'react';
import { DialogFooter, DefaultButton, PrimaryButton } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import Dialog from '../Dialog';
import { rootStyles } from './Styles';
import { IConfirmationDialogProps } from './interfaces';
import { defaultProps } from '../Dialog/Constants';

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = (props) => {
    return (
        <Dialog
            {...props}
            onDismiss={() => props.onDismiss()}
            modalProps={{
                ...props.modalProps,
                className: `${props.modalProps.className} ${rootStyles}`
            }}>
            {props.dialogContentProps.text &&
                props.dialogContentProps.text.split("\n").map(x => { return x.length === 0 ? <br></br> : <Text block className="TALXIS-Portal-confirmationDialog-text">{x}</Text>; })
                //<Text className="TALXIS-Portal-confirmationDialog-text">{props.dialogContentProps.text}</Text>
            }
            {props.children}
            <DialogFooter>
                <PrimaryButton
                    text={props.dialogContentProps.confirmButtonLabel ?
                        props.dialogContentProps.confirmButtonLabel :
                        window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/ErrorDialog/Ok")}
                    onClick={props.onConfirm} />
                <DefaultButton
                    text={props.dialogContentProps.cancelButtonLabel ?
                        props.dialogContentProps.cancelButtonLabel :
                        window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/ErrorDialog/Close")}
                    onClick={props.onCancel} />
            </DialogFooter>
        </Dialog>
    );
};
export default ConfirmationDialog;
//legacy code, should be deleted during refactoring
ConfirmationDialog.defaultProps = defaultProps as any;