import { IManyToManyRelationship, EntityDefinition } from "@app/interfaces/entitydefinition";
import { DomParser } from "@app/Constants";

// https://docs.microsoft.com/en-us/dotnet/api/microsoft.xrm.sdk.query.conditionoperator?view=dataverse-sdk-latest
export enum ConditionOperator {
    Unknown = -2,
    Equal = 0,
    DoesNotEqual = 1,
    GreaterThan = 2,
    LessThan = 3,
    GreaterThanOrEqual = 4,
    LessThanOrEqual = 5,
    Like = 6,
    NotLike = 7,
    DoesNotContainData = 12,
    NotNull = 13,
    Yesterday = 14,
    Today = 15,
    Tomorrow = 16,
    Last7Days = 17,
    Next7Days = 18,
    LastWeek = 19,
    ThisWeek = 20,
    LastMonth = 22,
    ThisMonth = 23,
    On = 25,
    OnOrBefore = 26,
    OnOrAfter = 27,
    LastYear = 28,
    ThisYear = 29,
    LastXDays = 33,
    NextXDays = 34,
    LastXMonths = 37,
    NextXMonths = 38,
    ContainsData = 49,
    ContainValues = 87,
    DoesNotContainValues = 88,
    BeginsWith = 54,
    DoesNotBeginWith = 55,
    EndsWith = 56,
    DoesNotEndWith = 57,
    In = 8,
    NotIn = 9
}

export const addLinkedEntity = (fetchXml: string, expression: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression): string => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");

    const linkedEntities = [...fetchXmlParsed.getElementsByTagName("link-entity")];

    const existingLink = linkedEntities.find(x => x.getAttribute("alias") === expression.alias);
    if (!existingLink) {
        const linkEntity = document.createElement("link-entity");
        linkEntity.setAttribute("alias", expression.alias);
        linkEntity.setAttribute("name", expression.name);
        linkEntity.setAttribute("from", expression.from);
        linkEntity.setAttribute("to", expression.to);
        linkEntity.setAttribute("link-type", expression.linkType);

        const entity = [...fetchXmlParsed.getElementsByTagName("entity")][0];
        entity.appendChild(linkEntity);

        fetchXml = serializeFetchXml(fetchXmlParsed);
    }

    return fetchXml;
};

export const getLinkedEntities = (fetchXml: string): ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression[] => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");

    const linkedEntities: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression[] = [];

    const linkedEntitiesElements = [...fetchXmlParsed.getElementsByTagName("link-entity")];
    for (const linkedEntity of linkedEntitiesElements) {
        linkedEntities.push({
            alias: linkedEntity.getAttribute("alias"),
            from: linkedEntity.getAttribute("from"),
            to: linkedEntity.getAttribute("to"),
            linkType: linkedEntity.getAttribute("link-type"),
            name: linkedEntity.getAttribute("name")
        });
    }

    return linkedEntities;
};

export const addManyToManyFilter = (
    fetchXml: string,
    entityDefinition: EntityDefinition,
    relationship: IManyToManyRelationship,
    linkExpression: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression,
    filterExpression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression,
    isSelfReferenced: boolean = false
): string => {
    const fetchXmlWithLinkEntity = addLinkedEntity(fetchXml, linkExpression);

    const fetchXmlParsed = DomParser.parseFromString(fetchXmlWithLinkEntity, "text/xml");
    const existingLink = [...fetchXmlParsed.getElementsByTagName("link-entity")].find(x => x.getAttribute("alias") === linkExpression.alias);

    const entityName = getEntityName(fetchXml);

    const linkEntity = document.createElement("link-entity");
    // Correctly locate the relationship direction - either parent > entity1 > child or parent > entity2 > child
    linkEntity.setAttribute("name", entityName === relationship.Entity2LogicalName ? relationship.Entity1LogicalName : relationship.Entity2LogicalName);
    linkEntity.setAttribute("from", isSelfReferenced ? entityDefinition.PrimaryIdAttribute : entityName === relationship.Entity2LogicalName ? relationship.Entity1IntersectAttribute : relationship.Entity2IntersectAttribute);
    linkEntity.setAttribute("to", entityName === relationship.Entity2LogicalName ? relationship.Entity1IntersectAttribute : relationship.Entity2IntersectAttribute);

    const filter = createFilterElement(filterExpression);
    linkEntity.appendChild(filter);

    existingLink.appendChild(linkEntity);

    return serializeFetchXml(fetchXmlParsed);
};

export const getEntityName = (fetchXml: string): string => {
    const parsedFetchXml = DomParser.parseFromString(fetchXml ?? "", "text/xml");
    return parsedFetchXml.getElementsByTagName("entity")[0].getAttribute("name");
};

export const setFilter = (fetchXml: string, expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression): string => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");

    if (expression?.conditions.length > 0) {
        const filter = createFilterElement(expression);

        fetchXmlParsed.getElementsByTagName("entity")[0].appendChild(filter);
    }

    const fetchXmlString = serializeFetchXml(fetchXmlParsed);

    return fetchXmlString;
};

const createFilterElement = (expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression): HTMLElement => {
    const filter = document.createElement("filter");
    filter.setAttribute("type", expression.filterOperator === 0 ? "and" : "or");

    for (const condition of expression.conditions) {
        let operator: string = null;

        switch (condition.conditionOperator) {
            case ConditionOperator.Like:
                operator = 'like';
                break;
            //@ts-ignore - not part of PCF typing, but does exists, TODO: we REALLY need to make our own typings, ideally in seperate package (client-libraries?)
            case ConditionOperator.NotLike: {
                operator = 'not-like';
                break;
            }
            case ConditionOperator.Equal:
                operator = 'eq';
                break;
            case ConditionOperator.DoesNotEqual:
                operator = 'ne';
                break;
            //@ts-ignore - same as above
            case ConditionOperator.NotNull:
            case ConditionOperator.ContainsData:
                condition.value = '';
                operator = 'not-null';
                break;
            case ConditionOperator.DoesNotContainData:
                condition.value = '';
                operator = 'null';
                break;
            case ConditionOperator.Yesterday:
                condition.value = '';
                operator = 'yesterday';
                break;
            case ConditionOperator.Today:
                condition.value = '';
                operator = 'today';
                break;
            case ConditionOperator.Tomorrow:
                condition.value = '';
                operator = 'tomorrow';
                break;
            case ConditionOperator.Last7Days:
                condition.value = '';
                operator = 'last-seven-days';
                break;
            case ConditionOperator.Next7Days:
                condition.value = '';
                operator = 'next-seven-days';
                break;
            case ConditionOperator.LastWeek:
                condition.value = '';
                operator = 'last-week';
                break;
            case ConditionOperator.ThisWeek:
                condition.value = '';
                operator = 'this-week';
                break;
            case ConditionOperator.LastMonth:
                condition.value = '';
                operator = 'last-month';
                break;
            case ConditionOperator.ThisMonth:
                condition.value = '';
                operator = 'this-month';
                break;
            case ConditionOperator.On:
                condition.value = condition.value || new Date().toISOString().split('T')[0];
                operator = "on";
                break;
            case ConditionOperator.OnOrBefore:
                condition.value = condition.value || new Date().toISOString().split('T')[0];
                operator = 'on-or-before';
                break;
            case ConditionOperator.OnOrAfter:
                condition.value = condition.value || new Date().toISOString().split('T')[0];
                operator = 'on-or-after';
                break;
            case ConditionOperator.LastYear:
                operator = 'last-year';
                break;
            case ConditionOperator.ThisYear:
                operator = 'this-year';
                break;
            case ConditionOperator.LastXDays:
                operator = 'last-x-days';
                break;
            case ConditionOperator.NextXDays:
                operator = 'next-x-days';
                break;
            case ConditionOperator.LastXMonths:
                operator = 'last-x-months';
                break;
            case ConditionOperator.NextXMonths:
                operator = 'next-x-months';
                break;
            case ConditionOperator.GreaterThan:
                operator = 'gt';
                break;
            case ConditionOperator.GreaterThanOrEqual:
                operator = 'ge';
                break;
            case ConditionOperator.LessThanOrEqual:
                operator = 'le';
                break;
            case ConditionOperator.LessThan:
                operator = 'lt';
                break;
            case ConditionOperator.ContainValues:
                operator = 'contain-values';
                break;
            // @ts-ignore - These values are not available in @types/pcf
            case ConditionOperator.DoesNotContainValues:
                operator = 'not-contain-values';
                break;
            // @ts-ignore - These values are not available in @types/pcf
            case ConditionOperator.BeginsWith:
                operator = 'begins-with';
                break;
            // @ts-ignore - These values are not available in @types/pcf
            case ConditionOperator.DoesNotBeginWith:
                operator = 'not-begin-with';
                break;
            // @ts-ignore - These values are not available in @types/pcf
            case ConditionOperator.EndsWith:
                operator = 'ends-with';
                break;
            // @ts-ignore - These values are not available in @types/pcf
            case ConditionOperator.DoesNotEndWith:
                operator = 'not-end-with';
                break;
            case ConditionOperator.In:
                operator = 'in';
                break;
            // @ts-ignore - These values are not available in @types/pcf
            case ConditionOperator.NotIn:
                operator = 'not-in';
                break;
        }

        if (operator === null) {
            throw new Error(`Unsupported operator: ${condition.conditionOperator}!`);
        }
        if (!Array.isArray(condition.value) || (condition.entityAliasName === "" || !condition.entityAliasName)) {
            const conditionElement = document.createElement("condition");
            conditionElement.setAttribute("attribute", condition.attributeName);
            conditionElement.setAttribute("operator", operator);
            if (condition.entityAliasName) {
                conditionElement.setAttribute("entityname", condition.entityAliasName);
            }
            if (!Array.isArray(condition.value)) {
                conditionElement.setAttribute("value", condition.value);
            }
            else {
                for (const value of condition.value) {
                    const valueElement = document.createElement("value");
                    valueElement.innerHTML = value;
                    conditionElement.appendChild(valueElement);
                }
            }
            filter.appendChild(conditionElement);
        }
        else {
            // @ts-ignore - These values are not available in @types/pcf
            if (condition.conditionOperator === ConditionOperator.In || condition.conditionOperator === ConditionOperator.NotIn) {
                // This has to be done via direct filters since Microsoft.Dynamics.CRM.In doesn't work correctly with nested expands
                const childFilter = document.createElement("filter");
                if (condition.conditionOperator === ConditionOperator.In) {
                    operator = "eq";
                    childFilter.setAttribute("type", "or");
                }
                else if (condition.conditionOperator === ConditionOperator.NotIn) {
                    operator = "ne";
                    childFilter.setAttribute("type", "and");
                }
                for (const value of condition.value) {
                    const valueElement = document.createElement("condition");
                    if (condition.entityAliasName) {
                        valueElement.setAttribute("entityname", condition.entityAliasName);
                    }
                    valueElement.setAttribute("value", value);
                    valueElement.setAttribute("attribute", condition.attributeName);
                    valueElement.setAttribute("operator", operator);
                    childFilter.appendChild(valueElement);
                }
                filter.appendChild(childFilter);
            }
            // @ts-ignore - These values are not available in @types/pcf
            else if (condition.conditionOperator === ConditionOperator.ContainValues || condition.conditionOperator === ConditionOperator.DoesNotContainValues) {
                const childFilter = document.createElement("filter");
                childFilter.setAttribute("type", "and");
                const conditionElement = document.createElement("condition");
                if (condition.entityAliasName) {
                    conditionElement.setAttribute("entityname", condition.entityAliasName);
                }
                conditionElement.setAttribute("attribute", condition.attributeName);
                conditionElement.setAttribute("operator", operator);
                for (const value of condition.value) {
                    const valueElement = document.createElement("value");
                    valueElement.innerHTML = value;
                    conditionElement.appendChild(valueElement);
                }
                childFilter.appendChild(conditionElement);
                filter.appendChild(childFilter);
            }
        }
    }

    return filter;
};

// This is for obtaining filter conditions back from FetchXml, but PCF only works with the ones set by the user instead of all the filters (this method will be however useful in future)
// export const getFilter = (fetchXml: string): ComponentFramework.PropertyHelper.DataSetApi.FilterExpression => {
//     const expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression = {
//         conditions: [],
//         filterOperator: null,
//         filters: []
//     };

//     const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
//     const filter = fetchXmlParsed.getElementsByTagName("filter");
//     if (filter.length > 0) {
//         expression.filterOperator = filter[0].getAttribute("type") == "and" ? 0 : 1;
//         for (const condition of filter[0].getElementsByTagName("condition")) {
//             // ConditionOperator.Unknown (-2) is made-up value to prevent conflicts with existing available values
//             let operator: ConditionOperator;
//             switch (condition.getAttribute("operator")) {
//                 case "like":
//                     operator = ConditionOperator.Like;
//                     break;
//                 default:
//                     operator = ConditionOperator.Unknown;
//                     break;
//             }
//             if (operator === ConditionOperator.Unknown) {
//                 throw new Error(`Unsupported operator ${condition.getAttribute("operator")} in FetchXml!`);
//             }
//             expression.conditions.push({
//                 attributeName: condition.getAttribute("attribute"),
//                 conditionOperator: operator,
//                 // TODO: Handle case of multiple values
//                 value: condition.getAttribute("value"),
//                 entityAliasName: condition.getAttribute("entityname")
//             });
//         }
//     }

//     return expression;
// }

export const getSorting = (fetchXml: string): ComponentFramework.PropertyHelper.DataSetApi.SortStatus[] => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
    const orderElements = fetchXmlParsed.getElementsByTagName("order");

    const sortStatus: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[] = [];

    for (const element of orderElements) {
        let name = element.getAttribute("attribute");
        if (element.parentNode.nodeName === "link-entity") {
            name = `${element.parentElement.getAttribute("alias")}.${name}`;
        }
        sortStatus.push({
            name: name,
            sortDirection: element.getAttribute("descending") === "true" ? 1 : 0
        });
    }

    return sortStatus;
};
export const setSorting = (fetchXml: string, sortStatus: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[]): string => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
    const orderElements = fetchXmlParsed.getElementsByTagName("order");

    // This cannot be done in for cycle due to being passed as reference
    while (orderElements.length > 0) {
        orderElements[0].remove();
    }

    for (const sort of sortStatus) {
        const sortNameSplit = sort.name.split(".");
        const order = document.createElement("order");
        order.setAttribute("attribute", sortNameSplit.pop());
        order.setAttribute("descending", sort.sortDirection === 1 ? "true" : "false");

        if (sort.name.includes(".")) {
            const linkEntity = fetchXmlParsed.querySelector(`link-entity[alias="${sortNameSplit[0]}"`);
            linkEntity.appendChild(order);
        }
        else {
            fetchXmlParsed.getElementsByTagName("entity")[0].appendChild(order);
        }
    }

    const fetchXmlString = serializeFetchXml(fetchXmlParsed);

    return fetchXmlString;
};

export const serializeFetchXml = (fetchXmlParsed: Document): string => {
    return new XMLSerializer().serializeToString(fetchXmlParsed)
        // Clean xmlns due to which FetchXml2OData fails
        .replaceAll('xmlns="http://www.w3.org/1999/xhtml"', '');
};

export const setPageSize = (fetchXml: string, pageSize: number): string => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
    fetchXmlParsed.getElementsByTagName("fetch")[0].setAttribute("count", pageSize.toString());

    return serializeFetchXml(fetchXmlParsed);
};

// Based on https://learn.microsoft.com/en-us/power-apps/developer/model-driven-apps/clientapi/reference/xrm-webapi/retrievemultiplerecords
export const updateFetchXmlWithPaging = (fetchXml: string, pagingCookie: string = null, page: number = null, count: number = null): string => {
    const xmlSerializer = new XMLSerializer();

    const fetchXmlDocument = DomParser.parseFromString(fetchXml, "text/xml");

    if (page) {
        fetchXmlDocument
            .getElementsByTagName("fetch")[0]
            .setAttribute("page", page.toString());
    }

    if (count) {
        fetchXmlDocument
            .getElementsByTagName("fetch")[0]
            .setAttribute("count", count.toString());
    }

    if (pagingCookie) {
        const cookieDoc = DomParser.parseFromString(pagingCookie, "text/xml");
        const innerPagingCookie = DomParser.parseFromString(
            decodeURIComponent(
                decodeURIComponent(
                    cookieDoc
                        .getElementsByTagName("cookie")[0]
                        .getAttribute("pagingcookie")
                )
            ),
            "text/xml"
        );
        fetchXmlDocument
            .getElementsByTagName("fetch")[0]
            .setAttribute(
                "paging-cookie",
                xmlSerializer.serializeToString(innerPagingCookie)
            );
    }

    return xmlSerializer.serializeToString(fetchXmlDocument);
};

export const getEntityAliasFromExpandAttribute = (fetchXml: string, attribute: string): string => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
    const linkElement = fetchXmlParsed.querySelector(`link-entity[to="${attribute}"]`);
    if (linkElement) {
        return linkElement.getAttribute("alias");
    }
    else {
        return null;
    }
};

export const getExpandAttributeFromAlias = (fetchXml: string, alias: string): string => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
    const linkElement = fetchXmlParsed.querySelector(`link-entity[alias="${alias}"]`);
    if (linkElement) {
        return linkElement.getAttribute("to");
    }
    else {
        return null;
    }
};

export const addAttributeIfMissing = (fetchXml: string, attributeName: string): string => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
    const attribute = fetchXmlParsed.querySelector('entity > attribute[name="' + attributeName + '"]');
    if (!attribute) {
        const attributeElement = fetchXmlParsed.createElement("attribute");
        attributeElement.setAttribute('name', attributeName);
        fetchXmlParsed.querySelector('entity').append(attributeElement);
    }
    return serializeFetchXml(fetchXmlParsed);
};

export const getEntityNameFromAlias = (fetchXml: string, alias: string): string => {
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
    const linkElement = fetchXmlParsed.querySelector(`link-entity[alias="${alias}"]`);
    if (linkElement) {
        return linkElement.getAttribute("name");
    }
    else {
        return null;
    }
};

export const addColumn = (fetchXml: string, columnName: string): string => {
    //TODO: handle expanded columns
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
    const attributeElement = fetchXmlParsed.createElement("attribute");
    attributeElement.setAttribute('name', columnName);
    fetchXmlParsed.querySelector('entity').append(attributeElement);
    return serializeFetchXml(fetchXmlParsed);
};

export const removeColumn = (fetchXml: string, columnName: string) => {
    //TODO: handle expanded columns
    const fetchXmlParsed = DomParser.parseFromString(fetchXml, "text/xml");
    const entityElement = fetchXmlParsed.querySelector('entity');
    const attributeElement = fetchXmlParsed.querySelector(`[name="${columnName}"]`);
    entityElement.removeChild(attributeElement);
    return serializeFetchXml(fetchXmlParsed);
};
