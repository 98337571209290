import { SitemapDefinition } from "@src/app/classes/definitions/SitemapDefinition";
import { SiteMap } from "@src/app/interfaces/sitemap";
import { useState } from "react";

const getSiteMapSubAreas = (): SiteMap.SubArea[] => {
    const subAreas = [];
    for (const area of SitemapDefinition.getCurrentSiteMap().getDefinition().areas) {
        for (const group of area.groups) {
            for (const subArea of group.subAreas) {
                subAreas.push(subArea);
            }
        }
    }
    return subAreas;
};

export const useInstalationDialog = (): [boolean, SiteMap.SubArea[], (visible: boolean) => void] => {
    const [dialogVisible, setDialogVisibility] = useState<boolean>(false);
    const [subAreas, setSubAreas] = useState<SiteMap.SubArea[]>([]);

    const toggleDialog = (visible: boolean) => {
        if (visible && subAreas.length === 0) {
            setSubAreas(getSiteMapSubAreas());
        }
        setDialogVisibility(visible);
    };

    return [dialogVisible, subAreas, toggleDialog];
};