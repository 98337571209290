import { Authentication, AuthenticationSource } from "./Authentication";

export class BotProtection {
    public static async getBotProtectionHeader(): Promise<RequestInit> {
        const request: RequestInit = {
            headers: {
                ...(this.requiresBotProtectionHeaders() && ({ 'Talxis-RequestVerification': `${await window.TALXIS.Portal.Context.getVerificationToken()}` }))
            }
        };
        return request;
    }
    public static requiresBotProtectionHeaders(): boolean {
        const source = Authentication.getAuthenticationSource();
        if ([AuthenticationSource.B2B, AuthenticationSource.B2C].includes(source) && Authentication.isAuthenticated()) {
            return false;
        }

        return true;
    }
}