import { IEntityRecord, IEntityColumn, Numeral } from "@talxis/base-controls";
import { DataProvider } from "./DataProvider";
import { DataType } from "@src/ComponentFramework/interfaces/DataType";
import { ColumnMetadata } from "./ColumnMetadata";
import { CURRENCY_NEGATIVE_PATTERN, CURRENCY_POSITIVE_PATTERN, Formatting } from "@src/ComponentFramework/PropertyClasses/Formatting/Formatting";
import { TransactionCurrencyDefinition } from "@src/app/classes/definitions/TransactionCurrencyDefinition";
import numeral from "numeral";
import { EntityDefinition } from "@src/app/classes/definitions/EntityDefinition";
import dayjs from "dayjs";
import { UserSettingsDefinition } from "@src/app/classes/definitions/UserSettingsDefinition";
import { UserSettings } from "@src/app/classes/models/UserSettings";

export class Record implements IEntityRecord {
    private _recordData: any;
    private _dataProvider: DataProvider;
    private _updatedColumnValues: Map<string, any> = new Map();
    private _userSettings: UserSettings;
    constructor(dataProvider: DataProvider, recordData: any) {
        this._recordData = recordData;
        this._dataProvider = dataProvider;
        this._userSettings = UserSettingsDefinition.getUserSettings();
    }

    public setValue(columnName: string, value: any) {
        const column = this._getColumnFromKey(columnName);
        if (value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
            value = null;
            this._updatedColumnValues.set(columnName, value);
            this._grid.render();
            return;
        }
        switch (column.dataType) {
            case DataType.MultiSelectPicklist: {
                //cases where we are removing the changes
                if (typeof value === 'string') {
                    value = value.split(',').map((value: string) => parseInt(value));
                }
                value = value.map((x: number) => x.toString()).join(',');
                break;
            }
            case DataType.Currency: {
                if (typeof value === 'number') {
                    break;
                }
                const numberFormatting = this._userSettings.numberFormattingInfo;
                const currency = TransactionCurrencyDefinition.getCurrencyById(this._getCurrencyId()) ?? TransactionCurrencyDefinition.getDefaultCurrency();
                const currencySymbol = currency.currencysymbol;
                const numberPattern = `\\d{1,}(${numberFormatting.currencyGroupSeparator}\\d{1,})*(\\${numberFormatting.currencyDecimalSeparator}\\d+)?`;
                const positivePattern = this._createCurrencyPattern(CURRENCY_POSITIVE_PATTERN[numberFormatting.currencyPositivePattern], numberPattern, currencySymbol);
                const negativePattern = this._createCurrencyPattern(CURRENCY_NEGATIVE_PATTERN[numberFormatting.currencyNegativePattern], numberPattern, currencySymbol);
                Numeral.currency({ ...numberFormatting, currencySymbol: currencySymbol });
                if (positivePattern.test(value)) {
                    value = numeral(value).value() ?? undefined;
                }
                else if (negativePattern.test(value)) {
                    const candidate = numeral(value).value()!;
                    if (candidate > 0) {
                        value = candidate * -1;
                    }
                    else {
                        value = candidate;
                    }
                }
                break;
            }
            case DataType.LookupCustomer:
            case DataType.LookupOwner:
            case DataType.LookupRegarding:
            case DataType.LookupSimple: {
                //the entity name gets returned in entityName due to compability with Power Apps
                //when it comes to entity references, they use multiple object variants, most of their
                //methods accepts only one variant which results in a lot of unnecessary mapping like this #smh
                const _value = value as (ComponentFramework.LookupValue & { entityName: string });
                value = {
                    etn: _value.entityName,
                    id: {
                        guid: _value.id
                    },
                    name: _value.name
                } as ComponentFramework.EntityReference;
                break;
            }
            case DataType.DateAndTimeDateOnly:
            case DataType.DateAndTimeDateAndTime: {
                if (value instanceof Date) {
                    const attributeMetadata = new ColumnMetadata(this._dataProvider, column).get();
                    const date = dayjs(value);
                    if (attributeMetadata.DateTimeBehavior === 'DateOnly') {
                        value = date.format('YYYY-MM-DD');
                    }
                    else {
                        value = date.toISOString();
                    }
                }
                break;
            }
        }
        this._updatedColumnValues.set(columnName, value);
        this._grid.render();
    }
    public async save(): Promise<void> {
        const updates: { [key: string]: any } = {};
        for (let [key, value] of this._updatedColumnValues.entries()) {
            const column = this._getColumnFromKey(key);
            switch (column.dataType) {
                case DataType.TwoOptions: {
                    if (value == '1') {
                        value = true;
                        break;
                    }
                    if (value == '0') {
                        value = true;
                        break;
                    }
                }
            }
            //change occured in lookup
            if (value?.etn) {
                const lookupEntityMetadata = await EntityDefinition.getAsync(value.etn);
                updates[`${key}@odata.bind`] = `${lookupEntityMetadata.EntitySetName}(${value.id.guid})`;
            }
            else {
                updates[key] = value;
            }
        }
        await window.Xrm.WebApi.updateRecord(this._dataProvider.entityName, this.getRecordId(), updates);
    }
    public getFormattedValue(columnName: string): string {
        const formatting = new Formatting(this._userSettings);
        const column = this._getColumnFromKey(columnName);
        if (!column) {
            return null;
        }

        const metadata = new ColumnMetadata(this._dataProvider, column);
        const attributeMetadata = metadata.get();
        let value = this._getValue(columnName);
        if (value === null || value === undefined) {
            return null;
        }
        switch (column.dataType) {
            case DataType.WholeNone: {
                if (typeof value === 'string') {
                    return value;
                }
                return formatting.formatInteger(value);
            }
            case DataType.WholeDuration: {
                if (typeof value === 'string') {
                    return value;
                }
                return formatting.formatDuration(value);
            }
            case DataType.Decimal: {
                const precision = attributeMetadata.attributeDescriptor.Precision;
                if (typeof value === 'string') {
                    return value;
                }
                return formatting.formatDecimal(value, precision);
            }
            case DataType.Currency: {
                const precision = attributeMetadata.attributeDescriptor.Precision;
                if (typeof value === 'string') {
                    return value;
                }
                const currency = TransactionCurrencyDefinition.getCurrencyById(this._getCurrencyId()) ?? TransactionCurrencyDefinition.getDefaultCurrency();
                return formatting.formatCurrency(value, precision, currency.currencysymbol);
            }
            case DataType.DateAndTimeDateOnly: {
                const date = dayjs(value);
                if (date.isValid()) {
                    return formatting.formatDateShort(date.toDate());
                }
                return value;
            }
            case DataType.DateAndTimeDateAndTime: {
                const behavior = attributeMetadata.attributeDescriptor.Behavior;
                const date = dayjs(value);
                if (date.isValid()) {
                    return formatting.formatTime(date.toDate(), behavior);
                }
                return value;
            }
            case DataType.TwoOptions:
            case DataType.OptionSet: {
                const [defaultValue, options] = metadata.getOptions();
                return options.find(x => x.Value == value)?.Label ?? null;
            }
            case DataType.MultiSelectPicklist: {
                const [defaultValue, options] = metadata.getOptions();
                value = value?.split(',');
                if (!value) {
                    return null;
                }
                return options.filter(option => value.includes(option.Value.toString())).map(x => x.Label).join('; ');
            }
            case DataType.LookupCustomer:
            case DataType.LookupOwner:
            case DataType.LookupRegarding:
            case DataType.LookupSimple: {
                return value.name;
            }
            default: {
                return value;
            }
        }
    }
    public getRecordId(): string {
        return this._recordData[this._dataProvider.entityDefinition.PrimaryIdAttribute];
    }
    public getValue(columnName: string): string | number | boolean | ComponentFramework.EntityReference | Date | number[] | ComponentFramework.EntityReference[] | ComponentFramework.LookupValue | ComponentFramework.LookupValue[] {
        const value = this._getValue(columnName);
        if (value === null || value === undefined) {
            return null;
        }
        const column = this._dataProvider.getColumns().find(x => x.name === columnName);
        if (!column) {
            return null;
        }
        switch (column.dataType) {
            case DataType.TwoOptions: {
                return value ? "1" : "0";
            }
        }
        return value;
    }
    public getNamedReference(): ComponentFramework.EntityReference {
        return {
            id: {
                guid: this.getRecordId()
            },
            name: this._recordData[this._dataProvider.entityDefinition.PrimaryNameAttribute],
            etn: this._dataProvider.entityName
        };
    }
    public updateRecordDataReference(recordData: any) {
        this._recordData = recordData;
    }

    public isDirty() {
        return this._updatedColumnValues.size !== 0;
    }

    private _getLookupReference(columnName: string): ComponentFramework.EntityReference {
        // TODO: Is the _xx_value still relevant with FetchXML?
        if (this._recordData[`_${columnName}_value`]) {
            return {
                id: {
                    guid: this._recordData[`_${columnName}_value`],
                },
                etn: this._recordData[`_${columnName}_value@Microsoft.Dynamics.CRM.lookuplogicalname`],
                name: this._recordData[`_${columnName}_value@OData.Community.Display.V1.FormattedValue`]
            };
        }
        else if (this._recordData[`${columnName}@Microsoft.Dynamics.CRM.lookuplogicalname`]) {
            return {
                id: {
                    guid: this._recordData[`${columnName}`]
                },
                etn: this._recordData[`${columnName}@Microsoft.Dynamics.CRM.lookuplogicalname`],
                name: this._recordData[`${columnName}@OData.Community.Display.V1.FormattedValue`]
            };
        }
        return null;
    }
    private get _grid() {
        return this._dataProvider.grid;
    }
    private _getValue(columnName: string) {
        const column = this._getColumnFromKey(columnName);
        if (this._updatedColumnValues.has(columnName)) {
            return this._updatedColumnValues.get(columnName);
        }
        switch (column?.dataType) {
            case DataType.LookupCustomer:
            case DataType.LookupOwner:
            case DataType.LookupRegarding:
            case DataType.LookupSimple: {
                return this._getLookupReference(columnName);
            }
        }
        return this._recordData[columnName];
    }
    private _getColumnFromKey(columnName: string): IEntityColumn | undefined {
        return this._dataProvider.getColumns().find(x => x.name === columnName);
    }
    private _getCurrencyId() {
        const currencyLookupReference = this.getValue('transactioncurrencyid') as ComponentFramework.EntityReference;
        if (currencyLookupReference) {
            return currencyLookupReference.id.guid;
        }
        return this._recordData['_transactioncurrencyid_value'];
    }
    private _escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    private _createCurrencyPattern(pattern: string, numberPattern: string, currencySymbol: string) {
        const escapedPattern = this._escapeRegExp(pattern);
        const escapedCurrencySymbolPattern = `(${this._escapeRegExp(currencySymbol)})?`;
        const finalPattern = escapedPattern.replace('\\$', escapedCurrencySymbolPattern).replace('n', numberPattern);
        return new RegExp(`^${finalPattern.replace(/\s/g, '')}$`);
    }
}