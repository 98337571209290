import React, { useRef } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import styles from './MainLoading.module.css';
import { Image as ImageComponent } from '@fluentui/react';
import { CSSTransition } from 'react-transition-group';

interface IMainLoadingProps {
    isLoading: boolean;
    loadingImageSrc?: string;
    loadingText?: string
}

const MainLoading: React.FC<IMainLoadingProps> = (props) => {
    return (
        <CSSTransition in={props.isLoading} timeout={300} unmountOnExit>
            {() => (
                <div className={styles.root}>
                    <div className={styles.spacer}></div>
                    <div className={styles.loadingWrapper}>
                        <div data-is-placeholder={!props.loadingImageSrc} className={styles.iconWrapper}>
                            <ImageComponent className={styles.icon} src={props.loadingImageSrc ?? '/images/appmodule.svg'} />
                        </div>
                        <Text className={styles.loadingText} variant="xLarge" >
                            {props.loadingText ?? window.TALXIS.Portal.Translations.getLocalizedString("@controls/loadings/MainLoading")}
                        </Text>
                        <ImageComponent className={styles.bottomLogo} src='/images/talxis_logo_black.png' />
                    </div>
                </div>
            )}
        </CSSTransition>
    );
};
export default MainLoading;