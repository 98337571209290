import React from 'react';
import { MessageBar, MessageBarButton, MessageBarType, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { IGlobalNotification } from './interfaces/IGlobalNotification';
import './css/styles.css';

interface IGlobalNotificationProps {
    notification: IGlobalNotification;
    index: number;
}

const getMessageBarType = (level: 1 | 2 | 3 | 4): MessageBarType => {
    switch (level) {
        case 1:
            return MessageBarType.success;
        case 2:
            return MessageBarType.error;
        case 3:
            return MessageBarType.warning;
        default:
            return MessageBarType.info;
    }
};

const getIconName = (level: 1 | 2 | 3 | 4): string | undefined => {
    switch (level) {
        case 2:
            return "Blocked";
        case 3:
            return "Warning";
        case 4:
            return "Info";
        default:
            return undefined;
    }
};

const GlobalNotification: React.FC<IGlobalNotificationProps> = (props) => {
    return (
        <MessageBar
            className='TALXIS__global-notification'
            messageBarIconProps={{
                iconName: props.notification.id === "__disabledNotification" ? "Lock12" : getIconName(props.notification.notification.level)
            }}
            messageBarType={getMessageBarType(props.notification.notification.level)}
            isMultiline={false}
            onDismiss={props.notification.notification.showCloseButton ? () => window.Xrm.App.clearGlobalNotification(props.notification.id) : undefined}
            actions={
                <div>
                    {props.notification.notification.action && props.notification.notification.action.actionLabel && props.notification.notification.action.eventHandler &&
                        <MessageBarButton onClick={() => { props.notification.notification.action.eventHandler(); }}>{props.notification.notification.action.actionLabel}</MessageBarButton>
                    }
                </div>
            }>
            <TooltipHost content={props.notification.notification.message}>
                {props.notification.notification.message}
            </TooltipHost>
        </MessageBar>
    );
};
export default GlobalNotification;