import { metadataRetrieveMultiple } from "./MetadataApi";
import { Theme } from "@models/Theme";
import { WebsiteDefinition } from "./WebsiteDefinition";
import { AppModule } from "../configuration/AppModule";
export class ThemeDefinition {
    private static _theme: Theme;
    public static get(): Theme {
        if (!this._theme) {
            throw new Error('Theme is not defined, have you called ThemeDefinition.loadAsync()?');
        }
        return this._theme;
    }
    public static async loadTheme() {
        const website = WebsiteDefinition.getWebsite();
        const appModule = AppModule.get();
        const themeId = website?.appModuleThemes.find(appModuleTheme => appModuleTheme.appModuleUniqueName === appModule.uniquename)?.themeId ?? website?.fallBackThemeId;
        const filterQuery = themeId ? `?$filter=talxis_configuration_themeid eq '${themeId}'` : "";
        let configurationThemeResponse = await metadataRetrieveMultiple(`v9.1/talxis_configuration_themes${filterQuery}`);
        if (!configurationThemeResponse?.entities) {
            this._theme = new Theme();
            return;
        }
        const configurationTheme = configurationThemeResponse.entities[0];
        this._theme = new Theme(configurationTheme);
    }
}
