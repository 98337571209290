export class NumberParser {
    public static tryParseHexadecimalStringToInt(hexadecimalErrorCode?: string): number | BigInt | undefined {
        if (hexadecimalErrorCode?.startsWith("0x") && hexadecimalErrorCode.length === 10) {
            return this._hexToSignedInt32(hexadecimalErrorCode);
        }
        else if (hexadecimalErrorCode?.startsWith("0x") && hexadecimalErrorCode.length === 18) {
            return this._hexToSignedInt64(hexadecimalErrorCode);
        }
        return undefined;
    }

    private static _hexToSignedInt32(hex: string): number {
        // parseInt(x) is not working with the sign bit by default
        const int32array = new Int32Array(1);
        int32array[0] = parseInt(hex, 16);

        return int32array[0];
    }

    private static _hexToSignedInt64(hex: string): BigInt {
        // parseInt(x) is not working with the sign bit by default
        const int64array = new BigInt64Array(1);
        int64array[0] = BigInt(hex);

        return int64array[0];
    }
}