import * as FetchXmlUtils from './FetchXmlUtils';
import { EntityDefinition, IManyToManyRelationship } from '@src/app/interfaces/entitydefinition';
export class FetchXmlBuilder {
    private _fetchXml: string;
    constructor(fetchXml: string) {
        this._fetchXml = fetchXml;
    }
    public setPageSize(pageSize: number) {
        this._fetchXml = FetchXmlUtils.setPageSize(this._fetchXml, pageSize);
        return this;
    }
    public setFilter(expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression) {
        this._fetchXml = FetchXmlUtils.setFilter(this._fetchXml, expression);
        return this;
    }
    public setManyToManyFilter(relationship: IManyToManyRelationship, linkExpression: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression, filterExpression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression, entityDefinition: EntityDefinition, isSelfReferenced?: boolean) {
        this._fetchXml = FetchXmlUtils.addManyToManyFilter(this._fetchXml, entityDefinition, relationship, linkExpression, filterExpression, isSelfReferenced);
        return this;
    }
    public setSorting(sortStatus: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[]) {
        this._fetchXml = FetchXmlUtils.setSorting(this._fetchXml, sortStatus);
        return this;
    }
    public setLinking(linkedEntityExpressions: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression[]) {
        for (const linkedEntityExpression of linkedEntityExpressions) {
            this._fetchXml = FetchXmlUtils.addLinkedEntity(this._fetchXml, linkedEntityExpression);
        }
        return this;
    }
    public addAttributeIfMissing(attribute: string) {
        this._fetchXml = FetchXmlUtils.addAttributeIfMissing(this._fetchXml, attribute);
        return this;
    }
    public setColumns(columns: string[]) {
        for (const column of columns) {
            this._fetchXml = FetchXmlUtils.addColumn(this._fetchXml, column);
        }
        return this;
    }
    public build(): string {
        return this._fetchXml;
    }
    public isEqual(fetchXml: string) {
        return this._fetchXml === fetchXml;
    }
}