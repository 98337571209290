import * as React from 'react';
import { IControlProps } from './interfaces';
import { FieldControl } from './FieldControl';
import { FormControlType } from "@controls/native/Form/interfaces/enums";
import { ErrorBoundary } from 'react-error-boundary';
import { MessageBarType, MessageBar } from '@fluentui/react';
import { DatasetControl } from './DatasetControl/DatasetControl';

export const Control: React.FC<IControlProps> = React.memo((props) => {
    const renderControl = () => {
        if (props.name === null) {
            return <MessageBar messageBarType={MessageBarType.error}>
                Unable to find control for field: {props.datafieldname}
            </MessageBar>;
        }
        if (props.type === FormControlType.DataSet) {
            return <DatasetControl {...props} />;
        }
        else {
            return <FieldControl {...props} />;
        }
    };
    return (
        <div>
            <ErrorBoundary
                FallbackComponent={(props) => <MessageBar>
                    {props.error.message ?? "An error ocurred during control render. This is mostlikely an issue with the control engine"}
                </MessageBar>}
            >
                {renderControl()}
            </ErrorBoundary>
        </div>
    );
});