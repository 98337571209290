import '@fluentui/react/dist/css/fabric.css';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import App from './app';
import AppProvider from '@providers/AppProvider';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import '@app/css/styles.css';
import { Registration } from '@pages/Registration';
import { Login } from '@pages/Login';
import { Admin } from '@pages/Admin';
import { createGlobalWindowProps } from '@app/Functions';
import TelemetryProvider from '@providers/TelemetryProvider';
import { HistoryProvider, history } from './providers/HistoryProvider/HistoryProvider';

// This is for local debugging only! https://dev.azure.com/thenetworg/INT0015/_wiki/wikis/INT0015.wiki/4076/Developing?anchor=performance
// Run `npm i @welldone-software/why-did-you-render`
// const whyDidYouRender = require('@welldone-software/why-did-you-render');
// whyDidYouRender(React, {
//   // trackAllPureComponents: true,
//   logOnDifferentValues: true
// });

window.addEventListener('load', () => {
  // Unregister all orphaned service workers.
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(serviceWorkers => {
      serviceWorkers.forEach(x => x.unregister());
    });
  }
});

const rootElement = document.getElementById('root');
initializeIcons(undefined, { disableWarnings: true });

// First we want to initialize window.TALXIS.Portal and other functions so we have localization and everything ready for our use
// TODO: This creates duplicate instances of WebApi and bunch of other stuff, we should unify it to have global instances in the AppContext and re-used across the application
createGlobalWindowProps();

ReactDOM.render(
  <TelemetryProvider history={history}>
    <Router history={history}>
      <Switch>
        <Route path="/registration" component={Registration} />
        <Route path="/login" component={Login} />
        <Route path="/_admin" component={Admin} />
        <Route>
          <AppProvider>
            <HistoryProvider>
              <App />
            </HistoryProvider>
          </AppProvider>
        </Route>
      </Switch>
    </Router>
  </TelemetryProvider>,
  rootElement);
