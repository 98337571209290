export class DateFormattingInfo implements ComponentFramework.UserSettingApi.DateFormattingInfo {
    public readonly amDesignator: string;
    public readonly abbreviatedDayNames: string[];
    public readonly abbreviatedMonthGenitiveNames: string[];
    public readonly abbreviatedMonthNames: string[];
    //static, not needed
    public readonly calendarWeekRule: number = 0;
    public readonly calendar: ComponentFramework.UserSettingApi.Calendar = {
        minSupportedDateTime: new Date("0001-01-01T00:00:00"),
        maxSupportedDateTime: new Date("9999-12-31T23:59:59.9999999"),
        //static, not needed
        algorithmType: 1,
        //static, not needed
        calendarType: 1,
        //static, not needed
        twoDigitYearMax: 2049,
    };
    public readonly dateSeparator: string;
    public readonly dayNames: string[];
    public readonly firstDayOfWeek: ComponentFramework.UserSettingApi.Types.DayOfWeek = 0;
    public readonly fullDateTimePattern: string;
    public readonly longDatePattern: string;
    public readonly longDateAbbreviatedPattern: string;
    public readonly longTimePattern: string;
    public readonly monthDayPattern: string;
    public readonly monthGenitiveNames: string[];
    public readonly monthNames: string[];
    public readonly pmDesignator: string;
    public shortDatePattern: string;
    public shortTimePattern: string;
    public readonly shortestDayNames: string[];
    //not needed, uses fullDateTimePattern
    public readonly sortableDateTimePattern: string;
    public readonly timeSeparator: string;
    //not needed, uses fullDateTimePattern
    public readonly universalSortableDateTimePattern: string;
    public readonly yearMonthPattern: string;
    private readonly _is12Hour: boolean;
    private readonly _locale: string;

    constructor(locale: string) {
        this._locale = locale;
        this._is12Hour = this._getIs12Hour();
        [this.amDesignator, this.pmDesignator] = this._getDesignators();
        this.abbreviatedDayNames = this._getDayNames();
        this.abbreviatedMonthNames = this._getMonthNames();
        this.dayNames = this._getDayNames(true);
        this.monthNames = this._getMonthNames(true);
        this.monthGenitiveNames = this._getGenitiveMonthNames();
        this.abbreviatedMonthGenitiveNames = this.abbreviatedMonthNames;
        this.shortestDayNames = this.abbreviatedDayNames;
        this.timeSeparator = this._getSeparator('time');
        this.dateSeparator = this._getSeparator('date');
        this.shortDatePattern = this._getShortDatePattern();
        this.shortTimePattern = this._getShortTimePattern();
        this.monthDayPattern = this._getMonthDayPattern();
        this.yearMonthPattern = this._getYearMonthPattern();
        this.longTimePattern = this._getLongTimePattern();
        this.longDatePattern = this._getLongDatePattern();
        this.longDateAbbreviatedPattern = this._getLongDatePattern(true);
        this.fullDateTimePattern = this._getFullDateTimePattern();
        this.sortableDateTimePattern = this.fullDateTimePattern;
        this.universalSortableDateTimePattern = this.fullDateTimePattern;
    }
    private _getIs12Hour(): boolean {
        return new Intl.DateTimeFormat(this._locale, { hour: 'numeric', minute: 'numeric' }).formatToParts(EXAMPLE_DATE).find(part => part.type === 'dayPeriod') ? true : false;
    }
    private _getDesignators() {
        const formatter = new Intl.DateTimeFormat(this._locale, {
            hour12: true,
            hour: 'numeric'
        });
        const amDesignator = formatter.formatToParts(new Date('1970-01-01T08:00:00')).find(part => part.type === 'dayPeriod').value;
        const pmDesignator = formatter.formatToParts(new Date('1970-01-01T15:00:00')).find(part => part.type === 'dayPeriod').value;

        return [amDesignator, pmDesignator];
    }
    private _getDayNames(long?: boolean) {
        const formatter = new Intl.DateTimeFormat(this._locale, { weekday: long ? 'long' : 'short' });
        const dayNames = [];
        for (let day = 0; day < 7; day++) {
            const date = new Date(2021, 0, 3 + day); // starts on Sunday
            dayNames.push(formatter.format(date));
        }
        return dayNames;
    }
    private _getMonthNames(long?: boolean) {
        const formatter = new Intl.DateTimeFormat(this._locale, { month: long ? 'long' : 'short' });
        const monthNames = [];
        for (let month = 0; month < 12; month++) {
            const date = new Date(2021, month, 1);
            monthNames.push(formatter.format(date));
        }
        return monthNames;
    }
    private _getGenitiveMonthNames() {
        const date = new Date(2021, 0, 1); // Start with January
        const genitiveMonthNames = [];

        for (let month = 0; month < 12; month++) {
            date.setMonth(month);
            const formattedDate = new Intl.DateTimeFormat(this._locale, {
                day: 'numeric',
                month: 'long'
            }).format(date);

            const monthName = formattedDate.replace(/[\d\s.]+/g, '').trim();
            genitiveMonthNames.push(monthName);
        }

        return genitiveMonthNames;
    }

    private _getSeparator(type: 'date' | 'time') {
        const options: Intl.DateTimeFormatOptions = type === 'time' ? {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        } : {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        };
        const formatter = new Intl.DateTimeFormat(this._locale, options);
        return formatter.formatToParts(EXAMPLE_DATE).find(x => x.type === 'literal').value;
    }
    private _getShortDatePattern() {
        const formatter = new Intl.DateTimeFormat(this._locale, { year: 'numeric', month: 'numeric', day: 'numeric' });
        const parts = formatter.formatToParts(EXAMPLE_DATE).map(part => {
            switch (part.type) {
                case 'year':
                    //MS has 'yyyy' there which is not correct for dayjs
                    //they replace it with upperCase during formatting
                    part.value = 'YYYY';
                    break;
                //if someone complaines that he wants to see 01.02.2023 instead of 1.2.2023, we will need to have formatting settings in user settings
                case 'month':
                    part.value = 'M';
                    break;
                case 'day':
                    //MS has 'd' which is wrong, d is day of week, not day of month,
                    //they fix this during formatting
                    part.value = 'D';
                    break;
            }
            return part.value;
        });
        return parts.join('').replaceAll(String.fromCharCode(160), ' ');
    }
    private _getMonthDayPattern() {
        const formatter = new Intl.DateTimeFormat(this._locale, { month: 'long', day: 'numeric' });
        const parts = formatter.formatToParts(EXAMPLE_DATE).map(part => {
            switch (part.type) {
                case 'month':
                    part.value = 'MMMM';
                    break;
                case 'day':
                    part.value = 'dd';
                    break;
            }
            return part.value;
        });
        return parts.join('').replaceAll(String.fromCharCode(160), ' ');
    }
    private _getShortTimePattern() {
        const formatter = new Intl.DateTimeFormat(this._locale, { hour: 'numeric', minute: 'numeric' });
        const parts = formatter.formatToParts(EXAMPLE_DATE).map(part => {
            switch (part.type) {
                case 'hour':
                    part.value = this._is12Hour ? 'h' : 'H';
                    break;
                case 'minute':
                    part.value = 'mm';
                    break;
                case 'dayPeriod':
                    //MS has 'tt' there which is not correct for dayjs
                    //they replace the 'tt' with 'A' during formatting
                    part.value = 'A';
                    break;
            }
            return part.value;
        });
        return parts.join('').replaceAll(String.fromCharCode(160), ' ');
    }
    private _getLongTimePattern() {
        const formatter = new Intl.DateTimeFormat(this._locale, { hour: 'numeric', minute: 'numeric', second: '2-digit' });
        const parts = formatter.formatToParts(EXAMPLE_DATE).map(part => {
            switch (part.type) {
                case 'hour':
                    part.value = this._is12Hour ? 'h' : 'H';
                    break;
                case 'minute':
                    part.value = 'mm';
                    break;
                case 'second':
                    part.value = 'ss';
                    break;
                case 'dayPeriod':
                    //MS has 'tt' there which is not correct for dayjs
                    //they replace the 'tt' with 'A' during formatting
                    part.value = 'A';
                    break;
            }
            return part.value;
        });
        return parts.join('').replaceAll(String.fromCharCode(160), ' ');
    }
    private _getYearMonthPattern() {
        const formatter = new Intl.DateTimeFormat(this._locale, { year: 'numeric', month: 'long' });
        const parts = formatter.formatToParts(EXAMPLE_DATE).map(part => {
            switch (part.type) {
                case 'year':
                    //MS has 'yyyy' there which is not correct for dayjs
                    //they replace it with upperCase during formatting
                    part.value = 'YYYY';
                    break;
                case 'month':
                    part.value = 'MMMM';
                    break;
            }
            return part.value;
        });
        return parts.join('').replaceAll(String.fromCharCode(160), ' ');
    }
    private _getLongDatePattern(abbreviated?: boolean) {
        const formatter = new Intl.DateTimeFormat(this._locale, { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' });
        const parts = formatter.formatToParts(EXAMPLE_DATE).map(part => {
            switch (part.type) {
                case 'year':
                    //MS has 'yyyy' there which is not correct for dayjs
                    //they replace it with upperCase during formatting
                    part.value = 'YYYY';
                    break;
                case 'month':
                    part.value = abbreviated ? 'MMM' : 'MMMM';
                    break;
                case 'weekday':
                    part.value = abbreviated ? 'ddd' : 'dddd';
                    break;
                case 'day':
                    //MS has 'd' which is wrong, d is day of week, not day of month,
                    //they fix this during formatting
                    part.value = 'D';
                    break;
            }
            return part.value;
        });
        return parts.join('').replaceAll(String.fromCharCode(160), ' ');
    }
    private _getFullDateTimePattern() {
        const formatter = new Intl.DateTimeFormat(this._locale, { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long', hour: 'numeric', minute: 'numeric', second: '2-digit' });
        const parts = formatter.formatToParts(EXAMPLE_DATE).map(part => {
            switch (part.type) {
                case 'year':
                    //MS has 'yyyy' there which is not correct for dayjs
                    //they replace it with upperCase during formatting
                    part.value = 'YYYY';
                    break;
                case 'month':
                    part.value = 'MMMM';
                    break;
                case 'weekday':
                    part.value = 'dddd';
                    break;
                case 'day':
                    //MS has 'd' which is wrong, d is day of week, not day of month,
                    //they fix this during formatting
                    part.value = 'D';
                    break;
                case 'hour':
                    part.value = this._is12Hour ? 'h' : 'H';
                    break;
                case 'minute':
                    part.value = 'mm';
                    break;
                case 'second':
                    part.value = 'ss';
                    break;
                case 'dayPeriod':
                    //MS has 'tt' there which is not correct for dayjs
                    //they replace the 'tt' with 'A' during formatting
                    part.value = 'A';
                    break;
            }
            return part.value;
        });
        return parts.join('').replaceAll(String.fromCharCode(160), ' ');

    }
}
const EXAMPLE_DATE = new Date('1970-01-01T13:00:00');