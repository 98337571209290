import { IDatasetDataProvider } from "./IDatasetDataProvider";
import { Grid } from "../Grid";
import { IViewDefinition, IDataSetColumn } from "../../native/View/interfaces/viewdefinition";
import { EntityDefinition as IEntityDefinition } from '@src/app/interfaces/entitydefinition';
import { EntityDefinition } from "@src/app/classes/definitions/EntityDefinition";
import { ViewDefinition } from "@src/app/classes/definitions/ViewDefinition";
import * as FetchXmlUtils from '../utils/FetchXmlUtils';
import { ColumnLayoutJson } from "@src/app/interfaces/columnLayoutJson";
import { FetchXmlBuilder } from "../utils/FetchXmlBuilder";
import { DomParser } from "@src/app/Constants";
import { ConditionOperator } from "../utils/FetchXmlUtils";
import { sanitizeGuid } from "@src/app/Functions";
import { IGridNativeStateValues, IXrmGridRelationship, XrmRelationshipType } from "../interfaces";
import { Record } from './Record';
import { IOptionSetDefinition } from "@src/app/interfaces/optionset";
import { IODataResponse } from "@src/app/interfaces/general";
import { OptionSetDefinition } from "@src/app/classes/definitions/OptionSetDefinition";
import { UserSettingsDefinition } from "@src/app/classes/definitions/UserSettingsDefinition";

export class DataProvider implements IDatasetDataProvider {
    private _grid: Grid;
    private _columns: IDataSetColumn[];
    private _nativeStateValuesRef: React.MutableRefObject<IGridNativeStateValues>;
    private _currentPage: Xrm.RetrieveMultipleResult;
    private _entityDefinition: IEntityDefinition;
    private _viewDefinition: IViewDefinition;
    private _title: string;
    private _loading: boolean = true;
    private _records: Map<string, Record> = new Map();

    /**
     * Initial fetchXml as present in ViewDefinition. It also includes relationships if they are present.
     * Used as base fetchXml on which all run time changes get applied.
     */
    private _initialFetchXml: string;
    private _userAddedColumnNames: string[] = [];
    private _relationship: IXrmGridRelationship;
    private _quickFindFilterConditions: ComponentFramework.PropertyHelper.DataSetApi.ConditionExpression[];
    private _viewId: string;
    private _entityName: string;
    //also includes entity definitions for linked entities and their option sets
    private _entitiesMetadata: {
        [entityName: string]: IEntityDefinition & {
            OptionSets: IODataResponse<IOptionSetDefinition>
        }
    } = {};

    constructor(grid: Grid,
        nativeStateValuesRef: React.MutableRefObject<IGridNativeStateValues>) {
        this._grid = grid;
        this._entityName = this._grid.bindings?.TargetEntityType?.value;
        this._nativeStateValuesRef = nativeStateValuesRef;
    }
    public async init(initializeDefaultState: (viewDefinition: IViewDefinition, fetchXml: string) => void) {
        //TODO: optimize loading
        this._entityDefinition = await EntityDefinition.getAsync(this.entityName);
        this._viewId = this._grid.bindings.ViewId?.value || this._grid.bindings?.[this._grid.datasetBindingName]?.ViewId || await this._getInitialViewId();
        this._viewDefinition = await ViewDefinition.getAsync(this._grid.viewId, this.entityName);
        this._relationship = await this._getRelationship();
        this._quickFindFilterConditions = await this._getQuickFindFilterConditions();
        this._initialFetchXml = await this._getInitialFetchXml(this._quickFindFilterConditions, this._relationship);
        initializeDefaultState(this._viewDefinition, this._initialFetchXml);
        if (this.getSorting().length === 0) {
            this.setSorting(FetchXmlUtils.getSorting(this._initialFetchXml));
        }
        const pageLink = this._nativeStateValuesRef.current.previousPages.at(-1);
        this._columns = await ViewDefinition.createColumnsAsync(this._viewDefinition, this._layoutjson, this._initialFetchXml);
        //the localized title should ideally be in the current view definition, it currently always gets czech translations
        this._title = (await ViewDefinition.getViewNamesAndIds(this.entityName)).find(x => x.savedqueryid === sanitizeGuid(this.viewId))?.name ?? this._viewDefinition.name;
        this._userAddedColumnNames = this._getUserAddedColumnsFromState();
        this._currentPage = await this._getEntities(pageLink);
    }

    public get initialFetchXml() {
        return this._initialFetchXml;
    }

    public get entityName() {
        return this._entityName;
    }

    public get viewId() {
        return this._viewId;
    }

    public get relationship() {
        return this._relationship;
    }
    public get grid() {
        return this._grid;
    }
    /**
    * This FetchXml is used as a base for applying filters, sorting and attributes.
    * It can only be directly modified by QueryBuilder
    */
    public get fetchXml() {
        return this._nativeStateValuesRef.current.fetchxml;
    }
    public get entityDefinition() {
        return this._entityDefinition;
    }
    public get entitiesMetadata() {
        return this._entitiesMetadata;
    }
    /**
    * Takes the FetchXml from view definition and applies all user defines filters, sorting and attributes.
    */
    public buildFetchXml(): string {
        const builder = new FetchXmlBuilder(this.fetchXml);
        const fetchXml = builder.setFilter(this._getQuickFindFilterExpression())
            .setFilter(this.getFiltering())
            .setSorting(this.getSorting())
            .setPageSize(this._pageSize)
            .setColumns(this._userAddedColumnNames)
            .build();
        return fetchXml;
    }
    public get quickFindColumnNames() {
        return this._quickFindFilterConditions.map(condition => condition.attributeName);
    }
    public set fetchXml(value: string) {
        this._nativeStateValuesRef.current.fetchxml = value;
    }
    public getSorting(): ComponentFramework.PropertyHelper.DataSetApi.SortStatus[] {
        return this._nativeStateValuesRef.current.userSorting;
    }
    public getLinking(): ComponentFramework.PropertyHelper.DataSetApi.Linking {
        return {
            addLinkedEntity: (expression) => {
                if (!this._userLinkedEntities.find(entity => entity.name === expression.name)) {
                    this._userLinkedEntities.push(expression);
                }
            },
            //return linking that comes from binding and user actions
            getLinkedEntities: () => {
                const builder = new FetchXmlBuilder(this.fetchXml);
                const fetchXml = builder.setLinking(this._userLinkedEntities).build();
                return FetchXmlUtils.getLinkedEntities(fetchXml);
            }
        };
    }
    public isLoading(): boolean {
        return this._loading;
    }
    public isError(): boolean {
        throw new Error("Method not implemented.");
    }
    public getErrorMessage(): string {
        throw new Error("Method not implemented.");
    }
    public setSorting(sorting: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[]): void {
        this._nativeStateValuesRef.current.userSorting = sorting;
    }
    public setFiltering(filtering: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression): void {
        this._nativeStateValuesRef.current.userFilterExpression = filtering;
    }
    public getFiltering(): ComponentFramework.PropertyHelper.DataSetApi.FilterExpression {
        return this._nativeStateValuesRef.current.userFilterExpression;
    }
    public async refresh(): Promise<ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[]> {
        this._pageNumber = 1;
        this._previousPages = [];
        this._currentPage = await this._getEntities();
        return this._currentPage.entities;
    }
    getRecords(): ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[] {
        const records: Record[] = [];
        for (const [key, recordData] of Object.entries(this._currentPage.entities)) {
            const recordId = recordData[this._entityDefinition.PrimaryIdAttribute];
            if (!this._records.get(recordId)) {
                this._records.set(recordId, new Record(this, recordData));
            }
            const record = this._records.get(recordId);
            record.updateRecordDataReference(recordData);
            records.push(record);
        }
        return records;
    }

    public getPaging(): ComponentFramework.PropertyHelper.DataSetApi.Paging {
        return {
            firstPageNumber: this._pageNumber,
            hasNextPage: !!this._nextLink,
            hasPreviousPage: this._pageNumber > 1,
            lastPageNumber: this._pageNumber,
            pageSize: this._pageSize,
            //@ts-ignore - not part of typings, but is used in Power Apps,
            pageNumber: this._pageNumber,
            loadExactPage: (pageNumber) => () => this._loadExactPage(pageNumber),
            loadNextPage: () => this._loadNextPage(),
            loadPreviousPage: () => this._loadPreviousPage(),
            reset: () => this._grid.refresh(),
            setPageSize: (pageSize: number) => {
                this._pageSize = pageSize;
                if (this._grid.isHomepageGrid) {
                    UserSettingsDefinition.getUserSettings().setPagingLimit(pageSize);
                }
            },
            //@ts-ignore - not part of typings, but is used in Power App,
            totalResultCount: this._currentPage._totalRecordCount
        };
    }
    getColumns(): ComponentFramework.PropertyHelper.DataSetApi.Column[] {
        return this._columns;
    }
    save(record: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord): void {
        throw new Error("Method not implemented.");
    }
    getTitle?(): string {
        return this._title;
    }
    public isDirty(): boolean {
        const builder = new FetchXmlBuilder(this._initialFetchXml);
        return !builder.isEqual(this.buildFetchXml());
    }
    public async addColumn(name: string) {
        const layout: ColumnLayoutJson.RootObject = JSON.parse(this._layoutjson);
        layout.Rows[0].Cells.push({
            Width: 150,
            Name: name,
            RelatedEntityName: '',
            IsHidden: false,
            ImageProviderFunctionName: '',
            ImageProviderWebresource: '',
            __userAdded: true

        });
        this._layoutjson = JSON.stringify(layout);
        this._columns = await ViewDefinition.createColumnsAsync(this._viewDefinition, this._layoutjson, this._initialFetchXml);
        this._userAddedColumnNames.push(name);
    }
    public async removeColumn(columnName: string) {
        const layout: ColumnLayoutJson.RootObject = JSON.parse(this._layoutjson);
        layout.Rows[0].Cells = layout.Rows[0].Cells.filter(cell => cell.Name !== columnName);
        this._layoutjson = JSON.stringify(layout);
        this._columns = await ViewDefinition.createColumnsAsync(this._viewDefinition, this._layoutjson, this._initialFetchXml);
        const filteredConditions = this.getFiltering()?.conditions.filter(condition => condition.attributeName !== columnName);
        if (filteredConditions) {
            this.setFiltering({
                ...this.getFiltering(),
                conditions: filteredConditions
            });
        }
        this._columns = this._columns.filter(x => x.name !== columnName);
        this._userAddedColumnNames = this._userAddedColumnNames.filter(x => x !== columnName);
    }
    public updateColumnOrder(columnOrder: string[]) {
        this._columns.sort((a, b) => columnOrder.indexOf(a.name) - columnOrder.indexOf(b.name));
        const layout: ColumnLayoutJson.RootObject = JSON.parse(this._layoutjson);
        layout.Rows[0].Cells.sort((a, b) => columnOrder.indexOf(a.Name) - columnOrder.indexOf(b.Name));
        this._layoutjson = JSON.stringify(layout);
    }
    private get _userLinkedEntities() {
        return this._nativeStateValuesRef.current.userLinkedEntities;
    }
    private get _nextLink() {
        return this._currentPage.nextLink;
    }
    private get _layoutjson() {
        return this._nativeStateValuesRef.current.layoutjson;
    }
    private get _pageSize() {
        return this._nativeStateValuesRef.current.pageSize;
    }
    private set _pageSize(value: number) {
        this._nativeStateValuesRef.current.pageSize = value;
    }
    private get _pageNumber() {
        return this._nativeStateValuesRef.current.pageNumber;
    }
    private set _pageNumber(value: number) {
        this._nativeStateValuesRef.current.pageNumber = value;
    }
    private get _previousPages() {
        return this._nativeStateValuesRef.current.previousPages;
    }
    private set _previousPages(values: string[]) {
        this._nativeStateValuesRef.current.previousPages = values;
    }
    private set _layoutjson(value: string) {
        this._nativeStateValuesRef.current.layoutjson = value;
    }
    private _getUserAddedColumnsFromState() {
        const layout: ColumnLayoutJson.RootObject = JSON.parse(this._layoutjson);
        return layout.Rows[0].Cells.filter(cell => cell.__userAdded).map(cell => cell.Name);
    }
    private async _loadPreviousPage() {
        if (this._pageNumber === 0) {
            return;
        }
        const previousPageLink = this._previousPages.at(-2);
        this._previousPages.pop();
        this._currentPage = await this._getEntities(previousPageLink);
        this._pageNumber--;

    }
    private async _loadNextPage() {
        if (!this._nextLink) {
            return;
        }
        this._previousPages.push(this._currentPage.nextLink);
        this._currentPage = await this._getEntities(this._nextLink);
        this._pageNumber++;
    }
    private async _loadExactPage(pageNumber: number) {
        throw new Error('Not implemented');
    }
    private async _getEntities(nextLink?: string) {
        this._loading = true;
        const fetchXml = this.buildFetchXml();
        let options = `?fetchXml=${encodeURIComponent(fetchXml)}&$count=true`;
        if (nextLink) {
            const nextLinkParts = nextLink.split('?');
            options = `?${nextLinkParts[1]}`;
        }
        const result = await window.Xrm.WebApi.retrieveMultipleRecords(this.entityName, options, this._pageSize);
        const entities = [this.entityName, ...this.getLinking().getLinkedEntities().map(x => x.name)];
        for (const entity of entities) {
            this._entitiesMetadata[entity] = {
                ...await EntityDefinition.getAsync(entity),
                OptionSets: await OptionSetDefinition.getAsync(entity)
            };
        }
        this._loading = false;
        return result;
    }
    private _getRelationship(): IXrmGridRelationship | null {
        if (!this._grid.bindings.RelationshipName?.value) {
            return null;
        }
        let relationship = this._entityDefinition.ManyToOneRelationships.find(x => x.SchemaName === this._grid.bindings.RelationshipName.value);
        if (relationship) {
            return {
                attributeName: relationship.ReferencingAttribute,
                entityName: this.entityName,
                name: relationship.SchemaName,
                recordId: this._grid.formContext.entityId,
                relationshipType: XrmRelationshipType.OneToMany,
                metadata: {
                    oneToMany: relationship
                }
            };
        }
        else if (this._entityDefinition.ManyToManyRelationships.find(x => x.SchemaName === this._grid.bindings.RelationshipName.value)) {
            const many2Many = this._entityDefinition.ManyToManyRelationships.find(x => x.SchemaName === this._grid.bindings.RelationshipName.value);
            // Correctly locate the relationship direction - either parent > entity1 > child or parent > entity2 > child
            const currentEntityIntersectAttribute = many2Many.Entity1LogicalName === this.entityName ? many2Many.Entity1IntersectAttribute : many2Many.Entity2IntersectAttribute;
            const relatedEntityIntersectAttribute = many2Many.Entity1LogicalName === this.entityName ? many2Many.Entity2IntersectAttribute : many2Many.Entity1IntersectAttribute;

            return {
                attributeName: many2Many.Entity2IntersectAttribute,
                entityName: this.entityName,
                name: many2Many.SchemaName,
                recordId: this._grid.formContext.entityId,
                relationshipType: XrmRelationshipType.ManyToMany,
                metadata: {
                    many2Many: {
                        ...many2Many,
                        CurrentEntityIntersectAttribute: currentEntityIntersectAttribute,
                        RelatedEntityIntersectAttribute: relatedEntityIntersectAttribute
                    }
                }
            };
        }
        throw new Error(`Unable to find relationship ${this._grid.bindings.RelationshipName.value} in entity definitions!`);
    }
    //needs to be run after relationship and quickfind filters get resolved
    private async _getInitialFetchXml(quickFindFilterConditions: ComponentFramework.PropertyHelper.DataSetApi.ConditionExpression[], relationship?: IXrmGridRelationship): Promise<string> {
        const builder = new FetchXmlBuilder(this._viewDefinition.fetchxml);
        // Distinct aliases https://codeburst.io/javascript-array-distinct-5edc93501dc4
        const aliases = [...new Set(quickFindFilterConditions.map(x => x.entityAliasName).filter(x => x !== null && x !== undefined))];
        const quickFindViewDefinition = await ViewDefinition.getQuickFindViewAsync(this.entityName);
        const parsedDefinition = DomParser.parseFromString(quickFindViewDefinition.fetchxml, "text/xml");
        const linkedEntities = [...parsedDefinition.getElementsByTagName("link-entity")];
        builder.setLinking(aliases.map(alias => {
            const link = linkedEntities.find(x => x.getAttribute("alias") === alias);
            return {
                alias: alias,
                from: link.getAttribute("from"),
                linkType: link.getAttribute("link-type"),
                name: link.getAttribute("name"),
                to: link.getAttribute("to")
            };
        }));
        // If FetchXML doesn't contain the PrimaryId, it needs to be added so that we can create links etc.
        builder.addAttributeIfMissing(this._entityDefinition.PrimaryIdAttribute);

        if (!relationship) {
            return builder.build();
        }
        if (relationship.relationshipType === XrmRelationshipType.OneToMany) {
            const oneToMany = relationship.metadata.oneToMany;
            builder.setLinking([
                {
                    alias: "__relatedEntityFilter",
                    from: oneToMany.ReferencedAttribute,
                    linkType: "inner",
                    name: oneToMany.ReferencedEntity,
                    to: oneToMany.ReferencingAttribute
                }
            ]);
            builder.setFilter({
                filterOperator: 0,
                conditions: [
                    {
                        attributeName: oneToMany.ReferencedAttribute,
                        conditionOperator: 0,
                        value: this._grid.formContext.entityId,
                        entityAliasName: "__relatedEntityFilter"
                    }
                ]
            });
        }
        else {
            const many2Many = this.relationship.metadata.many2Many;
            builder.setManyToManyFilter(many2Many, {
                alias: "__relatedEntityFilter",
                from: many2Many.CurrentEntityIntersectAttribute,
                linkType: "inner",
                name: many2Many.IntersectEntityName,
                to: many2Many.CurrentEntityIntersectAttribute
            }, {
                filterOperator: 0,
                conditions: [
                    {
                        attributeName: many2Many.RelatedEntityIntersectAttribute,
                        conditionOperator: 0,
                        value: this._grid.formContext.entityId
                    }
                ]
            }, this._entityDefinition, many2Many.Entity1LogicalName === many2Many.Entity2LogicalName);
        }
        return builder.build();
    }
    private async _getInitialViewId() {
        const availableViews = await ViewDefinition.getViewNamesAndIds(this.entityName, this._grid.bindings?.ViewIds?.value.split(','));
        if (availableViews.length > 0) {
            return availableViews[0].savedqueryid;
        }
        throw new Error(`Not view found for entity ${this.entityName}!`);
    }
    private async _getQuickFindFilterConditions() {
        const quickFindViewDefinition = await ViewDefinition.getQuickFindViewAsync(this.entityName);
        const parsedDefinition = DomParser.parseFromString(quickFindViewDefinition.fetchxml, "text/xml");
        const quickFindFilters = [...parsedDefinition.getElementsByTagName("condition")].filter(x => x.getAttribute("value") === "{0}");
        const conditions: ComponentFramework.PropertyHelper.DataSetApi.ConditionExpression[] = [];

        for (const filter of quickFindFilters) {
            let attributeName = filter.getAttribute("attribute");
            const definition = await EntityDefinition.getAsync(filter.getAttribute("entityname") ?? this.entityName);
            const attribute = definition.Attributes.find(x => x.LogicalName === filter.getAttribute("attribute"));
            if (attribute.AttributeType === "Lookup" || attribute.AttributeType === "Customer" || attribute.AttributeType === "Owner") {
                attributeName += "name";
            }
            let operator = ConditionOperator.Like;
            conditions.push({
                attributeName: attributeName,
                conditionOperator: operator,
                value: null,
                entityAliasName: filter.getAttribute("entityname")
            });
        }
        return conditions;
    }
    private _getQuickFindFilterExpression(): ComponentFramework.PropertyHelper.DataSetApi.FilterExpression {
        if (!this._grid.quickFindSearchValue) {
            return null;
        }
        return {
            filterOperator: 1,
            conditions: (() => {
                return this._quickFindFilterConditions.map(condition => {
                    return {
                        ...condition,
                        // if the search text starts with *
                        // we should use the like operator with % at the start and end
                        // otherwise, we should use the like operator with % at the end (behaves as begins with)
                        value: this._grid.quickFindSearchValue.startsWith('*') ?
                            `${this._grid.quickFindSearchValue.replace('*', '%')}%` :
                            `${this._grid.quickFindSearchValue}%`,
                    } as ComponentFramework.PropertyHelper.DataSetApi.ConditionExpression;
                });
            })()
        };
    }
}