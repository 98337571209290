import { GetEntityMetadataResponse } from "@src/Xrm/GetEntityMetadataResponse";
import { DataProvider } from "./DataProvider";
import { IEntityColumn } from "@talxis/base-controls";

export class ColumnMetadata {
    private _dataProvider: DataProvider;
    private _attributeName: string;
    private _entityAliasName: string | null;

    constructor(dataProvider: DataProvider, column: IEntityColumn) {
        this._dataProvider = dataProvider;
        this._entityAliasName = column.alias;
        this._attributeName = this._entityAliasName ? column.name.split('.')[1] : column.name;
    }
    public get(): ComponentFramework.PropertyHelper.EntityMetadata {
        if (this._entityAliasName) {
            const linkedEntity = this._dataProvider.getLinking().getLinkedEntities().find(x => x.alias === this._entityAliasName)!;
            const entityMetadata = this._dataProvider.entitiesMetadata[linkedEntity.name];
            return new GetEntityMetadataResponse(entityMetadata, [this._attributeName], entityMetadata.OptionSets).Attributes.get(this._attributeName);
        }
        else {
            const entityMetadata = this._dataProvider.entitiesMetadata[this._dataProvider.entityName];
            return new GetEntityMetadataResponse(entityMetadata, [this._attributeName], entityMetadata.OptionSets).Attributes.get(this._attributeName);
        }
    }
    public getOptions(): [number | boolean, ComponentFramework.PropertyHelper.OptionMetadata[]] {
        const options = this.get().attributeDescriptor.OptionSet as ComponentFramework.PropertyHelper.OptionMetadata[];
        const defaultValue = this.get().DefaultFormValue as number | boolean;
        return [defaultValue, options];
    }
}