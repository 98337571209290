import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { ITabsProps } from './interfaces';
export const getTabCommandBarItems = (props: ITabsProps, isMobile: boolean): ICommandBarItemProps[] => {
    const tabItems: ICommandBarItemProps[] = [];

    for (const tab of props.tabs) {
        if (tab.showLabel && tab.visible && (!isMobile || tab.availableForPhone)) {
            const tabItem: ICommandBarItemProps = {
                key: tab.id,
                text: tab.label,
                iconProps: {
                    iconName: tab.iconName
                },
                'data-id': `tablist-${tab.name}`,
                onClick: () => props.onTabChange(tab)
            };
            tabItems.push(tabItem);
        }
    }
    return tabItems;
};
