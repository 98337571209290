import { AppModule } from "@src/app/classes/configuration/AppModule";
import { ManifestDefinition } from "@src/app/classes/definitions/ManifestDefinition";
import { useState } from "react";

let installEvent: Event;
window.addEventListener('beforeinstallprompt', (e: Event) => {
    e.preventDefault();
    installEvent = e;
});

export const useInstallPrompt = (): [boolean, () => any, () => void] => {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const install = async () => {
        //@ts-ignore - not included in types
        installEvent.prompt();
        //@ts-ignore - not included in types
        const { outcome } = await installEvent.userChoice;
        if (outcome === 'accepted') {
            setVisible(false);
        }
        return outcome;
    };
    const dismiss = () => {
        window.localStorage.setItem(`installPrompt_${AppModule.get().uniquename}`, 'false');
        setVisible(false);
    };
    const isPromptVisible = () => {
        if (installEvent === undefined && !iOS) {
            return false;
        }
        if (ManifestDefinition.isOpenedInPWA()) {
            return false;
        }
        if (window.localStorage.getItem(`installPrompt_${AppModule.get().uniquename}`) === 'false') {
            return false;
        }
        return true;
    };
    //show if never shown before
    const [visible, setVisible] = useState<boolean>(() => isPromptVisible());
    return [visible, install, dismiss];
};