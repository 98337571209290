import { mergeStyles, getTheme } from "@fluentui/react/lib/Styling";
const theme = getTheme();
export const rootStyles = mergeStyles(({
    selectors: {
        '.ms-Dialog-subText': {
            fontSize: 16,
            fontWeight: 600,
            marginBottom: 5,
            color: theme.palette.black
        },
        '.TALXIS-Portal-confirmationDialog-text': {
            color: theme.palette.black
        }
    }
}));