import { Ribbon } from './Ribbon';
import { SCRIPT_ISOLATION } from '@src/app/Constants';
import { RibbonLocation } from '../../definitions/RibbonDefinition';

export class FormRibbon extends Ribbon {
    protected _formContext: Xrm.FormContext;
    //TODO: once form gets refactored, there should be one object representing form that contains
    //all of the information (similar to grid passed to DatasetRibbonController)
    private _enableRibbon: boolean;
    constructor(formContext: Xrm.FormContext, enableRibbon: boolean) {
        super({
            shouldIsolateScripts: SCRIPT_ISOLATION,
            getEntityName: () => formContext.data.entity.getEntityName(),
            getLocation: () => RibbonLocation.Form,
            getFormContext: () => formContext,
            getParameterValuesOverride: (parameters) => this._getParameterValues(parameters),
            isEnabled: () => this.isEnabled(),
            //should come from Form instance when refactored (similar to grid)
            isInitialized: () => true,

        });
        this._formContext = formContext;
        this._enableRibbon = enableRibbon;
    }

    public isEnabled(): boolean {
        if (this._formContext.data.entity.getEntityName() === 'talxis_portalpage') {
            return false;
        }
        //pure dialogs - not bound to an entity
        if (!this._formContext.data.entity.getEntityName()) {
            return false;
        }
        if (!this._enableRibbon) {
            return false;
        }
        return true;
    }

    protected _getParameterValues(parameters: Ribbon.Definition.ButtonFunctionParameter[]): string[] {
        const parameterValues: any[] = [];
        for (const parameter of parameters) {
            //TODO: should be parameter.name
            switch (parameter.value) {
                //needs to be here because Microsoft is stupid and doesnt follow its own docs
                case "SelectedControl": {
                    parameterValues.push(this._formContext);
                    break;
                }
                //the previous implementation would always push null into this if the buttons was not on grid
                case 'entity': {
                    parameterValues.push(this._formContext);
                    break;
                }
                default:
                    parameterValues.push(super._getParameterValues([parameter])[0] ?? null);
                    break;
            }

        }
        return parameterValues;
    }

}