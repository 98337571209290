import { EntityDefinition as IEntityDefinition, ISimpleEntityDefinition, LocalizedLabel } from '../../interfaces/entitydefinition';
import { Exception } from '../exceptions/Exception';
import { sendMetadataGetRequest } from './MetadataApi';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';
import { IODataResponse } from '../../interfaces/general';
import { LocalizeLabel } from '@src/localization/helpers';

export class EntityDefinition {
    private static _entityDefinition: IPromiseCache<IEntityDefinition> = {};

    static async preloadAsync(metadataIds: string[]): Promise<string[]> {
        console.groupCollapsed("Entity Definitions Preload");
        if (!metadataIds || metadataIds.length === 0) {
            console.log("No entities to preload");
            console.groupEnd();
            return;
        }
        const timeStart = performance.now();
        console.log(`Preloading entity definitions for:`, metadataIds);

        const entities: IEntityDefinition[] = [];

        // Querying EntityDefinitions has limit of 25 entities per request
        const chunkSize = 25;
        for (let i = 0; i < metadataIds.length; i += chunkSize) {
            const chunk = metadataIds.slice(i, i + chunkSize);
            const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions?$filter=${chunk.map(x => `MetadataId eq '${x}'`).join(' or ')}&$select=LogicalName,DisplayCollectionName,PrimaryNameAttribute,PrimaryIdAttribute,PrimaryImageAttribute,SchemaName,EntitySetName,Description,DisplayName,MetadataId,IsQuickCreateEnabled,IsActivity,IsValidForAdvancedFind&$expand=Attributes,OneToManyRelationships($select=SchemaName,RelationshipType,IsValidForAdvancedFind,ReferencedAttribute,ReferencedEntity,ReferencingAttribute,ReferencingEntity,ReferencedEntityNavigationPropertyName,ReferencingEntityNavigationPropertyName),ManyToOneRelationships($select=SchemaName,RelationshipType,IsValidForAdvancedFind,ReferencedAttribute,ReferencedEntity,ReferencingAttribute,ReferencingEntity,ReferencedEntityNavigationPropertyName,ReferencingEntityNavigationPropertyName),ManyToManyRelationships($select=SchemaName,RelationshipType,IsValidForAdvancedFind,Entity1LogicalName,Entity1IntersectAttribute,Entity1NavigationPropertyName,Entity2LogicalName,Entity2IntersectAttribute,Entity2NavigationPropertyName,IntersectEntityName)`);
            if (!response.ok) {
                throw new Exception('Unable to preload entity definitions!', new Exception(await response.text()));
            }
            const entityDefinitions: IODataResponse<IEntityDefinition> = await response.json();
            entities.push(...entityDefinitions.value);
        }

        const entityLogicalNames: string[] = [];

        for (const entity of entities) {
            entityLogicalNames.push(entity.LogicalName);
            this._entityDefinition[entity.LogicalName] = new Promise<IEntityDefinition>(async (resolve) => {
                resolve(entity);
            });
        }

        console.log(`Preloading entity definitions took:`, performance.now() - timeStart);
        console.groupEnd();

        return entityLogicalNames;
    }

    static async getAsync(): Promise<ISimpleEntityDefinition[]>;
    static async getAsync(entityName: string): Promise<IEntityDefinition>;
    static async getAsync(entityName?: string): Promise<IEntityDefinition | ISimpleEntityDefinition[]> {
        if (entityName === undefined) entityName = '*ALL*';
        return cachedWrapper(entityName, () => new Promise<IEntityDefinition | ISimpleEntityDefinition[]>(async (resolve, reject) => {
            try {
                if (entityName === '*ALL*') {
                    const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions?$select=LogicalName,DisplayName,MetadataId`);
                    if (!response.ok) {
                        throw new Error(`Entities could not be fetched!`);
                    }

                    const simpleEntitiesDefinitions = (await response.json()).value;
                    resolve(simpleEntitiesDefinitions.map((def: any) => ({
                        ...def,
                        DisplayName: LocalizeLabel(def.DisplayName.LocalizedLabels as LocalizedLabel[]),
                    })));
                    return;
                }

                const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions(LogicalName='${entityName}')?$select=LogicalName,DisplayCollectionName,PrimaryNameAttribute,PrimaryIdAttribute,PrimaryImageAttribute,SchemaName,EntitySetName,Description,DisplayName,MetadataId,IsQuickCreateEnabled,IsActivity,IsValidForAdvancedFind&$expand=Attributes,OneToManyRelationships($select=SchemaName,RelationshipType,IsValidForAdvancedFind,ReferencedAttribute,ReferencedEntity,ReferencingAttribute,ReferencingEntity,ReferencedEntityNavigationPropertyName,ReferencingEntityNavigationPropertyName),ManyToOneRelationships($select=SchemaName,RelationshipType,IsValidForAdvancedFind,ReferencedAttribute,ReferencedEntity,ReferencingAttribute,ReferencingEntity,ReferencedEntityNavigationPropertyName,ReferencingEntityNavigationPropertyName),ManyToManyRelationships($select=SchemaName,RelationshipType,IsValidForAdvancedFind,Entity1LogicalName,Entity1IntersectAttribute,Entity1NavigationPropertyName,Entity2LogicalName,Entity2IntersectAttribute,Entity2NavigationPropertyName,IntersectEntityName)`);
                if (!response.ok) {
                    throw new Error(`Entity ${entityName} could not be fetched!`);
                }
                const entityDefinition: IEntityDefinition = await response.json();
                resolve(entityDefinition);
            }
            catch (error) {
                reject(error);
            }
        }), this._entityDefinition);
    }
}