import { ICommandBarProps } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { CommandBar } from '@talxis/react-components';
import { isEqual } from 'lodash';
//This wrapper is needed so the buttons do not re-render on every scroll, this is Fluent UI related bug (https://github.com/microsoft/fluentui/issues/5487)
export const CommandBarWrapper: React.FC<ICommandBarProps> = (props) => {
    const [state, setState] = useState<ICommandBarProps>(null);
    useEffect(() => {
        if (!isEqual(props?.farItems, state?.farItems)) {
            setState(props);
        }
    }, [props]);
    return <CommandBar {...state} />;
};