import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Authentication } from '@app/classes/Authentication';
import { SpaConfiguration } from '@configuration/SpaConfiguration';
import MainLoading from '../components/loadings/MainLoading';

export const Login: React.FC = () => {
    const search = useLocation().search;
    const domainHint = new URLSearchParams(search).get('domain_hint');

    useEffect(() => {
        SpaConfiguration.loadAsync().then(() => {
            return Authentication.loginRedirect({
            }, "/", domainHint);
        });
    }, []);

    return (
        <div>
            <MainLoading isLoading={true} />
        </div>
    );
};