import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Authentication } from '@app/classes/Authentication';
import { SpaConfiguration } from '@configuration/SpaConfiguration';
import MainLoading from '../components/loadings/MainLoading';

export const Registration: React.FC = () => {
    const search = useLocation().search;
    const accessPrincipalId = new URLSearchParams(search).get('accessPrincipalId');
    const email = new URLSearchParams(search).get('email');

    useEffect(() => {
        SpaConfiguration.loadAsync().then(() => {
            return Authentication.loginRedirect({
                "accessPrincipalId": accessPrincipalId,
                "email": email,
                "isCustomSignupFromEmail": "true",
            }, "/", null, SpaConfiguration.get().b2cSignupPolicy ? SpaConfiguration.get().authority + SpaConfiguration.get().b2cSignupPolicy : null);
        });
    }, []);

    return (
        <div>
            <MainLoading isLoading={true} />
        </div>
    );
};