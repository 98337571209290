import { FRONTEND_VERSION } from "@app/Constants";

interface IFrameConfig {
    context: Window | undefined;
    id: string;
}

export class IFrameFactory {
    private static _iframes: { [key: string]: HTMLIFrameElement } = {};
    private static _promises: { [key: string]: Promise<HTMLIFrameElement> } = {};
    public static async createIFrame(config: IFrameConfig): Promise<HTMLIFrameElement> {
        const { id, context } = config;
        const iframe = document.createElement('iframe');
        const _iframeId = `ClientApiFrame_${id}`;

        if (!this._promises[_iframeId]) {
            this._promises[_iframeId] = new Promise<HTMLIFrameElement>((resolve, reject) => {
                iframe.id = _iframeId;
                iframe.src = `empty.html?v=${FRONTEND_VERSION}`;
                iframe.name = _iframeId;
                iframe.setAttribute('style', 'display: none');
                iframe.onload = () => {
                    if (context) {
                        iframe.contentWindow.Xrm = context.Xrm;
                        iframe.contentWindow.TALXIS = {
                            Portal: context.TALXIS.Portal,
                            DevKit: context.TALXIS.DevKit
                        };
                    }
                    resolve(iframe);
                };
                iframe.onerror = () => reject(new Error(`Unable to load iframe ${id} from ${iframe.src}!`));
                document.body.appendChild(iframe);
            });
            this._iframes[_iframeId] = iframe;
        }
        return this._promises[_iframeId];
    };

    public static destroyIFrame(id: string): void {
        const _iframeId = `ClientApiFrame_${id}`;
        const iframe = this._iframes[_iframeId];
        if (!iframe) {
            return;
        }
        iframe.parentElement?.removeChild(iframe);
        delete this._iframes[_iframeId];
        delete this._promises[_iframeId];
    }
}
