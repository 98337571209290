import React from 'react';
import { IPanelProps, Panel as BasePanel, PanelType } from '@fluentui/react';
import './styles.css';
//TODO: Add this to shared components?
const getClassName = (props: IPanelProps): string => {
    let className = 'TALXIS__panel';
    if (props.className) {
        className += ` ${props.className}`;
    }
    return className;
};

export const minimalPanelWidth = 405;

const Panel: React.FC<IPanelProps> = (props) => {
    return <BasePanel
        {...props}
        className={getClassName(props)}
        type={PanelType.custom}
        customWidth={props.customWidth ?? minimalPanelWidth.toString() + 'px'}
    />;
};
export default Panel;