import { APPLICATION_RIBBON_ENTITY_NAME, SCRIPT_ISOLATION } from "@src/app/Constants";
import { RibbonLocation } from "../../definitions/RibbonDefinition";
import { Ribbon } from './Ribbon';

export class AppRibbon extends Ribbon {
    constructor(onControlsResolved: () => void) {
        super({
            shouldIsolateScripts: SCRIPT_ISOLATION,
            getEntityName: () => APPLICATION_RIBBON_ENTITY_NAME,
            getFormContext: () => null,
            getLocation: () => RibbonLocation.Global,
            isEnabled: () => true,
            getParameterValuesOverride: (parameters) => this._getParameterValues(parameters),
            onControlsResolved: () => onControlsResolved(),
            isInitialized: () => true
        });
    }
}