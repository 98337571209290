import React from 'react';
import ReactDOM from "react-dom";
import { TextField as TextFieldComponent } from "@talxis/base-controls";
import { ITextField } from "@talxis/base-controls";

interface IInputs {
    value: ComponentFramework.PropertyTypes.StringProperty;
    enableDeleteButton?: ComponentFramework.PropertyTypes.StringProperty;
    enableCopyButton?: ComponentFramework.PropertyTypes.StringProperty;
    EnableTypeSuffix?: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
    ForceDisable?: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
    value?: string;
}

export class TextField implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _outputs: IOutputs = {};
    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = container;
    }
    public updateView(context: ComponentFramework.Context<IInputs>): void {
        ReactDOM.render(React.createElement(TextFieldComponent, {
            context: context,
            parameters: {
                value: context.parameters.value,
                EnableDeleteButton: {
                    raw: context.parameters.enableDeleteButton?.raw === 'true'
                },
                EnableCopyButton: {
                    raw: context.parameters.enableCopyButton?.raw === 'true'
                },
                ForceDisable: {
                    raw: context.parameters.ForceDisable?.raw === 'true'
                },
                EnableTypeSuffix: {
                    raw: context.parameters.EnableTypeSuffix?.raw === 'false' ? false : true
                },
            },
            onNotifyOutputChanged: (outputs) => {
                this._outputs = outputs;
                this._notifyOutputChanged();
            }
        } as ITextField), this._container);
    }

    public getOutputs(): IOutputs {
        return this._outputs;
    }

    public destroy(): void {
        ReactDOM.unmountComponentAtNode(this._container);
    }
}