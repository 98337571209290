import React, { useState } from "react";
import { IFileComponentProps } from "../interfaces/IFileComponentProps";
import { ActionButton, DefaultButton, IconButton } from "@fluentui/react/lib/Button";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { mergeStyles } from "@fluentui/react/lib/Styling";

export const FileComponent: React.FC<IFileComponentProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(props.isUploadInProgress ?? false);
    const [isDeleteInProgress, setIsDeleteInProgress] = useState<boolean>(false);

    const onClickDownload = async () => {
        setLoading(true);
        await props.onClickDownload();
        setLoading(false);
    };

    const onClickDelete = async () => {
        setIsDeleteInProgress(true);
        if (await props.onClickDelete() === false) {
            setIsDeleteInProgress(false);
        }
    };

    React.useEffect(() => {
        setIsUploadInProgress(props.isUploadInProgress ?? false);
        setIsDeleteInProgress(false);
    }, [props]);

    return (<>
        {!props.fileName && !isUploadInProgress &&
            <DefaultButton
                disabled={props.isControlDisabled}
                text={props.getLocalizedString("uploadButtonText")}
                onClick={props.onClickUpload}
                iconProps={{ iconName: "OpenFile" }} />
        }

        {(isUploadInProgress || isDeleteInProgress) &&
            <Spinner
                label={isUploadInProgress ? props.getLocalizedString("uploadInProgress") : props.getLocalizedString("deleteInProgress")}
                labelPosition="right"
                styles={{
                    root: {
                        padding: "5px",
                        justifyContent: "left"
                    },
                }}
            />
        }

        {
            props.fileName && !isDeleteInProgress &&
            <div className={mergeStyles({
                display: "flex",
                alignItems: "center"
            })}>
                {loading &&
                    <Spinner
                        styles={{
                            root: {
                                padding: "5px",
                                alignItems: "unset"
                            },
                            label: {
                                fontSize: 14
                            }
                        }}
                        label={props.fileName}
                        labelPosition="right"
                    />
                }
                {!loading &&
                    <ActionButton
                        styles={{
                            root: {
                                height: "unset",
                                overflow: "hidden"
                            },
                            label: {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            },
                            textContainer: {
                                overflow: "hidden"
                            }
                        }}
                        iconProps={{ iconName: "DownloadDocument" }}
                        onClick={onClickDownload}
                        title={props.fileName}>
                        {props.fileName}
                    </ActionButton>
                }
                {!props.isControlDisabled &&
                    <IconButton
                        styles={{
                            root: {
                                marginLeft: "auto"
                            }
                        }}
                        iconProps={{ iconName: "Delete" }}
                        disabled={loading}
                        onClick={onClickDelete} />
                }
            </div >
        }
    </>);
};