import isEqual from 'lodash/isEqual';
import React from 'react';
import ReactDOM from "react-dom";
import { Text } from "@fluentui/react";
import { Form as FormComponent } from './Form';
import { IFormProps, IFormControlStateValues } from './interfaces';
import * as QueryString from 'query-string';
import { QueryData } from '@pages/Control/interfaces';
import { Liquid } from "liquidjs";
import { DomParser } from '@app/Constants';

interface IInputs {
    value: ComponentFramework.PropertyTypes.LookupProperty;
    QuickForms: ComponentFramework.PropertyTypes.StringProperty;
    entityName: ComponentFramework.PropertyTypes.StringProperty;
    entityId: ComponentFramework.PropertyTypes.StringProperty;
    formId: ComponentFramework.PropertyTypes.StringProperty;
    extraQs: ComponentFramework.PropertyTypes.StringProperty;
    formUniqueName: ComponentFramework.PropertyTypes.StringProperty;
    isQuickCreate: ComponentFramework.PropertyTypes.StringProperty;
    isDialog: ComponentFramework.PropertyTypes.StringProperty;
    isNavbarLoaded: ComponentFramework.PropertyTypes.StringProperty;
    DisplayCommandbar: ComponentFramework.PropertyTypes.StringProperty;
    isMainTopLevel: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
    // TODO: Not sure what this should return right now, but probably Xrm EntityReference right now
    // value?: ComponentFramework.LookupValue;
}
interface IQuickForms {
    entityName: string;
    formId: string;
}

export class Form implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _value: ComponentFramework.LookupValue = undefined;
    private _quickForms: IQuickForms = null;
    private _state: IFormControlStateValues;

    private _liquid: Liquid;

    constructor() {
        this._liquid = new Liquid();
    }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement): void {
        this._context = context;
        this._state = state as IFormControlStateValues;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        container.appendChild(this._container);
    }

    private _parseQuickForms(quickForms: string): IQuickForms {
        const quickFormsXml = DomParser.parseFromString(quickForms, "text/xml");

        // Sample: <QuickForms><QuickFormIds><QuickFormId entityname="account">E9388EE6-C985-4F4C-9B2A-BEB5044FE3AC</QuickFormId></QuickFormIds></QuickForms>
        const quickFormId = quickFormsXml.getElementsByTagName("QuickFormId")[0];
        return {
            entityName: quickFormId.getAttribute("entityname"),
            formId: quickFormId.textContent
        };
    }

    private _isMainFormOrDialog(): boolean {
        // Check for nested form control properties present
        return !(!!this._context.parameters.value?.raw && !!this._context.parameters.QuickForms?.raw);
    }

    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;

        if (this._isMainFormOrDialog()) {
            const props: IFormProps = {
                mode: context.mode,
                state: this._state,
                disabled: this._context.mode.isControlDisabled,
                entityId: this._context.parameters.entityId.raw,
                entityName: this._context.parameters.entityName.raw,
                formId: this._context.parameters.formId.raw,
                formUniqueName: this._context.parameters.formUniqueName.raw,
                isQuickCreate: this._context.parameters.isQuickCreate.raw == "true",
                isDialog: this._context.parameters.isDialog.raw == "true",
                isNavbarLoaded: this._context.parameters.isNavbarLoaded.raw == "true",
                enableRibbon: this._context.parameters.DisplayCommandbar?.raw == "true",
                isMainTopLevel: this._context.parameters.isMainTopLevel?.raw == "true",
                extraqs: !!this._context.parameters.extraQs?.raw ? QueryString.parse(this._context.parameters.extraQs.raw, {
                    parseBooleans: true,
                    parseNumbers: true
                }) : null,
                getTranslatedString: this._getTranslation.bind(this),
                fireEvent: (eventName, params) => {
                    // @ts-ignore - factory.fireEvent is not included in typings
                    this._context.factory.fireEvent(eventName, params);
                }
            };
            ReactDOM.render(React.createElement(FormComponent, props), this._container);
        }
        else {
            const newValue = context.parameters.value.raw?.length === 1 ? context.parameters.value.raw[0] : null;

            if (!isEqual(newValue, this._value)) {
                this._value = newValue;
                this._quickForms = this._parseQuickForms(context.parameters.QuickForms.raw);

                // For nested form, we obtain ExtraQs from the URL
                const params = QueryString.parse(window.location.search.substring(1));
                const data: QueryData = params.data ? JSON.parse(params.data as string) : {};
                const extraQsParsed = data?.extraqs !== null ? QueryString.parse(data.extraqs, {
                    parseBooleans: true,
                    parseNumbers: true
                }) : null;

                ReactDOM.unmountComponentAtNode(this._container);
                if (this._value?.id) {
                    const props: IFormProps = {
                        mode: context.mode,
                        state: this._state,
                        disabled: this._context.mode.isControlDisabled,
                        entityId: this._value.id,
                        entityName: this._quickForms.entityName,
                        formId: this._quickForms.formId,
                        isNavbarLoaded: true,
                        enableRibbon: this._context.parameters.DisplayCommandbar?.raw == "true",
                        extraqs: extraQsParsed,
                        getTranslatedString: this._getTranslation.bind(this),
                        // @ts-ignore - factory.fireEvent is not included in typings
                        fireEvent: (eventName, params) => {
                            // @ts-ignore - factory.fireEvent is not included in typings
                            this._context.factory.fireEvent(eventName, params);
                        }
                    };
                    ReactDOM.render(React.createElement(FormComponent, props), this._container);
                }
                else {
                    ReactDOM.render(React.createElement("div", {
                        style: {
                            textAlign: 'center',
                            paddingTop: '20px',
                            paddingBottom: '20px'
                        }
                    }, React.createElement(Text, {}, this._getTranslation("selectRecord"))), this._container);
                }
            }
        }
    }

    private _getTranslation(string: string, variables: {} = {}): string {
        return Object.keys(variables).length > 0 ? this._liquid.parseAndRenderSync(this._context.resources.getString(string), variables) : this._context.resources.getString(string);
    }

    public getOutputs(): IOutputs {
        return {};
    }

    public destroy(): void {
        if (this._container) ReactDOM.unmountComponentAtNode(this._container);
    }
}