import React, { useState } from 'react';
import { Image } from '@fluentui/react';
import styles from './InstallPrompt.module.css';
import { AppModule } from '@src/app/classes/configuration/AppModule';
import { useInstallPrompt } from './useInstallPrompt';
//@ts-ignore - types not available for package
import PWAPrompt from 'react-ios-pwa-prompt';
import { sprintf } from 'sprintf-js';
import { InstallationDialog } from './InstallationDialog/InstallationDialog';
import { useInstalationDialog } from './useInstalationDialog';
import { SiteMap } from '@src/app/interfaces/sitemap';

interface IInstallPromptProps {
    imageSrc?: string;
    onUserSettingsSelected: (selectedSubAreas: SiteMap.SubArea[]) => Promise<void>;
}

enum ShortcutPlatformLimit {
    Android = 3,
    Desktop = 10
};

export const InstallPrompt: React.FC<IInstallPromptProps> = (props) => {
    const [visible, install, dismiss] = useInstallPrompt();
    const [installDialogVisible, subAreas, toggleDialog] = useInstalationDialog();
    const [iosPromptVisible, setIosPromptVisible] = useState<boolean>(false);
    const maxShortcutCount = (() => {
        if (navigator.userAgent.match(/Android/i)) {
            return ShortcutPlatformLimit.Android;
        }
        return ShortcutPlatformLimit.Desktop;
    })();

    const getLocalizedString = (key: string) => window.TALXIS.Portal.Translations.getLocalizedString(key);
    const installHandler = () => {
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        if (iOS) {
            setIosPromptVisible(true);
            return;
        }
        toggleDialog(!installDialogVisible);
    };
    return (
        <>
            {visible &&
                <>
                    <div className={styles.root}>
                        <div className={styles.descWrapper}>
                            <Image src={AppModule.get().icon.value} />
                            <span dangerouslySetInnerHTML={{
                                __html: sprintf(getLocalizedString('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/Text'), AppModule.get().name)
                            }}></span>
                        </div>
                        <div className={styles.btnWrapper}>
                            <button onClick={dismiss}>{getLocalizedString('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/NotNow')}</button>
                            <button onClick={installHandler}>{getLocalizedString('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/Install')}</button>
                        </div>
                    </div>
                    {installDialogVisible &&
                        <InstallationDialog
                            maxShortcutCount={maxShortcutCount}
                            width={600}
                            height={600}
                            hidden={false}
                            dialogContentProps={{
                                showCloseButton: true,
                                title: window.TALXIS.Portal.Translations.getLocalizedString('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Title')
                            }}
                            onDismiss={() => toggleDialog(false)}
                            onInstall={install}
                            onInjectUserSettingsToManifest={props.onUserSettingsSelected}
                            subAreas={subAreas} />
                    }
                </>
            }
            {iosPromptVisible &&
                <PWAPrompt
                    copyTitle={sprintf(getLocalizedString('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/iOSTitle'), AppModule.get().name)}
                    copyClosePrompt={getLocalizedString('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/iOSCancel')}
                    copyBody={sprintf(getLocalizedString('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/iOSDescription'), AppModule.get().name)}
                    copyShareButtonLabel={getLocalizedString('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/iOSStep1')}
                    copyAddHomeButtonLabel={getLocalizedString('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/iOSStep2')}
                    onClose={() => setIosPromptVisible(false)}
                    debug={true} />
            }
        </>
    );
};