import React, { useEffect, useContext, useState } from 'react';
import { AppModule } from '@configuration/AppModule';
import { AppContext } from '@providers/AppProvider';
import { SitemapDefinition } from '@definitions/SitemapDefinition';
import { IAppContext } from '@providers/AppProvider/interfaces';
import { IPageRoute } from '@models/SiteMap';
import { replaceLast } from '@app/Functions';
import { requireAuthentication } from '@app/classes/Authentication';
import { FieldControl } from '@controls/FieldControl';
import { FormControlType } from '@controls/native/Form/interfaces/enums';
import { useRef } from 'react';
import { ControlLoader, ControlRegistration } from '@loaders/ControlLoader';
import { RouteComponentProps } from 'react-router-dom';
import styles from './HomePage.module.css';
import { FormLoading } from '@src/components/loadings/FormLoading/FormLoading';
import { HistoryContext } from '@src/providers/HistoryProvider/HistoryProvider';
import { history } from '@src/providers/HistoryProvider/HistoryProvider';

export interface IHomeProps extends RouteComponentProps {

}

const Home: React.FC<IHomeProps> = (props) => {
    const appContext: IAppContext = useContext(AppContext);
    const historyContext = useContext(HistoryContext);
    const [route, setRoute] = useState<IPageRoute>();
    const [shouldReload, setShouldReload] = useState<boolean>(false);
    const controlDefinitionRef = useRef<ControlRegistration>(null);

    useEffect(() => {
        const routes = SitemapDefinition.getStaticPageRoutes();
        const currentAppModule = AppModule.get().uniquename;
        let currentRoutePath = window.location.pathname;
        if (currentRoutePath.startsWith(`/${currentAppModule}`)) {
            currentRoutePath = currentRoutePath.replace(`/${currentAppModule}`, "");
        }
        // Strip trailing slash
        if (currentRoutePath.endsWith("/") && currentRoutePath.length > 1) {
            currentRoutePath = replaceLast(currentRoutePath, "/", "");
        }
        const route = routes?.find(x => x.slug === currentRoutePath || x.slug === `${currentRoutePath}/`);
        if (!route) {
            history.replace('/');
            return;
        }
        (async () => {
            if (route.requiresAuthentication) {
                await requireAuthentication();
            }
            controlDefinitionRef.current = await ControlLoader.getAsync('TALXIS.PCF.WebPage');
            setRoute(route);
            setShouldReload(true);
            appContext.SetLoading(false);
        })();
    }, [historyContext.currentPage]);

    useEffect(() => {
        if (setShouldReload) {
            setShouldReload(false);
        }
    }, [shouldReload]);

    return (
        <div className={`TALXIS__portal__page ${styles.root}`}>
            {appContext.isNavbarLoaded && !shouldReload && route &&
                <>
                    {/*                     <div>Page ID: {route.id}</div>
                    <div>Slug: {route.slug}</div>
                    <div>Requires Authentication: {route.requiresAuthentication ? "true" : "false"}</div> */}
                    <FieldControl name="TALXIS.PCF.WebPage"
                        bindings={{
                            Id: {
                                isStatic: true,
                                value: route.id
                            }
                        }}
                        disableLoading
                        id=""
                        classId=""
                        datafieldname={null}
                        disabled={false}
                        type={FormControlType.Field}
                        visible={true}
                        isUnbound={true}
                        isRequired={false}
                        definition={controlDefinitionRef.current}

                    />
                </>
            }
            {!appContext.isNavbarLoaded &&
                <FormLoading />
            }
        </div>
    );
};
export default Home;