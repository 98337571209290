import { IAlertDialogProps } from '../../components/dialogs/AlertDialog/interfaces';
import { IErrorDialogProps } from '../../components/dialogs/ErrorDialog/interfaces';
import { IConfirmationDialogProps } from "../../components/dialogs/ConfirmationDialog/interfaces";
import { IFormDialogProps } from "../../components/dialogs/FormDialog/interfaces";
import { AppModule } from "@configuration/AppModule";
import { QueryData } from "@pages/Control/interfaces";
import { ErrorType, FormDefinition, FormType } from "@definitions/FormDefinition";
import * as QueryString from 'query-string';
import { EntityDefinition } from "@definitions/EntityDefinition";
import { history } from '@providers/HistoryProvider/HistoryProvider';
import { LocalizeLabel } from '@localization/helpers';
import { XrmFormContext } from '@src/components/controls/native/Form/Form';

interface IDialogOptions {
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
}

const dialogOptions: IDialogOptions = {};

export class Navigation implements ComponentFramework.Navigation {
    openDialog = (name: string, options: any, data: any): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            Xrm.Utility.showProgressIndicator("");
            const form = await FormDefinition.getAsync(null, null, name);
            const dialogProps: IFormDialogProps = {
                hidden: false,
                formName: name,
                formId: form.Id,
                formType: form.Type,
                width: options?.width,
                //the default height of '100%' should only apply to form dialogs,
                //unbound dialogs grow dynamically with content by default
                height: options?.height ?? 'initial',
                maxWidth: options?.width,
                position: options?.position,
                onCloseCallback: resolve,
            };
            if (data) {
                // serialize data to the extraqs format accepted by the form control
                // dialogProps.extraqs = `${Object.keys(data).map((key) => key + "=" + data[key]).join("&")}`;
                dialogProps.extraqs = QueryString.stringify(data, { skipNull: false, skipEmptyString: false });
            }
            Xrm.Utility.closeProgressIndicator();
            window.TALXIS.Portal.Context.openFormDialog(dialogProps);
        });
    };
    openAlertDialog = (alertStrings: Xrm.Navigation.AlertStrings | ComponentFramework.NavigationApi.AlertDialogStrings, options?: ComponentFramework.NavigationApi.AlertDialogOptions): Promise<void> => {
        if (!options)
            options = {};
        dialogOptions.height = options.height;
        dialogOptions.width = options.width;
        this._setDialogOptions(dialogOptions);

        return new Promise<void>((resolve, reject) => {
            const dialogProps: IAlertDialogProps = {
                hidden: false,
                width: dialogOptions.width,
                height: dialogOptions.height,
                minWidth: dialogOptions.minWidth,
                minHeight: dialogOptions.minHeight,
                modalProps: {
                    isBlocking: true
                },
                dialogContentProps: {
                    title: Object.keys(alertStrings).includes('title') ? (alertStrings as Xrm.Navigation.AlertStrings).title : '',
                    subText: alertStrings.text,
                    confirmButtonLabel: alertStrings.confirmButtonLabel
                },
                onDismiss: () => {
                    const hiddenDialogProps = { ...dialogProps };
                    hiddenDialogProps.hidden = true;
                    window.TALXIS.Portal.Context.setAlertDialogProps(hiddenDialogProps);
                    resolve();
                }
            };
            window.TALXIS.Portal.Context.setAlertDialogProps(dialogProps);
        });
    };
    openConfirmDialog = (confirmStrings: ComponentFramework.NavigationApi.ConfirmDialogStrings, options?: ComponentFramework.NavigationApi.ConfirmDialogOptions): Promise<ComponentFramework.NavigationApi.ConfirmDialogResponse> => {
        if (!options)
            options = {};
        dialogOptions.height = options.height;
        dialogOptions.width = options.width;
        this._setDialogOptions(dialogOptions);

        return new Promise((resolve) => {
            const dialogProps: IConfirmationDialogProps = {
                hidden: false,
                width: dialogOptions.width,
                height: dialogOptions.height,
                minWidth: dialogOptions.minWidth,
                minHeight: dialogOptions.minHeight,
                modalProps: {
                    isBlocking: true
                },
                dialogContentProps: {
                    title: confirmStrings.title,
                    subText: confirmStrings.subtitle,
                    confirmButtonLabel: confirmStrings.confirmButtonLabel,
                    cancelButtonLabel: confirmStrings.cancelButtonLabel,
                    text: confirmStrings.text
                },
                onDismiss: (result?: boolean) => {
                    const hiddenDialogProps = { ...dialogProps };
                    hiddenDialogProps.hidden = true;
                    window.TALXIS.Portal.Context.setConfirmationDialogProps(hiddenDialogProps);
                    let response: ComponentFramework.NavigationApi.ConfirmDialogResponse = {
                        confirmed: result
                    };
                    resolve(response);
                    return;
                },
                onConfirm: () => {
                    dialogProps.onDismiss(true);
                },
                onCancel: () => {
                    dialogProps.onDismiss(false);
                }
            };
            window.TALXIS.Portal.Context.setConfirmationDialogProps(dialogProps);
        });
    };
    openErrorDialog = (options: ComponentFramework.NavigationApi.ErrorDialogOptions): Promise<void> => {
        return new Promise((resolve) => {
            const dialogProps: IErrorDialogProps = {
                hidden: false,
                modalProps: {
                    isBlocking: true
                },
                dialogContentProps: {
                    title: window.TALXIS.Portal.Translations.getLocalizedString("@ComponentFramework/PropertyClasses/Navigation/openErrorDialog-title"),
                    subText: options.message,
                    moreDetailsText: options.details
                },
                onDismiss: () => {
                    const hiddenDialogProps = { ...dialogProps };
                    hiddenDialogProps.hidden = true;
                    window.TALXIS.Portal.Context.setErrorDialogProps(hiddenDialogProps);
                    resolve();
                }
            };
            if (!window.TALXIS.Portal.Context?.setErrorDialogProps) {
                alert(
                    window.TALXIS.Portal.Translations.getLocalizedString("@ComponentFramework/PropertyClasses/Navigation/openErrorDialog-title") + "\n" +
                    options.message + "\n\n" +
                    options.details
                );
                resolve();
                return;
            }
            window.TALXIS.Portal.Context.setErrorDialogProps(dialogProps);
        });
    };
    openFile = async (file: ComponentFramework.FileObject, options?: ComponentFramework.NavigationApi.OpenFileOptions): Promise<void> => {
        if (options.openMode === 1) {
            throw new Error("openFile with openMode = Open (1) is not implemeted!");
        }
        else if (options.openMode === 2) {
            var byteCharacters = window.atob(file.fileContent);
            var downloadedContentByteArray = new Array(file.fileSize ?? byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                downloadedContentByteArray[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(downloadedContentByteArray);

            const blob = new Blob([byteArray]);
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', file.fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    private _openMainForm = (formUrl: string, parameters?: { [key: string]: string | number; }) => {
        //replace the url if it has been intercepted or is the same as previous location, this prevents the need of clicking multiple times to get
        //to previous page in some cases
        if (parameters?.injectedUrl || formUrl === `${window.location.pathname}${decodeURI(window.location.search)}`) {
            history.replace(formUrl);
        }
        else {
            history.push(formUrl);
        }
    };

    openForm = (options: ComponentFramework.NavigationApi.EntityFormOptions, parameters?: { [key: string]: string | number; }): Promise<ComponentFramework.NavigationApi.OpenFormSuccessResponse> => {
        return new Promise(async (resolve, reject) => {
            const dataQuery: QueryData = {
                entityName: options.entityName,
                entityId: options.entityId,
                formId: options.formId
            };
            const openInDialog = parameters?.openInDialog?.toString() === "true";
            if (parameters) {
                dataQuery.extraqs = encodeURIComponent(QueryString.stringify(parameters, { skipNull: false, skipEmptyString: false }));
            }
            let formUrl = `/${AppModule.get().uniquename}/control/form?data=${JSON.stringify(dataQuery)}`;
            console.log(`Opening the following form: ${formUrl}`);
            if (options.useQuickCreateForm || openInDialog || parameters?.formType === 9) {
                Xrm.Utility.showProgressIndicator("");
                if (!options.formId) {
                    if (options.useQuickCreateForm || parameters?.formType === 9) {
                        // Try to fetch quick create form
                        try {
                            options.formId = (await FormDefinition.getDefaultQuickCreateAsync(options.entityName)).Id;
                        }
                        catch (error) {
                            if (error && typeof error === 'object' && 'code' in error && 'message' in error) {
                                if (error.code === ErrorType.MissingQuickCreateForm) {
                                    if (parameters?.formType === 9) {
                                        resolve(undefined);
                                        return;
                                    } else {
                                        this._openMainForm(formUrl, parameters);
                                        return;
                                    }
                                }
                            }
                            else if (error) {
                                throw Error(error as string);
                            }
                        }
                    }
                    // No quick create found or should openInDialog
                    if (!options.formId || openInDialog) {
                        // Try to fetch main form
                        try {
                            options.formId = (await FormDefinition.getEntityDefaultFormId(options.entityName));
                        } catch (error) {
                            if (error && typeof error === 'object' && 'message' in error) {
                                throw Error(error.message as string);
                            }
                            if (error) {
                                throw Error(error as string);
                            }
                            throw Error(`Error happend while fetching form for ${options.entityName} entity.`);
                        }
                    }
                }
                // Sets default dialog position 2 (far side)
                if (!options.windowPosition || parameters?.formType === 9) {
                    options.windowPosition = 2;
                }
                const form = await FormDefinition.getAsync(options.entityName, options.formId);
                const dialogInstanceId = window.self.crypto.randomUUID();
                const entityDefinition = await EntityDefinition.getAsync(options.entityName);

                //@ts-ignore - not part of official interface for openForm
                let customTitle = options.title ?? '';
                if (!customTitle) {
                    if (form.Type === FormType.QuickCreate) {
                        customTitle = `${window.TALXIS.Portal.Translations.getLocalizedString("@ComponentFramework/PropertyClasses/Navigation/openForm-title-create")}: `;
                    }
                    customTitle += `${LocalizeLabel(entityDefinition.DisplayName.LocalizedLabels)}`;
                }
                const dialogProps: IFormDialogProps = {
                    id: dialogInstanceId,
                    hidden: false,
                    customTitle: customTitle,
                    formName: form.Id,
                    formId: form.Id,
                    formType: form.Type,
                    width: options.width,
                    height: options.height,
                    maxWidth: options.width,
                    position: (options.windowPosition as 1 | 2),
                    onBeforeClose: async (formContext?: XrmFormContext) => {
                        if (!formContext || (formContext && formContext.data.getIsDirty())) {
                            const result = await this.openConfirmDialog({
                                text: window.TALXIS.Portal.Translations.getLocalizedString("@ComponentFramework/PropertyClasses/Navigation/discardUnsavedChanges-Text"),
                                cancelButtonLabel: window.TALXIS.Portal.Translations.getLocalizedString("@ComponentFramework/PropertyClasses/Navigation/discardUnsavedChanges-Cancel"),
                                confirmButtonLabel: window.TALXIS.Portal.Translations.getLocalizedString("@ComponentFramework/PropertyClasses/Navigation/discardUnsavedChanges-Confirm"),
                            });
                            return result.confirmed;
                        }
                        return true;
                    },
                    onCloseCallback: (result) => {
                        let savedEntityReference: ComponentFramework.LookupValue[] = null;
                        if (result) {
                            savedEntityReference = [{
                                id: result.parameters[entityDefinition.PrimaryIdAttribute],
                                entityType: entityDefinition.LogicalName,
                                name: result.parameters[entityDefinition.PrimaryNameAttribute],
                            }];
                        }
                        resolve({ savedEntityReference: savedEntityReference });
                    },
                    onCreateCallback: (result) => {
                        window.TALXIS.Portal.Context.closeFormDialog(null, null, dialogInstanceId);
                        resolve({
                            savedEntityReference: [result]
                        });
                    },
                    parameters: options,
                    isQuickCreate: options.useQuickCreateForm || parameters?.formType === 9,
                };
                if (parameters) {
                    // serialize data to the extraqs format accepted by the form control
                    dialogProps.extraqs = QueryString.stringify(parameters, { skipNull: false, skipEmptyString: false });
                }
                Xrm.Utility.closeProgressIndicator();
                window.TALXIS.Portal.Context.openFormDialog(dialogProps);
            }
            else if (options.openInNewWindow) {
                window.open(formUrl);
            } else {
                this._openMainForm(formUrl, parameters);
            }
        });
    };
    openUrl = (url: string, options?: ComponentFramework.NavigationApi.OpenUrlOptions): void => {
        throw new Error("Method not implemented.");
    };
    openWebResource = (name: string, options?: ComponentFramework.NavigationApi.OpenWebResourceOptions, data?: string): void => {
        throw new Error("Method not implemented.");
    };
    navigateTo = (
        pageInput:
            | Xrm.Navigation.PageInputEntityRecord
            | Xrm.Navigation.PageInputEntityList
            | Xrm.Navigation.CustomPage
            | Xrm.Navigation.PageInputHtmlWebResource
            | Xrm.Navigation.Dashboard,
        navigationOptions?: Xrm.Navigation.NavigationOptions,
    ): Promise<any> => {
        return window.TALXIS.Portal.Navigation.navigateTo(pageInput, navigationOptions);
    };

    private _setDialogOptions(options: IDialogOptions) {
        if (options.width) {
            options.minWidth = 0;
        }
        else {
            options.minWidth = undefined;
        }
        if (options.height) {
            options.minHeight = 0;
        }
        else {
            options.minHeight = undefined;
        }
    }
}