import { metadataRetrieveMultiple } from "./MetadataApi";
import { Website } from "@models/Website";
export class WebsiteDefinition {
    private static _website: Website | null = null;
    public static getWebsite(): Website | null {
        if (!this._website) {
            return null;
        }
        return this._website;
    }
    public static async loadWebsite() {
        try {
            let websiteResponse = await metadataRetrieveMultiple(`v9.1/talxis_websites?$select=talxis_allowedappmodules,_talxis_themeid_value&$filter=talxis_hostname eq '${window.location.host}'&$expand=talxis_talxis_website_talxis_appmodulewebsiteconnection_websiteid($select=talxis_appmodulewebsiteconnectionid,_talxis_themeid_value;$expand=talxis_appmoduleid($select=talxis_uniquename))`);
            if (!(websiteResponse?.entities?.length > 0)) {
                return;
            }
            const websiteRecord = websiteResponse.entities[0];
            this._website = new Website(websiteRecord);
        }
        catch (error) {
            // If website doesn't exist, portal shouldn't fail on it.
        }
    }
}
