import React, { useEffect, useState } from 'react';
import { IItemColumnProps } from './interfaces';
import { ICommandBarProps, Link, ICommandBarItemProps, mergeStyles, useTheme } from '@fluentui/react';
import Loading from '../../../../../loadings/Loading';
import { Text } from '@fluentui/react/lib/Text';
import { CommandBarWrapper } from './components/CommandBarWrapper';
import { QueryData } from '@pages/Control/interfaces';
import { AppModule } from '@configuration/AppModule';
import { ribbonButtonsColumnName } from '@app/Constants';
import { DataType } from '@ComponentFramework/interfaces/DataType';
import 'external-svg-loader';

enum FieldType {
    Text = 'Text',
    Lookup = 'Lookup',
    Primary = 'Primary',
}

const getFieldType = (entityName: string, entityId: string, primaryName: string, fieldName: string): FieldType => {
    if (entityName && entityId) {
        return FieldType.Lookup;
    }
    else if (primaryName === fieldName) {
        return FieldType.Primary;
    }
    return FieldType.Text;
};

export const ItemColumn: React.FC<IItemColumnProps> = (props) => {
    const getFormattedValue = () => {
        const result = props.item.record.getFormattedValue(props.column.fieldName);
        if (typeof result === 'string') {
            return result;
        }
        return undefined;
    };
    const isRibbonButtonsColumn = props.column.key === ribbonButtonsColumnName;
    const [ribbonCommandBarProps, setRibbonCommandBarProps] = useState<ICommandBarProps>(null);
    const value = props.item.record.getValue(props.column.fieldName);
    const formattedValue = getFormattedValue();
    const lookupEntityName = (value as ComponentFramework.LookupValue)?.entityType;
    const lookupEntityId = (value as ComponentFramework.LookupValue)?.id;
    const fieldType = getFieldType(lookupEntityName, lookupEntityId, props.primaryName, props.column.fieldName);
    const theme = useTheme();

    const isNumberColumn = (): boolean => {
        switch (props.column.dataType) {
            case DataType.Currency:
            case DataType.WholeNone:
            case DataType.Decimal:
                return true;
        }
        return false;
    };

    const isDateColumn = (): boolean => {
        switch (props.column.dataType) {
            case DataType.DateAndTimeDateAndTime:
            case DataType.DateAndTimeDateOnly:
                return true;
        }
        return false;
    };

    const getLinkURL = () => {
        let query: QueryData = {};
        if (fieldType === FieldType.Lookup) {
            query.entityName = lookupEntityName;
            query.entityId = lookupEntityId;
        }
        else {
            query.entityName = props.item.record.getNamedReference().etn;
            query.entityId = props.item.record.getRecordId();
        }
        return `/${AppModule.get().uniquename}/control/form?data=${JSON.stringify(query)}`;

    };

    const onRenderIcon = (button: Ribbon.Command) => {
        if (!button.icon) {
            return undefined;
        }
        return () => <svg
            className={`TALXIS__ribbon__icon__svg ${mergeStyles({
                width: 16,
                height: 16,
                marginLeft: 4,
                marginRight: 4,
                'path': {
                    fill: theme.palette.themePrimary
                }
            })}`}
            data-src={button.icon}
        />;
    };

    useEffect(() => {
        const initAsync = async () => {
            if (!props.dataset) {
                setRibbonCommandBarProps({
                    className: 'TALXIS__ribbon Ribbon_root',
                    items: []
                });
                return;
            }
            //@ts-ignore - not part of types
            const commands: Ribbon.Command[] = await props.dataset.retrieveRecordCommand([props.item.record.getRecordId()]);
            const items: ICommandBarItemProps[] = [];
            for (const command of commands) {
                if (!command.__isInline || !command.shouldBeVisible) {
                    continue;
                }
                items.push({
                    key: command.commandButtonId,
                    text: command.label,
                    ["data-id"]: command.commandButtonId,
                    ["data-command"]: command.commandId,
                    onClick: (e) => {
                        e.stopPropagation();
                        command.execute();
                    },
                    onRenderIcon: onRenderIcon(command),
                    iconProps: command.icon ? {
                        imageProps: {
                            src: command.icon
                        }
                    } : undefined
                });
            }
            setRibbonCommandBarProps({
                className: 'TALXIS__ribbon Ribbon_root',
                items: items
            });

        };

        if (isRibbonButtonsColumn) {
            initAsync();
        }
    }, [props.item]);

    if (isRibbonButtonsColumn) {
        if (!ribbonCommandBarProps) {
            return <Loading />;
        };
        return (
            <>
                {ribbonCommandBarProps &&
                    <CommandBarWrapper
                        {...ribbonCommandBarProps}
                        items={[]}
                        farItems={ribbonCommandBarProps.items}
                        className={`${ribbonCommandBarProps.className} TALXIS__command-bar--view-item`} />
                }
            </>
        );
    }
    //case of lookup or primary name field => render link
    else if ((fieldType === FieldType.Lookup || fieldType === FieldType.Primary) && props.onOpenDatasetItem) {
        return (
            <Link
                title={formattedValue}
                href={getLinkURL()}
                style={{
                    fontSize: 14
                }}
                onClick={(e) => {
                    e.preventDefault();
                    if (fieldType === FieldType.Lookup) {
                        props.onOpenDatasetItem({
                            etn: lookupEntityName,
                            name: '',
                            id: {
                                guid: lookupEntityId
                            }
                        });
                    }
                    else {
                        props.onOpenDatasetItem({
                            etn: props.item.record.getNamedReference().etn,
                            name: props.item.record.getNamedReference().name,
                            id: {
                                guid: props.item.record.getRecordId()
                            }
                        });
                    }
                }}>
                {formattedValue}
            </Link>
        );
    }
    else {
        //Props are unaware of data-* attributes
        const additionalProps: any = {};
        if (isNumberColumn()) {
            additionalProps['data-type-number'] = "true";
            if (Number(formattedValue) > 0) {
                additionalProps['data-gt-zero'] = "true";
            }
        }
        else if (isDateColumn()) {
            if (new Date(Date.parse(formattedValue)) < new Date()) {
                additionalProps['data-lt-today'] = "true";
            }
            else {
                const date = new Date();
                date.setDate(date.getDate() + 60);
                if (new Date(Date.parse(formattedValue)) < date) {
                    additionalProps['data-lt-twomonths'] = "true";
                }
            }
        }
        return (
            <Text {...additionalProps} title={formattedValue}>{formattedValue}</Text>
        );
    }
};