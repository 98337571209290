import { Icon, IContextualMenuItem, SearchBox } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useDebounce } from '@app/hooks/useDebounce';
import { IEntityMenuProps } from '../../interfaces';

const getMenuItemFromEntityRecord = (entityRecord: { logicalname: string }): IContextualMenuItem => {
    return {
        key: 'entity-' + entityRecord.logicalname,
        text: entityRecord.logicalname,
        subMenuProps: {
            items: [
                {
                    key: 'entity-' + entityRecord.logicalname + '-definition',
                    text: 'Definition'
                }
            ]
        }
    };
};

const getEntityMenuItems = async (searchTerm?: string): Promise<IContextualMenuItem[]> => {
    const searchQueryOption = searchTerm ? `&$filter=contains(logicalname, '${searchTerm}')` : '';
    let response = await Xrm.WebApi.retrieveMultipleRecords('entity', '?$select=logicalname&$top=10' + searchQueryOption);
    return response?.entities?.map(entityRecord => getMenuItemFromEntityRecord(entityRecord));
};

const filteredItemsStyle: React.CSSProperties = {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const noEntityFoundMenuItem = (<div key="no_results" style={filteredItemsStyle}>
    <Icon iconName="SearchIssue" title="No entities found" />
    <span> No entities found</span>
</div>);

const EntityMenu: React.FC<IEntityMenuProps> = (props) => {
    const [items, setItems] = React.useState<IContextualMenuItem[]>([{
        key: 'placeholder', text: 'Loading 1...',
        subMenuProps: {
            items: [
                {
                    key: 'PlaceholderItemEntitiesContent',
                    text: 'Loading 2...'
                }
            ]
        }
    }]);
    const [searchTermValue, setSearchTermValue] = useState<string>('');
    const debouncedValue = useDebounce<string>(searchTermValue, 500);

    const initAsync = async () => {
        //setItems(await getEntityMenuItems());
    };

    useEffect(() => {
        initAsync();
    }, []);

    useEffect(() => {
        const getItems = async () => {
            const filteredItems = await getEntityMenuItems(searchTermValue);

            if (!filteredItems || !filteredItems.length) {
                filteredItems.push({
                    key: 'no_results',
                    onRender: (item: any, dismissMenu: any) => (
                        noEntityFoundMenuItem
                    ),
                });
            }
            setItems(filteredItems);
        };

        getItems();
    }, [debouncedValue]);

    const onChange = React.useCallback(async (ev: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        setSearchTermValue(newValue);
    }, []);

    /* const menuProps = React.useMemo(
        () => {
            props.menuListProps.items = items;
            return props.menuListProps;
        },
        [items],
    ); */

    return (
        <div>
            <div style={{ borderBottom: '1px solid #ccc' }}>
                <SearchBox
                    placeholder="Filter entities"
                    onChange={onChange}
                    styles={{
                        root: { margin: '8px' },
                    }}
                />
            </div>
            {/* {props.defaultRender({
                items: [{
                    key: 'PlaceholderItemEntitiesA',
                    text: 'A',
                    subMenuProps: {
                        items: [
                            {
                                key: 'PlaceholderItemEntitiesContentB',
                                text: 'B'
                            }
                        ]
                    }
                }],
                defaultMenuItemRenderer: props.menuListProps.defaultMenuItemRenderer,
                totalItemCount: items.length,
                hasCheckmarks: props.menuListProps.hasCheckmarks,
                hasIcons: props.menuListProps.hasIcons,
            })} */}
            {props.defaultRender({
                ...props.menuListProps,
                items: [...props.menuListProps.items, ...[{
                    key: 'PlaceholderItemEntitiesA',
                    text: 'A',
                    subMenuProps: {
                        items: [
                            {
                                key: 'PlaceholderItemEntitiesContentB',
                                text: 'B'
                            }
                        ]
                    }
                }]
                ],
            })}
        </div>
    );
};
export default EntityMenu;