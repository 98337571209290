import { IOptionSet } from "@talxis/base-controls";
import { OptionSet as OptionSetComponent } from "@talxis/base-controls";
import React from 'react';
import ReactDOM from "react-dom";

interface IInputs {
    value: ComponentFramework.PropertyTypes.OptionSetProperty;
    deleteButton?: ComponentFramework.PropertyTypes.StringProperty;
    EnableOptionSetColors: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
}
interface IOutputs {
    value?: number;
}

export class OptionSet implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _outputs: IOutputs = {};

    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = container;
    }
    public updateView(context: ComponentFramework.Context<IInputs>): void {
        ReactDOM.render(React.createElement(OptionSetComponent, {
            context: context,
            parameters: {
                value: context.parameters.value,
                EnableDeleteButton: {
                    raw: context.parameters.deleteButton?.raw !== 'false'
                },
                EnableOptionSetColors: {
                    raw: context.parameters.EnableOptionSetColors?.raw === 'true'
                }

            },
            onNotifyOutputChanged: (outputs) => {
                this._outputs = outputs;
                this._notifyOutputChanged();
            }
        } as IOptionSet), this._container);
    }

    public getOutputs(): IOutputs {
        return this._outputs;
    }

    public destroy(): void {
        ReactDOM.unmountComponentAtNode(this._container);
    }
}