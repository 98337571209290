import { DateFormattingInfo } from "@src/ComponentFramework/PropertyClasses/Formatting/DateFormattingInfo";
import { NumberFormattingInfo } from "@src/ComponentFramework/PropertyClasses/Formatting/NumberFormattingInfo";
import { DEFAULT_LANGUAGE } from "@src/localization/helpers";
import lcid from "lcid";
import { NUMBER_OF_RECORDS_PER_VIEW_PAGE } from "../definitions/UserSettingsDefinition";

interface IAccessPrincipalDetails {
    id: string;
    username?: string;
    languageId?: number;
    pagingLimit?: number;
    settingsId?: string
}

const DEFAULT_RECORDS_PER_VIEW_PAGE = 100;

export class UserSettings implements ComponentFramework.UserSettings {
    public readonly dateFormattingInfo: DateFormattingInfo;
    public readonly locale: string;
    public readonly isRTL: boolean;
    public readonly languageId: number;
    public readonly numberFormattingInfo: NumberFormattingInfo;
    public readonly securityRoles: string[];
    public readonly userId: string;
    public readonly userName: string;
    public readonly formatInfoCultureName: string;
    private _pagingLimit: number;
    private _accessPrincipalSettingsId?: string;

    constructor(accessPrincipalDetails?: IAccessPrincipalDetails) {
        this.userId = accessPrincipalDetails?.id ?? null;
        this.userName = accessPrincipalDetails?.username ?? null;
        this._pagingLimit = accessPrincipalDetails?.pagingLimit ?? DEFAULT_RECORDS_PER_VIEW_PAGE;
        this._accessPrincipalSettingsId = accessPrincipalDetails?.settingsId;
        this.locale = this._getLocale(accessPrincipalDetails?.languageId);
        this.formatInfoCultureName = this.locale;
        this.languageId = this._getLanguageId(accessPrincipalDetails?.languageId);
        this.numberFormattingInfo = new NumberFormattingInfo(this.locale);
        this.dateFormattingInfo = new DateFormattingInfo(this.locale);
    }

    public getTimeZoneOffsetMinutes(date?: Date): number {
        throw new Error("Method not implemented.");
    }
    /**
     * Returns local browser's timezone offset in miliseconds
     */
    public getLocalTimeZoneOffset(): number {
        return (new Date()).getTimezoneOffset() * 60000;
    }

    public async setPagingLimit(pageSize: number) {
        const optionSetValue = NUMBER_OF_RECORDS_PER_VIEW_PAGE.find(x => x.value == pageSize)?.id;
        if (!optionSetValue || !this._accessPrincipalSettingsId) {
            return;
        }
        this._pagingLimit = pageSize;
        return window.Xrm.WebApi.updateRecord('talxis_accessprincipalsettings', this._accessPrincipalSettingsId, {
            talxis_numberofrecordsperviewpagetypecode: optionSetValue
        });
    }

    public get pagingLimit() {
        return this._pagingLimit;
    }
    /**
     * Returns current user's timezone offset in miliseconds from talxis_accessprincipalsettings in future
     */
    public getConfiguredUserTimeZoneOffset(): number {
        return (new Date()).getTimezoneOffset() * 60000;
    }
    private _getLanguageId(languageId?: number) {
        return languageId ?? lcid.to(this._getLocale().replace('-', '_'));
    }
    private _getLocale(languageId?: number) {
        if (languageId) {
            return lcid.from(languageId).replace('_', '-');
        }
        const browserLocale = navigator.languages[0];
        const browserLocaleLCID = lcid.to(browserLocale.replace('-', '_'));
        //if the browser LCID has portal translations, use it
        if (window.TALXIS.Portal.Translations._builtinTranslations?.[browserLocaleLCID]) {
            return browserLocale;
        }
        return lcid.from(DEFAULT_LANGUAGE).replace('_', '-');
    }
}