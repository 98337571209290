import { ContextualMenu, DirectionalHint, IconButton, mergeStyles, MessageBar, MessageBarButton, TooltipHost } from "@fluentui/react";
import React, { useState } from "react";
import GlobalNotification from "../GlobalNotification";
import { IGlobalNotification } from "../GlobalNotification/interfaces/IGlobalNotification";
import { sprintf } from "sprintf-js";
import { useEffect } from "react";

interface INotificationsProps {
    notifications: IGlobalNotification[];
    collapseCount: number;
    isGlobal?: boolean;
}

export const Notifications: React.FC<INotificationsProps> = (props) => {
    const ref = React.useRef<HTMLDivElement>();
    const [calloutWidth, setCalloutWidth] = useState<number>();
    const [notificationsExpanded, expandNotifications] = useState<boolean>(false);

    useEffect(() => {
        setCalloutWidth(ref.current?.offsetWidth);
    }, [notificationsExpanded]);

    if (props.notifications.length < (props.collapseCount)) {
        return (
            <>
                {props.notifications.map((notification, index) =>
                    <GlobalNotification key={index} notification={notification} index={index} />)}
            </>
        );
    }
    else {
        const notificationText = sprintf(window.TALXIS.Portal.Translations.getLocalizedString(`@pages/Layout/components/Notifications/NewNotifications`), props.notifications.length);
        return <>
            <MessageBar isMultiline={false} ref={ref} actions={
                <MessageBarButton onClick={() => expandNotifications(!notificationsExpanded)}>
                    {window.TALXIS.Portal.Translations.getLocalizedString(`@pages/Layout/components/Notifications/${notificationsExpanded ? 'Hide' : 'Show'}`)}
                </MessageBarButton>
            } className='TALXIS__global-notification'>
                <TooltipHost content={<div dangerouslySetInnerHTML={{ __html: notificationText }}></div>}><div dangerouslySetInnerHTML={{ __html: notificationText }}></div></TooltipHost>
            </MessageBar>
            <ContextualMenu
                useTargetWidth
                isBeakVisible={false}
                items={props.notifications.map((notification) => {
                    return {
                        key: notification.id
                    };
                })}
                calloutProps={{
                    styles: {
                        root: {
                            width: calloutWidth,
                            left: props.isGlobal && '0px !important',
                            ' .ms-MessageBar .ms-MessageBar-content': {
                                justifyContent: !props.isGlobal ? 'flex-start' : undefined
                            }
                        }
                    }
                }}
                onRenderMenuList={() => <>
                    {props.notifications.map((notification, index) =>
                        <GlobalNotification key={index} notification={notification} index={index} />)}
                </>}
                directionalHint={DirectionalHint.bottomLeftEdge}
                directionalHintFixed
                hidden={!notificationsExpanded}
                target={ref}
                onDismiss={() => expandNotifications(false)}
            />
        </>;
    }
};