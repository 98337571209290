import { mergeStyles } from "@fluentui/react";
import { NavigationType } from "@configuration/SpaConfiguration";

export const getFooterStyles = (pageNavigationVisible: boolean, navigationType: NavigationType): string => {
    return mergeStyles({
        textAlign: 'right',
        padding: 15,
        paddingTop: 0,
        'span': {
            fontWeight: 600,
            color: 'grey',
        }
    });
};