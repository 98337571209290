import { HistoryManager } from "@src/providers/HistoryProvider/HistoryManager";
import { State } from "@src/providers/HistoryProvider/State";
import { useRef, useEffect } from "react";
import { useStateValues } from '@talxis/react-components';

export const useControlState = (controlId: string, cellId?: string): React.MutableRefObject<State> => {
    const pageState = HistoryManager.getCurrentPage().state;
    const controlStateRef = useRef<State>((() => {
        let id = controlId;
        if (cellId) {
            id = `${cellId}_${controlId}`;
        }
        let controlState = pageState.get(id);
        if (!controlState) {
            controlState = new State(id);
            pageState.addChildState(controlState);
        }
        return controlState;
    })());
    return controlStateRef;
};

export const useControlledNativeStateValues = <T>(controlNativeStateRef: React.MutableRefObject<State>, defaultValues?: T): [
    React.MutableRefObject<T>,
    (defaultValues: T) => void
] => {
    const [nativeStateValuesRef, getChangedStateValues, setDefaultStateValues] = useStateValues<T>(controlNativeStateRef.current.getValues<T>('native'), defaultValues);

    const setNativeStateValues = () => {
        const changedStateValues = getChangedStateValues();
        controlNativeStateRef.current.setValues('native', changedStateValues);
    };
    useEffect(() => {
        //this guarantees that the setstate gets called on browser reload
        controlNativeStateRef.current.setSaveStateValuesChangesCallback(setNativeStateValues);
        return () => {
            setNativeStateValues();
        };
    }, []);

    return [nativeStateValuesRef, setDefaultStateValues];
};