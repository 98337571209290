import { Grid } from '../Grid';
import { useState, useMemo, useEffect } from 'react';
import { QueryBuilder } from './QueryBuilder';
import { ICommandBarProps } from '@fluentui/react';

export const useQueryBuilder = (grid: Grid, showUserFilters: boolean): [
    boolean,
    (container: HTMLDivElement) => void,
    () => void,
    boolean,
    ICommandBarProps,
] => {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const queryBuilder = useMemo(() => { return new QueryBuilder(grid, () => refresh(), showUserFilters); }, []);
    const [commandBarProps, setCommandBarProps] = useState<ICommandBarProps>(null);
    const getCommandBarProps = (): ICommandBarProps => {
        return {
            items: [
                {
                    key: 'reset',
                    text: 'Reset to default',
                    iconProps: {
                        iconName: 'Filter'
                    },
                    onClick: () => queryBuilder.reset()
                },
                {
                    key: 'download',
                    text: 'Download FetchXML',
                    iconProps: {
                        iconName: 'Download'
                    },
                    onClick: () => queryBuilder.download()
                }
            ]
        };
    };

    useEffect(() => {
        return () => {
            queryBuilder.destroy();
        };
    }, []);
    const init = async (container: HTMLDivElement) => {
        await queryBuilder.init(container);
        setCommandBarProps(getCommandBarProps());
        setIsInitialized(true);
    };
    const refresh = () => {
        setIsDirty(queryBuilder.isDirty());
    };
    return [isInitialized, (container) => init(container), () => queryBuilder.save(), isDirty, commandBarProps];
};
