import React from 'react';
import ReactDOM from "react-dom";
import { ITwoOptions } from "@talxis/base-controls";
import { TwoOptions as TwoOptionsComponent } from "@talxis/base-controls";

interface IInputs {
    value: ComponentFramework.PropertyTypes.TwoOptionsProperty;
}
interface IOutputs {
    value?: boolean;
}

export class TwoOptions implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _outputs: IOutputs = {};

    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = container;
    }
    public updateView(context: ComponentFramework.Context<IInputs>): void {
        ReactDOM.render(React.createElement(TwoOptionsComponent, {
            context: context,
            parameters: {
                value: context.parameters.value,
            },
            onNotifyOutputChanged: (outputs) => {
                this._outputs = outputs;
                this._notifyOutputChanged();
            }
        } as ITwoOptions), this._container);
    }

    public getOutputs(): IOutputs {
        return this._outputs;
    }

    public destroy(): void {
        ReactDOM.unmountComponentAtNode(this._container);
    }
}