import { TransactionCurrencyDefinition } from "@src/app/classes/definitions/TransactionCurrencyDefinition";

export class NumberFormattingInfo implements ComponentFramework.UserSettingApi.NumberFormattingInfo {
    public readonly currencyDecimalDigits: number = 2;
    public readonly currencyDecimalSeparator: string;
    public readonly currencyGroupSeparator: string;
    //static, not needed
    public readonly currencyGroupSizes: number[] = [];
    public readonly currencyNegativePattern: number;
    public readonly currencyPositivePattern: number;
    public readonly currencySymbol: string;
    //static, not needed
    public readonly nanSymbol: string = 'NaN';
    //static, not needed
    public readonly nativeDigits: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    //static, not needed
    public readonly negativeInfinitySymbol: string = '-∞';
    public readonly negativeSign: string;
    //static, should come from access principal settings in the future
    public readonly numberDecimalDigits: number = 2;
    public readonly numberDecimalSeparator: string;
    public readonly numberGroupSeparator: string;
    //static, not needed
    public readonly numberGroupSizes: number[] = [];
    public readonly numberNegativePattern: number;
    //static, not needed
    public readonly perMilleSymbol: string = '‰';
    //static, should come from access principal settings in the future
    public readonly percentDecimalDigits: number = 2;
    public readonly percentDecimalSeparator: string;
    public readonly percentGroupSeparator: string;
    public readonly percentGroupSizes: number[] = [];
    public readonly percentNegativePattern: number = 1;
    public readonly percentPositivePattern: number = 1;
    public readonly percentSymbol: string;
    public readonly positiveInfinitySymbol: string = '∞';
    public readonly positiveSign: string;
    private _locale: string;

    constructor(locale: string) {
        this._locale = locale;
        const formatting = new Intl.NumberFormat(locale, {
            signDisplay: 'always',
            style: 'decimal'
        });
        const formattingParts = new Map(formatting.formatToParts(NUMBER_EXAMPLE).map(part => [part.type, part.value]));
        this.numberGroupSeparator = formattingParts.get('group').replace(String.fromCharCode(160), ' ');;
        this.currencyGroupSeparator = formattingParts.get('group').replace(String.fromCharCode(160), ' ');;
        this.percentGroupSeparator = formattingParts.get('group').replace(String.fromCharCode(160), ' ');;
        this.numberDecimalSeparator = formattingParts.get('decimal').replace(String.fromCharCode(160), ' ');;
        this.currencyDecimalSeparator = formattingParts.get('decimal').replace(String.fromCharCode(160), ' ');;
        this.percentDecimalSeparator = formattingParts.get('decimal').replace(String.fromCharCode(160), ' ');;
        this.positiveSign = formattingParts.get('plusSign');
        this.negativeSign = formatting.formatToParts(NUMBER_EXAMPLE * -1).find(part => part.type === 'minusSign').value;
        this.percentSymbol = new Intl.NumberFormat(locale, { style: 'percent' }).formatToParts(NUMBER_EXAMPLE).find(part => part.type === 'percentSign').value;
        [this.numberNegativePattern] = this._getPatterns('decimal');
        [this.currencyNegativePattern, this.currencyPositivePattern] = this._getPatterns('currency');
        [this.percentNegativePattern, this.percentPositivePattern] = this._getPatterns('percent');
        //TODO: should be part of access principal settings
        this.currencySymbol = this._locale === 'cs-CZ' ? 'Kč' : '$';
    }

    private _getPatterns(type: 'percent' | 'currency' | 'decimal') {
        const getPattern = (value: number) => {
            return new Intl.NumberFormat(this._locale, {
                style: type,
                maximumFractionDigits: 0,
                currencyDisplay: 'narrowSymbol',
                currency: 'USD'
            }).format(value).replace('1', 'n').replace(String.fromCharCode(160), ' ');
        };
        const positivePattern = getPattern(type === 'percent' ? 0.01 : 1);
        const negativePattern = getPattern(type === 'percent' ? -0.01 : -1);
        const [negativePatternMap, positivePatternMap] = this._getPatternMaps(type);
        const positivePatternKey = Object.keys(positivePatternMap).find((key: any) => positivePatternMap[key] === positivePattern);
        const negativePatternKey = Object.keys(negativePatternMap).find((key: any) => negativePatternMap[key] === negativePattern);
        return [parseInt(negativePatternKey), parseInt(positivePatternKey)];
    }
    private _getPatternMaps(type: 'percent' | 'currency' | 'decimal') {
        switch (type) {
            case 'decimal': {
                return [NUMBER_NEGATIVE_PATTERN, {}];
            }
            case 'currency': {
                return [CURRENCY_NEGATIVE_PATTERN, CURRENCY_POSITIVE_PATTERN];
            }
            case 'percent': {
                return [PERCENT_NEGATIVE_PATTERN, PERCENT_POSITIVE_PATTERN];
            }
        }
    }
}

export const CURRENCY_POSITIVE_PATTERN: { [key: number]: string } = {
    0: '$n',
    1: 'n$',
    2: '$ n',
    3: 'n $',
};
export const CURRENCY_NEGATIVE_PATTERN: { [key: number]: string } = {
    0: '($n)',
    1: '-$n',
    2: '$-n',
    3: '$n-',
    4: '(n$)',
    5: '-n$',
    6: 'n-$',
    7: 'n$-',
    8: '-n $',
    9: '-$ n',
    10: 'n $-',
    11: '$ n-',
    12: '$ -n',
    13: 'n- $',
    14: '($ n)',
    15: '(n $)',
    16: '$- n'
};
export const NUMBER_NEGATIVE_PATTERN: { [key: number]: string } = {
    0: '(n)',
    1: '-n',
    2: '- n',
    3: 'n-',
    4: 'n -'
};
export const PERCENT_POSITIVE_PATTERN: { [key: number]: string } = {
    0: 'n %',
    1: 'n%',
    2: '%n',
    3: '% n'
};
export const PERCENT_NEGATIVE_PATTERN: { [key: number]: string } = {
    0: '-n %',
    1: '-n%',
    2: '-%n',
    3: '%-n',
    4: '%n-',
    5: 'n-%',
    6: 'n%-',
    7: '-% n',
    8: 'n %-',
    9: '% n-',
    10: '% -n',
    11: 'n- %'
};

const NUMBER_EXAMPLE = 100000.5;