import Cookies from 'js-cookie';

export class RingProvider {
    public static set(ring: string): void {
        Cookies.set("TALXIS-Portal-Ring", ring);
    }
    public static get(): string | null {
        const cookie = Cookies.get("TALXIS-Portal-Ring") ?? null;

        return cookie;
    }
    public static clear(): void {
        Cookies.remove("TALXIS-Portal-Ring");
    }
}