import { Grid } from "../Grid";
import { EntityDefinition } from "@src/app/classes/definitions/EntityDefinition";
import { LocalizeLabel } from "@src/localization/helpers";

export class QuickFind {
    private _grid: Grid;
    private _refreshCallback: () => void;
    private _columnDisplayNames: string[];
    constructor(grid: Grid) {
        this._grid = grid;
    }
    public get placeholder() {
        return this._grid.bindings?.SearchPlaceholder?.value ?? window.TALXIS.Portal.Translations.getLocalizedString('@pages/Control/View/index.tsx');
    }
    public init(refreshCallback: () => void) {
        this._refreshCallback = refreshCallback;
        this.refresh();
    }
    public get searchValue() {
        return this._grid?.quickFindSearchValue;
    }
    public set searchValue(value: string) {
        this._grid.quickFindSearchValue = value;
        this.refresh();
        this._grid.refresh();
    }
    public refresh() {
        this._refreshCallback();
    }
    public isVisible(): boolean {
        if (this._grid.isFullyVirtual || this._grid.isHybridVirtual) {
            return false;
        }
        return this._grid.cdsOptions.displayQuickFind;
    }
    public async getColumnDisplayNames(): Promise<string[]> {
        if (!this._columnDisplayNames) {
            const columnNames = this._grid.quickFindColumnNames;
            const definition = await EntityDefinition.getAsync(this._grid.entityName);
            this._columnDisplayNames = definition.Attributes
                .filter(attribute => columnNames.includes(attribute.LogicalName))
                .map(attribute => LocalizeLabel(attribute.DisplayName.LocalizedLabels));

        }
        return this._columnDisplayNames;
    }
}