import { SiteMapMapper } from '@mappers/SiteMapMapper';
import { AppComponents } from '@configuration/AppComponents';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';
import { sendMetadataGetRequest } from './MetadataApi';
import { IPageRoute, SiteMap, SiteMarker } from '@models/SiteMap';

export class SitemapDefinition {
    private static _sitemapDefinitionCache: IPromiseCache<SiteMap> = {};
    private static _currentSitemap: SiteMap;
    public static async getAsync(sitemapId: string): Promise<SiteMap> {
        return cachedWrapper(sitemapId, () => this._getSitemapDefinitionAsync(sitemapId), this._sitemapDefinitionCache);
    }
    public static getCurrentSiteMap(): SiteMap {
        if (!this._currentSitemap) {
            throw new Error('Current sitemap is undefined, have you called loadCurrentSitemapAsync?');
        }
        return this._currentSitemap;
    }
    public static async loadCurrentSitemapAsync(): Promise<void> {
        const siteMapId = AppComponents.get().filter(appComponent => appComponent.componenttype == 62)[0].objectid;
        this._currentSitemap = await this.getAsync(siteMapId);
    }

    public static getWellKnownPage(marker: SiteMarker): string {
        return this._currentSitemap.getWellKnownPage(marker);
    }

    public static getStaticPageRoutes(): IPageRoute[] {
        return this._currentSitemap.getStaticPageRoutes();
    }

    private static async _getSitemapDefinitionAsync(siteMapId: string): Promise<SiteMap> {
        const response = await sendMetadataGetRequest(`v9.1/sitemaps(${siteMapId})?$select=sitemapxml`);
        const json = await response.json();
        const siteMapXml = json['sitemapxml'];
        const siteMapRoot = await SiteMapMapper.parseAsync(siteMapXml);
        const sitemap = new SiteMap(siteMapRoot);
        await sitemap.loadStaticPages();
        return sitemap;
    }
}