import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import React from 'react';
import styles from './NavigationPromptDialog.module.css';

export interface INavigationPromptDialogProps {
    onDiscard: () => void;
    onSave: () => void;
    onDismiss: () => void;
}

export const NavigationPromptDialog: React.FC<INavigationPromptDialogProps> = (props) => {
    return (<Dialog
        hidden={false}
        onDismiss={() => {
            props.onDismiss();
        }}
        modalProps={{
            className: styles.root
        }}
        dialogContentProps={{
            showCloseButton: true,
            type: DialogType.normal,

            title: window.TALXIS.Portal.Translations.getLocalizedString('components/dialogs/NavigationPromptDialog/Title'),
            subText: window.TALXIS.Portal.Translations.getLocalizedString('components/dialogs/NavigationPromptDialog/SubText'),
        }}
    >
        <DialogFooter>
            <PrimaryButton onClick={() => {
                props.onSave();
            }}
                text={window.TALXIS.Portal.Translations.getLocalizedString('components/dialogs/NavigationPromptDialog/SaveAndContinue')} />
            <DefaultButton
                onClick={() => {
                    props.onDiscard();
                }}
                text={window.TALXIS.Portal.Translations.getLocalizedString('components/dialogs/NavigationPromptDialog/DiscardChanges')} />
        </DialogFooter>
    </Dialog>
    );
};