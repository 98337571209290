import React from 'react';
import ReactDOM from "react-dom";
import { IMultiSelectOptionSet } from "@talxis/base-controls";
import { MultiSelectOptionSet as MultiSelectOptionSetComponent } from "@talxis/base-controls";

interface IInputs {
    value: ComponentFramework.PropertyTypes.MultiSelectOptionSetProperty;
}
interface IOutputs {
    value?: number[];
}

export class MultiSelectOptionSet implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _outputs: IOutputs = {};

    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = container;
    }
    public updateView(context: ComponentFramework.Context<IInputs>): void {
        ReactDOM.render(React.createElement(MultiSelectOptionSetComponent, {
            context: context,
            parameters: {
                value: context.parameters.value,
                EnableDeleteButton: {
                    raw: true
                }
            },
            onNotifyOutputChanged: (outputs) => {
                this._outputs = outputs;
                this._notifyOutputChanged();
            }
        } as IMultiSelectOptionSet), this._container);
    }

    public getOutputs(): IOutputs {
        return this._outputs;
    }

    public destroy(): void {
        ReactDOM.unmountComponentAtNode(this._container);
    }
}