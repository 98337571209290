import { styled } from '@fluentui/utilities';
import { IGroupHeaderStyleProps, IGroupHeaderStyles } from '@fluentui/react';
import { getStyles } from '@fluentui/react/lib/components/GroupedList/GroupHeader.styles';
import * as React from 'react';
import { GroupHeaderBase, IGroupHeaderProps } from './GroupHeader.base';

export const GroupHeader: React.FunctionComponent<IGroupHeaderProps> = styled<
    IGroupHeaderProps,
    IGroupHeaderStyleProps,
    IGroupHeaderStyles
>(GroupHeaderBase, getStyles, undefined, {
    scope: 'GroupHeader',
});

export type { IGroupHeaderProps };