import React from 'react';
import ReactDOM from "react-dom";
import { DateTime as DateTimeComponent } from "@talxis/base-controls";
import { IDateTime } from "@talxis/base-controls";

interface IInputs {
  value: ComponentFramework.PropertyTypes.DateTimeProperty;
  enableDeleteButton?: ComponentFramework.PropertyTypes.StringProperty;
  HideMonthSelection?: ComponentFramework.PropertyTypes.StringProperty;
  hideDaySelection?: ComponentFramework.PropertyTypes.StringProperty;
  RestrictedDaysOfWeek?: ComponentFramework.PropertyTypes.StringProperty;
  RestrictedDates?: ComponentFramework.PropertyTypes.StringProperty;
  ForceDisable?: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
  value?: Date;
}

export class DateTime implements ComponentFramework.StandardControl<IInputs, IOutputs> {
  private _notifyOutputChanged: () => void;
  private _container: HTMLDivElement;
  private _outputs: IOutputs = {};

  constructor() { }

  public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
    this._notifyOutputChanged = notifyOutputChanged;
    this._container = container;
  }
  public updateView(context: ComponentFramework.Context<IInputs>): void {
    ReactDOM.render(React.createElement(DateTimeComponent, {
      context: context,
      parameters: {
        value: context.parameters.value,
        EnableDeleteButton: {
          raw: true
        },
        ForceDisable: {
          raw: context.parameters.ForceDisable?.raw === 'true'
        },
        EnableDayPicker: {
          raw: context.parameters.hideDaySelection?.raw === 'true' ? false : true
        },
        EnableMonthPicker: {
          raw: context.parameters.HideMonthSelection?.raw === 'true' ? false : true
        },
        RestrictedDaysOfWeek: {
          raw: context.parameters.RestrictedDaysOfWeek?.raw
        },
        RestrictedDates: {
          raw: context.parameters.RestrictedDates?.raw
        }
      },
      onNotifyOutputChanged: (outputs) => {
        this._outputs = outputs;
        this._notifyOutputChanged();
      }
    } as IDateTime), this._container);
  }

  public getOutputs(): IOutputs {
    return this._outputs;
  }

  public destroy(): void {
    ReactDOM.unmountComponentAtNode(this._container);
  }
}