import { IconButton, mergeStyles, useTheme } from "@fluentui/react";
import React, { useContext } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { HistoryContext } from "@src/providers/HistoryProvider/HistoryProvider";
import { history } from '@providers/HistoryProvider/HistoryProvider';

export const BackButton: React.FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const historyContext = useContext(HistoryContext);
    const theme = useTheme();
    const className = useMemo(() => {
        return `TALXIS__navigate-back-btn ${mergeStyles({
            height: 44,
            width: 44,
            '&[data-disabled="true"]': {
                pointerEvents: 'none',
                'i': {
                    color: theme.semanticColors.inputIconDisabled
                }
            },
            borderRight: `1px solid ${theme.semanticColors.bodyDivider}`
        })}`;
    }, [isDisabled]);
    useEffect(() => {
        if (historyContext.currentPage.isFirst()) {
            setIsDisabled(true);
        }
        else {
            setIsDisabled(false);
        }
    }, [historyContext.currentPage]);
    return (
        <IconButton data-disabled={isDisabled} className={className} onClick={() => history.goBack()} iconProps={{
            iconName: 'Back',
        }} />
    );
};