import { metadataRetrieveMultiple } from "./MetadataApi";

export interface ITransactionCurrency {
    transactioncurrencyid: string;
    isocurrencycode: string;
    currencysymbol: string;
    currencyname: string;
    currencyprecision: number;
}

export class TransactionCurrencyDefinition {
    private static _currenciesPromise: Promise<ITransactionCurrency[]> = null;
    private static _currencies: ITransactionCurrency[] = null;

    public static load(): Promise<ITransactionCurrency[]> {
        // https://hajekj.crm4.dynamics.com/api/data/v9.1/transactioncurrencies?$select=transactioncurrencyid,isocurrencycode,currencysymbol,currencyname

        if (!this._currenciesPromise) {
            this._currenciesPromise = new Promise<ITransactionCurrency[]>(async (resolve, reject) => {
                const currencies = await metadataRetrieveMultiple(`v9.1/transactioncurrencies?$select=transactioncurrencyid,isocurrencycode,currencysymbol,currencyname,currencyprecision`);
                this._currencies = currencies.entities as ITransactionCurrency[];
                resolve(this._currencies);
            });
        }

        return this._currenciesPromise;
    }

    public static getCurrencies(): ITransactionCurrency[] {
        if (!this._currencies) {
            throw new Error("Transaction Currencies are not loaded yet.");
        }
        return this._currencies;
    }

    public static getCurrencyById(id: string): ITransactionCurrency {
        if (!this._currencies) {
            throw new Error("Transaction Currencies are not loaded yet.");
        }
        return this._currencies.find(c => c.transactioncurrencyid === id);
    }

    //in Power Apps, the default currency for this case comes from expanded user settings that include default transaction id for the user.
    //These user settings are fetched through GetClientMetadata that return 1.6MB large JSON file
    //we currently have no way to get this information, so default currency needs to be handled like this for now
    public static getDefaultCurrency(): ITransactionCurrency {
        if (!this._currencies) {
            throw new Error("Transaction Currencies are not loaded yet.");
        }
        const defaultCurrencies = this._currencies.filter(x => x.currencyname === 'CZK' || x.currencyname === 'USD');
        let currency = defaultCurrencies.find(x => x.currencyname === 'CZK') ?? defaultCurrencies.find(x => x.currencyname === 'USD');
        if (!currency) {
            currency = this._currencies[0];
        }
        return currency;
    }
}