import { Callout, DirectionalHint, Text, FontIcon, ThemeProvider, ITextField } from "@fluentui/react";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { TextField } from '@talxis/react-components';
import { ITextFieldProps } from '@talxis/react-components';
import styles from './QuickFind.module.css';
import { ThemeDefinition } from "@src/app/classes/definitions/ThemeDefinition";

export interface IQuickFindProps {
    columnDisplayNames: string[]
    textFieldProps: ITextFieldProps;
}

export const QuickFindComponent: React.FC<IQuickFindProps> = (props) => {
    const id = useMemo(() => `prefix_${window.self.crypto.randomUUID()}`, []);
    const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
    const [isContainsSearchQuery, setIsContainsSearchQuery] = useState<boolean>(false);
    const componentRef = useRef<ITextField>(null);
    const cursorPositionRef = useRef({
        beforeStart: 0,
        beforeEnd: 0
    });

    useLayoutEffect(() => {
        componentRef.current.setSelectionRange(cursorPositionRef.current.beforeStart, cursorPositionRef.current.beforeEnd);
    }, [props.textFieldProps.value]);

    return (
        <>
            {calloutVisible &&
                <Callout
                    className={styles.callout}
                    calloutMaxWidth={250}
                    target={`#${id}`}
                    styles={{
                        calloutMain: styles.callout
                    }}
                    directionalHint={DirectionalHint.bottomLeftEdge}>
                    <div className={styles.calloutContent}>
                        {isContainsSearchQuery ?
                            <Text block>
                                <FontIcon iconName="Warning" />
                                {window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/SearchInfo*")}
                            </Text> :
                            <Text block>
                                {window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/SearchInfo/part1")}
                                <b>{window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/SearchInfo/part2")}</b>
                                {window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/SearchInfo/part3")}
                            </Text>
                        }
                        {props.columnDisplayNames.map(name => <Text key={name} block>{name}</Text>)}
                    </div>
                </Callout>
            }
            <ThemeProvider theme={ThemeDefinition.get().controlV8} applyTo="none">
                <TextField
                    id={id}
                    {...props.textFieldProps}
                    componentRef={componentRef}
                    onClick={() => setCalloutVisible(true)}
                    onBlur={() => setCalloutVisible(false)}
                    onChange={(event, newValue) => {
                        if (newValue.startsWith('*')) {
                            setCalloutVisible(true);
                            setIsContainsSearchQuery(true);
                        }
                        else {
                            setIsContainsSearchQuery(false);
                            setCalloutVisible(false);
                        }
                        const beforeStart = event.currentTarget.selectionStart;
                        const beforeEnd = event.currentTarget.selectionEnd;
                        cursorPositionRef.current = {
                            beforeStart,
                            beforeEnd
                        };
                        props.textFieldProps.onChange(event, newValue);
                    }}
                />
            </ThemeProvider>
        </>
    );
};