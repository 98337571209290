import { IControlProps } from "@controls/interfaces";
import { State } from "@src/providers/HistoryProvider/State";

export class Mode implements InternalMode {
    constructor(props: IControlProps, state: State) {
        this.isControlDisabled = props.disabled;
        this.isVisible = props.visible;
        this.label = props.label;
        this._state = state;
    }
    allocatedHeight: number;
    allocatedWidth: number;
    isControlDisabled: boolean;
    isVisible: boolean;
    label: string;
    private _state: State;
    get fullPageParam(): { [key: string]: string } {
        const params = new URLSearchParams(window.location.search);
        const data = params.get("data");
        return data ? JSON.parse(data) : null;
    }
    setControlState(state: ComponentFramework.Dictionary): boolean {
        this._state.setValues('pcf', state);
        return true;
    }
    setFullScreen(value: boolean): void {
        throw new Error("Method not implemented.");
    }
    trackContainerResize(value: boolean): void {
        //throw new Error("Method not implemented.");
    }
}

interface InternalMode extends ComponentFramework.Mode {
    fullPageParam: { [key: string]: string };
}