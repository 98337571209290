import { IconButton, Label, MessageBar, MessageBarType, PrimaryButton, Spinner, ThemeProvider, Image, TooltipHost } from '@fluentui/react';
import { ThemeDefinition } from '@src/app/classes/definitions/ThemeDefinition';
import { SiteMap } from '@src/app/interfaces/sitemap';
import Dialog from '@src/components/dialogs/Dialog';
import { IDialogProps } from '@src/components/dialogs/Dialog/interfaces';
import { IStage, IStageState, Multistage, StageValue, TagPicker } from '@talxis/react-components';
import { IItemProps } from '@talxis/react-components';
import React, { useEffect, useRef, useState } from 'react';
import styles from './InstalationDialog.module.css';
import { Text } from '@fluentui/react/lib/Text';
import { Taskbar } from '../Taskbar/Taskbar';
import { AppModule } from '@src/app/classes/configuration/AppModule';
import produce from 'immer';
import { EditShortcutNameDialog } from './EditShortcutNameDialog/EditShortcutNameDialog';
import { sprintf } from 'sprintf-js';

export interface IShortcut {
    id: string;
    text: string;
}
interface IInstallationDialogProps extends IDialogProps {
    subAreas: SiteMap.SubArea[];
    onInjectUserSettingsToManifest: (selectedSubAreas: SiteMap.SubArea[]) => Promise<void>;
    onInstall(): Promise<void>;
    maxShortcutCount: number;
};

export const InstallationDialog: React.FC<IInstallationDialogProps> = (props) => {
    const transformSubAreaToItem = (subArea: SiteMap.SubArea): IItemProps => {
        const iconStyles = {
            root: {
                fontSize: 12
            }
        };
        return {
            key: subArea.id,
            text: subArea.title,
            secondaryText: subArea.parentGroupTitle,
            buttons: [
                {
                    hideInSuggestions: true,
                    key: 'edit',
                    iconProps: {
                        styles: iconStyles,
                        iconName: 'Edit'
                    },
                    onClick: () => setCurrentlyEditedShortcut({
                        id: subArea.id,
                        text: subArea.title
                    })
                }
            ],
            deleteButtonProps: {
                key: 'deleteSubArea',
                iconProps: {
                    styles: iconStyles,
                    iconName: 'ChromeClose'
                }
            }
        };
    };
    const [selectedSubAreas, setSelectedSubAreas] = useState<SiteMap.SubArea[]>(props.subAreas.slice(0, 3));
    const [isInjectingUserSettings, setIsInjectingUserSettings] = useState<boolean>(false);
    const [pendingInstallation, setPendingInstallation] = useState<boolean>();
    const [numberOfShortcutSlotsAvailable, setNumberOfShortcutsSlotsAvailable] = useState<number>(props.maxShortcutCount - selectedSubAreas.length);
    const [currentlyEditedShortcut, setCurrentlyEditedShortcut] = useState<IShortcut>(null);
    const hasNavigationShortcutBeenAddedRef = useRef<boolean>(false);
    const getTranslation = (key: string) => window.TALXIS.Portal.Translations.getLocalizedString(key);

    const onResolveSuggestions = (filter: string, selectedItems?: IItemProps[]): IItemProps[] => {
        return props.subAreas.filter(subArea => {
            if (!subArea.title.includes(filter)) {
                return false;
            }
            if (selectedItems.map(selectedItem => selectedItem.key).includes(subArea.id)) {
                return false;
            }
            return true;
        }).map(subArea => transformSubAreaToItem(subArea));
    };

    const onNavigationShortcutChange = (items: IItemProps[]) => {
        if (items.length < selectedSubAreas.length) {
            setNumberOfShortcutsSlotsAvailable(numberOfShortcutSlotsAvailable + 1);
        }
        if (items.length > selectedSubAreas.length) {
            setNumberOfShortcutsSlotsAvailable(numberOfShortcutSlotsAvailable - 1);
            hasNavigationShortcutBeenAddedRef.current = true;
        }
        const selectedExistingAreas = selectedSubAreas.filter(selectedSubArea => items.find(item => item.key === selectedSubArea.id));
        const selectedNewAreas = props.subAreas.filter(selectedNewArea => items.find(item => item.key === selectedNewArea.id))
            .filter(subArea => !selectedExistingAreas.map(selectedExistingAreas => selectedExistingAreas.id).includes(subArea.id));

        setSelectedSubAreas([...selectedExistingAreas, ...selectedNewAreas]);

    };

    const getStages = (): IStage[] => {
        const stages = [{
            key: 'stage1',
            label: getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step1'),
            onRender: () => {
                return (
                    <div className={styles.introduction}>
                        <div className={styles.introductionScrollableWrapper}>
                            <Text className={styles.introductionText}>{sprintf(getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step1/Description'), AppModule.get().name)}</Text>
                            <Image className={styles.appModuleIcon} src={AppModule.get().icon.value} />
                        </div>
                        <Taskbar />
                    </div>
                );
            }
        }, {
            key: 'stage2',
            label: getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step2'),
            onRender: () => {
                return (
                    <div className={styles.shortcuts}>
                        <MessageBar truncated={true} isMultiline={false} messageBarType={MessageBarType.info}>
                            {getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step2/Explanation')}
                        </MessageBar>
                        {/*   
                      //TODO: implement these shortcuts
                      <fieldset>
                            <legend>Actions</legend>
                            <Checkbox label='Create record' />
                            <Checkbox label='Select record' />
                        </fieldset> 
                        */}
                        <div>
                            <div className={styles.navigationShortcutsLabel}>
                                <Label>{getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step2/NavigationShortcuts')}</Label>
                                <TooltipHost content={getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step2/NavigationShortcuts/Tooltip')}>
                                    <IconButton iconProps={{
                                        iconName: 'Info'
                                    }} />
                                </TooltipHost>
                            </div>
                            <ThemeProvider theme={ThemeDefinition.get().controlV8}>
                                <TagPicker
                                    selectedItems={selectedSubAreas.map(selectedSubArea => transformSubAreaToItem(selectedSubArea))}
                                    itemLimit={props.maxShortcutCount}
                                    onChange={onNavigationShortcutChange}
                                    searchBtnProps={{
                                        key: 'search',
                                        iconProps: {
                                            iconName: 'Search'
                                        }
                                    }}
                                    stackItems
                                    onResolveSuggestions={onResolveSuggestions} />
                            </ThemeProvider>
                        </div>
                    </div>
                );
            }
        },
        {
            key: 'stage3',
            label: getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step3'),
            onRender: () => {
                return (
                    <div className={styles.finishInstallation}>
                        <Text block variant='xxLarge'>{getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step3/ReadyToInstall')}</Text>
                        <div className={styles.successCircleWrapper}>
                            <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle className={styles.checkmarkCircle} cx="26" cy="26" r="25" fill="none" /> <path className={styles.checkmarkCheck} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                    </div>
                );
            }
        }
        ];
        return stages;
    };
    const getStageStates = (stages: IStage[]): IStageState[] => {
        return stages.map(stage => {
            return {
                stageKey: stage.key,
                value: stage.key === 'stage1' ? StageValue.in_progress : StageValue.inactive
            };
        });
    };
    const changeState = async (nextStageKey: string, goesToPreviousStage: boolean) => {
        const nextStageStates = await produce(stageStates, async draftStageStates => {
            const currentStageState = draftStageStates.find(state => state.stageKey === currentStageKey);
            if (nextStageKey === 'stage3') {
                setIsInjectingUserSettings(true);
                await props.onInjectUserSettingsToManifest(selectedSubAreas);
                setIsInjectingUserSettings(false);
            }
            if (!goesToPreviousStage) {
                currentStageState.value = StageValue.valid;
            }

        });
        setCurrentStageKey(nextStageKey);
        //TODO: the current stage key does not get reflected unless stageStates are rerendered, investigate in shared component
        setStageStates([...nextStageStates]);
    };

    const onInstallHandler = async () => {
        const nextStageStatesPendingInstall = produce(stageStates, draftStageStates => {
            const currentStageState = draftStageStates.find(state => state.stageKey === currentStageKey);
            currentStageState.messageProps = {
                message: getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step3/ClickToFinishInstall'),
            };
        });
        setStageStates(nextStageStatesPendingInstall);
        setPendingInstallation(true);
        await props.onInstall();
        const nextStageStatesFinishedInstall = produce(stageStates, draftStageStates => {
            const currentStageState = draftStageStates.find(state => state.stageKey === currentStageKey);
            currentStageState.messageProps = null;
        });
        setStageStates(nextStageStatesFinishedInstall);
        setPendingInstallation(false);
    };

    const editShortcutTitle = (updatedShorcut: IShortcut) => {
        setSelectedSubAreas(selectedSubAreas.map(selectedSubArea => {
            if (selectedSubArea.id === updatedShorcut.id) {
                return {
                    ...selectedSubArea,
                    title: updatedShorcut.text
                };
            }
            return selectedSubArea;
        }));
        setCurrentlyEditedShortcut(null);
    };

    const stages = getStages();
    const [stageStates, setStageStates] = useState<IStageState[]>(() => getStageStates(stages));
    const [currentStageKey, setCurrentStageKey] = useState<string>('stage1');

    useEffect(() => {
        const nextStageStates = produce(stageStates, draftStageStates => {
            const currentStageState = draftStageStates.find(state => state.stageKey === 'stage2');
            if (numberOfShortcutSlotsAvailable === 0) {
                currentStageState.messageProps = {
                    messageBarProps: {
                        messageBarType: MessageBarType.warning
                    },
                    message: getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/Step2/ShortcutLimitMessage')
                };
                return;
            }
            currentStageState.messageProps = null;
        });
        setStageStates(nextStageStates);
    }, [numberOfShortcutSlotsAvailable]);

    useEffect(() => {
        if (!hasNavigationShortcutBeenAddedRef.current) {
            return;
        }
        const stageBody = document.querySelector('.TALXIS__Multistage__body');
        setTimeout(() => {
            stageBody?.scrollTo(0, stageBody.scrollHeight);
            hasNavigationShortcutBeenAddedRef.current = false;
        }, 50);
    }, [selectedSubAreas]);
    return (
        <ThemeProvider theme={ThemeDefinition.get().main}>
            <Dialog {...props}
                modalProps={{
                    className: styles.root
                }}>
                <Multistage
                    currentStageKey={currentStageKey}
                    footerProps={{
                        onRender: (footerProps) => {
                            return (
                                <div className='TALXIS__Multistage__footer'>
                                    {footerProps.showSubmitButton &&
                                        <PrimaryButton disabled={pendingInstallation} onClick={onInstallHandler} text={getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/Install')} />
                                    }
                                    {footerProps.showNextButton && isInjectingUserSettings &&
                                        <IconButton className={styles.loadingSpinner} onRenderIcon={() => <Spinner label={getTranslation('@controls/loadings/MainLoading')} />} />
                                    }
                                    {footerProps.showNextButton && !isInjectingUserSettings &&
                                        <IconButton data-icon-next={true} iconProps={{
                                            iconName: 'SkypeCircleArrow'
                                        }} onClick={footerProps.goToNextStage} />
                                    }
                                </div>
                            );
                        }
                    }}
                    stages={stages}
                    onStageChange={changeState}
                    stageStates={stageStates} />
            </Dialog>
            {currentlyEditedShortcut &&
                <EditShortcutNameDialog
                    shortcut={currentlyEditedShortcut}
                    onDismiss={() => setCurrentlyEditedShortcut(null)}
                    onSave={editShortcutTitle} />
            }
        </ThemeProvider>
    );
};