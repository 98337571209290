import { IButtonProps, ContextualMenuItemType } from "@fluentui/react";
import { useState, useRef } from "react";
import { Grid } from "../Grid";
import { ViewSelector } from "./ViewSelector";
import { sanitizeGuid } from "@src/app/Functions";

export const useViewSwitcher = (changeView: (query: string) => void): [
    IButtonProps,
    (grid: Grid) => Promise<void>,
    (text?: string) => Promise<void>
] => {
    const titleRef = useRef<string>(null);
    const viewSelectorRef = useRef<ViewSelector>(null);
    const [viewPickerProps, setViewPickerProps] = useState<IButtonProps>(null);

    const getButtonProps = async (): Promise<IButtonProps> => {
        const allViewNamesAndIds = await viewSelectorRef.current.getViewNamesAndIds();
        return {
            text: titleRef.current,
            menuProps: {
                items: [{
                    key: 'header',
                    itemType: ContextualMenuItemType.Header,
                    text: window.TALXIS.Portal.Translations.getLocalizedString("@pages/control/view/systemViews")
                }
                    , ...allViewNamesAndIds.sort((a, b) => a.name.localeCompare(b.name)).map(x => {
                        return {
                            key: x.savedqueryid,
                            text: x.name,
                            ['data-selected']: sanitizeGuid(viewSelectorRef.current.getCurrentView().id) === x.savedqueryid,
                            onClick: () => {
                                viewSelectorRef.current.setCurrentView({
                                    entityType: viewSelectorRef.current.getCurrentView().entityType,
                                    id: x.savedqueryid,
                                    name: x.name
                                });
                                refresh(x.name);
                            }
                        };
                    })]
            }
        };
    };

    const refresh = async (text?: string) => {
        if (!viewSelectorRef.current.isVisible() || text === "") {
            setViewPickerProps(null);
            return;
        }
        //TODO: disable until we have a implemented a correct fetchXml differ
        titleRef.current = text ?? viewSelectorRef.current.getCurrentView().name;
        setViewPickerProps(await getButtonProps());
    };

    const init = async (grid: Grid) => {
        viewSelectorRef.current = grid.viewSelector;
        grid.viewSelector.init(refresh, changeView);
    };

    return [viewPickerProps, init, refresh];
};
