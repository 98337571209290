import React from 'react';
import ReactDOM from "react-dom";
import { Text } from '@fluentui/react/lib/Text';

interface IInputs {
    value: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
    value?: string;
}

export class Label implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _value: string;
    private _container: HTMLDivElement;

    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._container = document.createElement("div");
        container.appendChild(this._container);
    }
    public updateView(context: ComponentFramework.Context<IInputs>): void {
        ReactDOM.render(React.createElement(Text, { children: context.mode.label || this._value }), this._container);
    }

    public getOutputs(): IOutputs {
        return {
            value: this._value
        };
    }

    public destroy(): void {
    }
}