import { EnvironmentNotSelectedException } from "./exceptions/EnvironmentNotSelectedException";
import { isDev } from "../Functions";

export class MultitenantProvider {
    public static getFetchHeader(): RequestInit {
        const request: RequestInit = {
            headers: {
                ...((isDev() || import.meta.env.VITE_HOSTNAME) && { 'TALXIS-Portal-Hostname': MultitenantProvider.getHostname() })
            }
        };
        return request;
    }
    public static getHostname(): string {
        if (isDev() && !import.meta.env.VITE_HOSTNAME && !localStorage.getItem('HOSTNAME_OVERRIDE')) {
            throw new EnvironmentNotSelectedException('In order to use the portal frontend on localhost or *.frontend.portals.talxis.com hostnames,' +
                'you need to override the portal hostname using environment variable REACT_APP_HOSTNAME (you can do it locally by creating .env.local file) ' +
                'or HOSTNAME_OVERRIDE item to the browser localStorage.');
        }
        return import.meta.env.VITE_HOSTNAME || localStorage.getItem('HOSTNAME_OVERRIDE') || window.location.hostname;
    }
}