import { Page } from "./Page";
import { parse, stringify } from 'flatted';
import { AppModule } from "@src/app/classes/configuration/AppModule";
import { replaceLast } from "@src/app/Functions";

window.addEventListener('pagehide', () => {
    HistoryManager.showLoading();
    HistoryManager.saveHistory();
});

export interface ISessionPage {
    _path: string;
    _index: number;
    _sitemapKey: string;
    _state: ISessionState;
    _previousPage: ISessionPage | null;
    _nextPage: ISessionPage | null;
    _isNextPage: boolean | undefined;
}
export interface ISessionState {
    _id: string;
    _childStates: ISessionState[];
    _nativeValues?: ComponentFramework.Dictionary;
    _controlValues?: ComponentFramework.Dictionary;
}

export class HistoryManager {
    private static _currentPageRef: React.MutableRefObject<Page>;
    public static pages: Page[] = [];
    public static activeControls: Set<ComponentFramework.StandardControl<any, any>> = new Set();
    public static showLoading: () => void = () => { };
    public static setCurrentPageRef(ref: React.MutableRefObject<Page>) {
        this._currentPageRef = ref;
    }
    public static getCurrentPage(): Page {
        return HistoryManager._currentPageRef.current;
    }
    public static isUserOnLandingPage(): boolean {
        const currentAppModuleName = AppModule.get().uniquename;
        let pagePath = decodeURIComponent(`${window.location.pathname}${window.location.search}`);
        if (pagePath.endsWith("/") && pagePath.length > 1) {
            pagePath = replaceLast(pagePath, "/", "");
        }
        if (pagePath === `/${currentAppModuleName}` || pagePath === '/') {
            return true;

        }
        return false;
    }
    public static getSessionPage(): Page | null {
        const pageString = sessionStorage.getItem('history');
        if (!pageString) {
            return null;
        }
        const sessionPage = parse(pageString) as ISessionPage;
        const page = new Page(sessionPage._path, sessionPage._index, null, sessionPage);
        //do not load session state if the last page path does not equal the current one
        if (decodeURIComponent(`${window.location.pathname}${window.location.search}`) !== page.path) {
            return null;
        }
        return page;
    }
    public static saveHistory() {
        //save the state of current page (native)
        HistoryManager._currentPageRef.current.state.saveStateValuesChanges();
        //save the state of all controls that are currently present on screen
        for (const control of HistoryManager.activeControls) {
            control.destroy();
        }
        const history = stringify(HistoryManager._currentPageRef.current, (key, value) => {
            if ((key === '_nativeValues' || key === '_controlValues') && Object.keys(value).length === 0) {
                return undefined;
            }
            if (key === '_childStates' && value.length === 0) {
                return undefined;
            }
            if (key === '_entityName') {
                return undefined;
            }
            return value;
        });
        sessionStorage.setItem('history', history);
    }
};