import { Client, Resources, Utility, Factory } from "../Implementation";
import { WebApi } from "./WebApi";
import { Mode } from "./Mode";
import { Navigation } from "./Navigation";
import { Manifest } from "@controls/interfaces/manifest";
import { Form } from '@controls/native/Form/interfaces/form';
import { IControlProps } from "@controls/interfaces";
import { EntityDefinition } from "@definitions/EntityDefinition";
import { OptionSetDefinition } from "@definitions/OptionSetDefinition";
import { OptionSet, IOptionSetDefinition as OptionSetValue, Option } from "@app/interfaces/optionset";
import { ICustomControl } from "@controls/interfaces/customcontrol";
import { GlobalOptionSet } from "@app/interfaces/twooptions";
import { RequiredLevel } from "@app/interfaces/entitydefinition";
import { IAttributeConfiguration } from "@controls/native/Form/interfaces/IAttributeConfiguration";
import * as LRU from 'lru-cache';
import { ViewDefinition } from "@definitions/ViewDefinition";
import { MultiSelectOptionSet, IMultiSelectOptionSetDefinition as MultiSelectGlobalOptionSetValue } from "@app/interfaces/multiselectoptionset";
import { LocalizeLabel } from "@localization/helpers";
import { DataType } from "../interfaces/DataType";
import { sanitizeGuid } from "@app/Functions";
import { DomParser } from "@app/Constants";
import { IFileObject } from "@src/components/controls/interfaces/IFileObject";
import { State } from "@src/providers/HistoryProvider/State";
import { Grid } from "@src/components/controls/DatasetControl/Grid";
import { IDatasetControlProps } from "@src/components/controls/DatasetControl/interfaces";
import { ITransactionCurrency, TransactionCurrencyDefinition } from "@src/app/classes/definitions/TransactionCurrencyDefinition";
import { CURRENCY_NEGATIVE_PATTERN, CURRENCY_POSITIVE_PATTERN, Formatting } from "./Formatting/Formatting";
import { UserSettingsDefinition } from "@src/app/classes/definitions/UserSettingsDefinition";
import { UserSettings } from "@src/app/classes/models/UserSettings";
import numeral from "numeral";
import dayjs from "dayjs";
import isEmail from 'validator/lib/isEmail';
import { ThemeDefinition } from "@src/app/classes/definitions/ThemeDefinition";
import { Numeral } from "@talxis/base-controls";

// The cache here is used for short-term caching when handling lookup values for PCF Context, mostly cuts down time on nested forms which receive values via extraqs
const lookupEntityCache = new LRU.default<string, ComponentFramework.WebApi.Entity>({
    maxAge: 1000 * 5
});

export const getRequiredLevel = (attributeConfiguration: null | IAttributeConfiguration, fieldRequired?: boolean): ComponentFramework.PropertyHelper.Types.RequiredLevel => {
    let requiredLevel: ComponentFramework.PropertyHelper.Types.RequiredLevel = -1;
    switch (attributeConfiguration?.entityRequiredLevel) {
        case "ApplicationRequired":
            requiredLevel = 2;
            break;
        case "None":
            requiredLevel = 0;
            break;
        case "Recommended":
            requiredLevel = 3;
            break;
        case "SystemRequired":
            requiredLevel = 1;
            break;
    }

    if (fieldRequired) {
        requiredLevel = 2;
    }

    if (attributeConfiguration && requiredLevel !== 1) {
        switch (attributeConfiguration.customRequiredLevel) {
            case "none":
                requiredLevel = 0;
                break;
            case "recommended":
                requiredLevel = 3;
                break;
            case "required":
                requiredLevel = 1;
                break;
        }
    }
    return requiredLevel;
};

async function getBindingParameters(controlProps: IControlProps, entityName: string, manifest: Manifest.Control, bindings: Form.ControlBindings, entity: ComponentFramework.WebApi.Entity, attributeConfiguration: { [name: string]: IAttributeConfiguration }, context: Context, entityChanges?: ComponentFramework.WebApi.Entity, onSetErrorMessage?: (errorMessage: string) => void, formErrorMessage?: string) {
    let formError = formErrorMessage ? true : false;
    if (!formError) {
        formErrorMessage = null;
    }
    const parameters: { [propertyName: string]: ComponentFramework.PropertyTypes.Property } = {};
    let bindingFieldParametersCreated = false;
    for (const [property, binding] of Object.entries(bindings ?? {})) {
        const isBindingField = (() => {
            if (bindingFieldParametersCreated) {
                return false;
            }
            if (!binding.isStatic) {
                bindingFieldParametersCreated = true;
                return true;
            }
        })();
        //@ts-ignore - Special binding for virtual grids
        if (binding.Type === 'Grid') {
            continue;
        }
        const fieldType = manifest?.properties.find(x => x.name === property)?.ofType;
        if (fieldType === DataType.LookupSimple || fieldType == DataType.LookupCustomer || fieldType === DataType.LookupOwner) {
            let lookupTargets: string[] = [];
            let logicalName: string;
            let displayName: string;
            let description: string;
            let viewId: string;
            let fieldName = binding.value;

            if (!entityName) {
                const targetEntities = DomParser.parseFromString(bindings["TargetEntities"].value, "text/xml");
                const entityLogicalNameElements = targetEntities.querySelectorAll('EntityLogicalName');
                for (const entityLogicalNameElement of entityLogicalNameElements) {
                    lookupTargets.push(entityLogicalNameElement.textContent);
                }
                logicalName = lookupTargets[0];
                const entityDefinition = await EntityDefinition.getAsync(logicalName);
                description = LocalizeLabel(entityDefinition.Description.LocalizedLabels);
                displayName = LocalizeLabel(entityDefinition.DisplayName.LocalizedLabels);
                // Getting lookup view of target entity.
                // Current implementation does not support lookup to multiple entities.
                viewId = attributeConfiguration?.[binding.value]?.defaultViewId ??
                    sanitizeGuid(targetEntities.querySelector("DefaultViewId")?.textContent) ??
                    (await ViewDefinition.getLookupViewAsync(logicalName)).savedqueryid;
            }
            else {
                const entityMetadata = await Xrm.Utility.getEntityMetadata(entityName, [binding.value]);
                // if (binding.type === "Lookup.Simple") {
                //     // TODO: This is a workaround to parse the body of parameters
                //     const lookupParameterObject = DomParser.parseFromString(`<root>${binding.extraParameters}</root>`, "text/xml");
                //     const bindingAttribute = lookupParameterObject.getElementsByTagName("BindAttribute")?.[0]?.textContent;
                //     if (bindingAttribute) {
                //         attribute = entityDefinition.Attributes.find(x => x.LogicalName === bindingAttribute);
                //     }
                //     else {
                //         attribute = entityDefinition.Attributes.find(x => x.LogicalName === binding.value);
                //     }
                // }
                //@ts-ignore - No getByName
                const attribute = entityMetadata.Attributes.getByName(binding.value);
                // TODO: This should come from binding.extraParamters if provided instead of global DefaultViewId in case there are multiple lookups bound, see code above
                lookupTargets = attribute.attributeDescriptor.Targets;
                if (bindings["value"].attributes?.targets) {
                    if (lookupTargets)
                        lookupTargets = lookupTargets.filter(target => bindings["value"].attributes.targets.includes(target));
                    else
                        lookupTargets = bindings["value"].attributes?.targets;
                }
                if (lookupTargets.length === 0 && attribute.EntityLogicalName) {
                    lookupTargets.push(attribute.EntityLogicalName);
                }
                // Getting lookup view of target entity.
                // Current implementation does not support lookup to multiple entities.
                viewId = attributeConfiguration?.[binding.value]?.defaultViewId ??
                    sanitizeGuid(bindings["DefaultViewId"]?.value) ??
                    (await ViewDefinition.getLookupViewAsync(lookupTargets[0])).savedqueryid;
                logicalName = attribute.LogicalName;
                description = attribute.Description;
                displayName = attribute.DisplayName;
                fieldName = attribute.LogicalName;
            }
            let value: ComponentFramework.LookupValue[] = null;
            //lookup is bound to a form
            if (entity[fieldName]) {
                value = entity[fieldName];
            }
            //lookup binding is not bound to a form but it's value has been set (for example through binding on TALXIS.WebPage)
            if (Array.isArray(binding.value)) {
                value = binding.value;
            }

            const boundValue: ComponentFramework.PropertyTypes.LookupProperty = {
                error: formError,
                errorMessage: formErrorMessage,
                type: fieldType,
                getTargetEntityType: () => {
                    return lookupTargets[0];
                },
                getViewId: () => {
                    if (viewId) {
                        return viewId;
                    }

                    // TODO: This is used for filtering, probably use the default QuickView?
                    throw new Error("Unable to find QuickFindView!");
                },
                //@ts-ignore - not part of types
                getAllViews: async (entityName: string) => {
                    let _viewId = viewId;
                    if (lookupTargets.length > 1) {
                        _viewId = (await ViewDefinition.getLookupViewAsync(entityName)).savedqueryid;
                        if (!_viewId) {
                            _viewId = (await ViewDefinition.getQuickFindViewAsync(entityName)).savedqueryid;
                        }
                    }
                    if (!viewId) {
                        viewId = (await ViewDefinition.getQuickFindViewAsync(entityName)).savedqueryid;
                    }
                    return [{
                        isDefault: true,
                        viewId: _viewId
                    }];
                },
                attributes: {
                    Description: description,
                    DisplayName: displayName,
                    LogicalName: logicalName,
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null,
                    Targets: lookupTargets
                } as ComponentFramework.PropertyHelper.FieldPropertyMetadata.LookupMetadata,
                raw: []
            };
            if (value != null && value.length > 0) {
                for (const lookupValue of value) {
                    let name = lookupValue.name;
                    if (!name) {
                        const cacheKey = `${lookupValue.entityType}(${lookupValue.id})`;
                        const cachedResult = lookupEntityCache.get(cacheKey);
                        const targetEntityDefinition = await EntityDefinition.getAsync(lookupValue.entityType);
                        if (cachedResult) {
                            name = cachedResult[targetEntityDefinition.PrimaryNameAttribute];
                        }
                        else {
                            const targetEntityValue = await window.Xrm.WebApi.retrieveRecord(lookupValue.entityType, lookupValue.id, `?$select=${targetEntityDefinition.PrimaryNameAttribute}`);
                            name = targetEntityValue[targetEntityDefinition.PrimaryNameAttribute];
                            lookupEntityCache.set(cacheKey, targetEntityValue);
                        }
                    }
                    boundValue.raw.push({
                        id: lookupValue.id,
                        entityType: lookupValue.entityType,
                        name: name
                    });
                }
            }

            parameters[property] = boundValue;
        }
        else if (fieldType === DataType.OptionSet) {
            let optionSetsDefinition: OptionSetValue;
            let optionSet: OptionSet;
            let requiredLevel: RequiredLevel;
            // Check if Lookup is bound or unbound (dialog)
            if (entityName) {
                const definition = await OptionSetDefinition.getAsync(entityName);
                optionSetsDefinition = definition.value.find(x => x.LogicalName === binding.value);
                optionSet = optionSetsDefinition.OptionSet;
                requiredLevel = optionSetsDefinition.RequiredLevel;
            }
            else {
                const optionSetNameBinding = bindings["OptionSetName"] ?? binding;
                if (optionSetNameBinding) {
                    optionSet = await OptionSetDefinition.getGlobalAsync(optionSetNameBinding.value);
                }
                else {
                    throw new Error("Unable to find OptionSetName for unbound control in dialog!");
                }
            }

            // TODO: Get OptionSet definition from metadata and set default option and map available options

            const options: ComponentFramework.PropertyHelper.OptionMetadata[] = [];
            for (const option of attributeConfiguration?.[binding.value]?.options || optionSet.Options) {
                options.push({
                    Color: option.Color,
                    Label: LocalizeLabel(option.Label.LocalizedLabels),
                    Value: option.Value
                });
            }

            const parameter: ComponentFramework.PropertyTypes.OptionSetProperty = {
                raw: entity[binding.value],
                error: formError,
                errorMessage: formErrorMessage,
                type: fieldType,
                attributes: {
                    DefaultValue: optionSetsDefinition?.DefaultFormValue,
                    Description: LocalizeLabel(optionSet.Description.LocalizedLabels),
                    DisplayName: LocalizeLabel(optionSet.DisplayName.LocalizedLabels),
                    LogicalName: optionSet.Name,
                    Options: options,
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null
                }
            };

            parameters[property] = parameter;
        }
        else if (fieldType === DataType.TwoOptions) {
            let optionSet: GlobalOptionSet;
            let requiredLevel: RequiredLevel;
            if (entityName) {
                const response = await OptionSetDefinition.getTwoOptionsAsync(entityName);
                const attribute = response.value.find(x => x.LogicalName === binding.value);
                optionSet = attribute.OptionSet;
                requiredLevel = attribute.RequiredLevel;
            }
            else {
                // When we are unbound, we are going to GlobalOptionSets
                const optionSetNameBinding = bindings["OptionSetName"] ?? binding;
                if (optionSetNameBinding) {
                    try {
                        optionSet = await OptionSetDefinition.getGlobalTwoOptionsAsync(optionSetNameBinding.value);
                    } catch (error) {
                        // If boolean doesn't exist as global, we can use an empty object
                        optionSet = {
                            Name: null,
                            IsCustomOptionSet: false,
                            IsGlobal: null,
                            OptionSetType: "bool",
                            Description: { LocalizedLabels: null },
                            DisplayName: { LocalizedLabels: null },
                            TrueOption: {
                                Value: null,
                                Description: { LocalizedLabels: null },
                                ParentValues: null,
                                Label: { LocalizedLabels: null }
                            },
                            FalseOption: {
                                Value: null,
                                Description: { LocalizedLabels: null },
                                ParentValues: null,
                                Label: { LocalizedLabels: null }
                            }
                        };
                    }

                }
                else {
                    throw new Error("Unable to find OptionSetName for unbound control in dialog!");
                }
            }

            const parameter: ComponentFramework.PropertyTypes.TwoOptionsProperty = {
                raw: entity[binding.value],
                error: formError,
                errorMessage: formErrorMessage,
                type: fieldType,
                attributes: {
                    DefaultValue: false,
                    Description: LocalizeLabel(optionSet.Description.LocalizedLabels),
                    DisplayName: LocalizeLabel(optionSet.DisplayName.LocalizedLabels),
                    LogicalName: optionSet.Name,
                    Options: [
                        {
                            Color: optionSet.TrueOption.Color,
                            Label: LocalizeLabel(optionSet.TrueOption.Label.LocalizedLabels),
                            Value: optionSet.TrueOption.Value
                        },
                        {
                            Color: optionSet.FalseOption.Color,
                            Label: LocalizeLabel(optionSet.FalseOption.Label.LocalizedLabels),
                            Value: optionSet.FalseOption.Value
                        }
                    ],
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null
                }
            };

            parameters[property] = parameter;
        }
        else if (fieldType === DataType.MultiSelectOptionSet) {
            let optionSetsDefinition: MultiSelectGlobalOptionSetValue;
            let optionSet: MultiSelectOptionSet;
            let requiredLevel: RequiredLevel;
            // Check if Lookup is bound or unbound (dialog)
            if (entityName) {
                const definition = await OptionSetDefinition.getMultiSelectOptionSetAsync(entityName);
                optionSetsDefinition = definition.value.find(x => x.LogicalName === binding.value);
                // This covers filtering cases in grid (we could technically move this away into attributeConfiguration)
                if (!optionSetsDefinition?.OptionSet) {
                    const definition = await OptionSetDefinition.getAsync(entityName);
                    optionSetsDefinition = definition.value.find(x => x.LogicalName === binding.value);
                    if (!optionSetsDefinition?.OptionSet) {
                        const definition = await OptionSetDefinition.getTwoOptionsAsync(entityName);
                        const twoOptions = definition.value.find(x => x.LogicalName === binding.value);
                        optionSet = { ...twoOptions.OptionSet, Options: [twoOptions.OptionSet.TrueOption, twoOptions.OptionSet.FalseOption] };
                        optionSetsDefinition = { ...twoOptions, DefaultFormValue: twoOptions.OptionSet.FalseOption.Value, OptionSet: optionSet };
                    }
                    else {
                        optionSet = optionSetsDefinition.OptionSet;
                    }
                }
                else {
                    optionSet = optionSetsDefinition.OptionSet;
                }
                requiredLevel = optionSetsDefinition.RequiredLevel;
            }
            else {
                const optionSetNameBinding = bindings["OptionSetName"] ?? binding;
                if (optionSetNameBinding) {
                    optionSet = await OptionSetDefinition.getGlobalMultiSelectOptionSetAsync(optionSetNameBinding.value);
                }
                else {
                    throw new Error("Unable to find OptionSetName for unbound control in dialog!");
                }
            }

            // TODO: Get OptionSet definition from metadata and set default option and map available options

            const options: ComponentFramework.PropertyHelper.OptionMetadata[] = [];
            for (const option of attributeConfiguration?.[binding.value]?.options || optionSet.Options) {
                options.push({
                    Color: option.Color,
                    Label: LocalizeLabel(option.Label.LocalizedLabels),
                    Value: option.Value
                });
            }

            const value: number[] = [];
            if (Array.isArray(entity[binding.value])) {
                for (const val of entity[binding.value]) {
                    value.push(parseInt(val));
                }
            }
            else if (entity[binding.value]) {
                value.push(parseInt(entity[binding.value]));
            }

            const parameter: ComponentFramework.PropertyTypes.MultiSelectOptionSetProperty = {
                raw: value,
                error: formError,
                errorMessage: formErrorMessage,
                type: fieldType,
                attributes: {
                    DefaultValue: optionSetsDefinition?.DefaultFormValue,
                    Description: LocalizeLabel(optionSet.Description.LocalizedLabels),
                    DisplayName: LocalizeLabel(optionSet.DisplayName.LocalizedLabels),
                    LogicalName: optionSet.Name,
                    Options: options,
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null
                }
            };

            parameters[property] = parameter;
        }
        // TODO: We don't support of-type group in parsing at the moment
        else if (fieldType as string === 'DateTime' || fieldType === DataType.DateAndTimeDateAndTime || fieldType == DataType.DateAndTimeDateOnly) {
            let value = binding.isStatic ? binding.value : entity[binding.value];
            let error = false;
            const isDateOnly = await (async () => {
                if (fieldType === DataType.DateAndTimeDateOnly) {
                    return true;
                }
                const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
                if (!entityDefinition) {
                    return false;
                }
                const format = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value).Format;
                switch (format) {
                    case 'DateAndTime':
                    case 'Date and Time':
                    case 'DateAndTime.DateAndTime':
                    case 'datetime': {
                        return false;
                    }
                }
                return true;
            })();
            const formatting = isDateOnly ? context.userSettings.dateFormattingInfo.shortDatePattern : `${context.userSettings.dateFormattingInfo.shortDatePattern} ${context.userSettings.dateFormattingInfo.shortTimePattern}`;
            const dayjsDate = dayjs(value, formatting, true);
            if (typeof value?.getMonth !== 'function' && value) {
                if (!dayjsDate.isValid()) {
                    error = true;
                }
                else {
                    value = dayjsDate.toDate();
                }
            }
            if (binding.isStatic) {
                parameters[property] = {
                    raw: value,
                    error: formError || error,
                    errorMessage: formErrorMessage ?? (error ? window.TALXIS.Portal.Translations.getLocalizedString('@ComponentFramework/PropertyClasses/Context') : undefined),
                    type: fieldType,
                    formatted: null,
                    attributes: {
                        Description: null,
                        DisplayName: null,
                        LogicalName: null,
                        SourceType: null,
                        RequiredLevel: null,
                        IsSecured: null,
                    }
                };
            }
            else {
                const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
                const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);

                let dateTimeBehavior: ComponentFramework.FormattingApi.Types.DateTimeFieldBehavior = null;
                switch (field?.DateTimeBehavior.Value) {
                    case "None":
                        dateTimeBehavior = 0;
                        break;
                    case "UserLocal":
                        dateTimeBehavior = 1;
                        break;
                    case "TimeZoneIndependent":
                        dateTimeBehavior = 3;
                        break;

                }
                const _attributes: ComponentFramework.PropertyHelper.FieldPropertyMetadata.DateTimeMetadata = {
                    Description: LocalizeLabel(field?.Description.LocalizedLabels),
                    DisplayName: LocalizeLabel(field?.DisplayName.LocalizedLabels),
                    LogicalName: field?.LogicalName ?? binding.value,
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null,
                    Format: field?.Format,
                    Behavior: dateTimeBehavior,
                    ImeMode: null
                };
                const _property: ComponentFramework.PropertyTypes.DateTimeProperty = {
                    raw: value,
                    error: formError || error,
                    errorMessage: formErrorMessage ?? (error ? window.TALXIS.Portal.Translations.getLocalizedString('@ComponentFramework/PropertyClasses/Context') : undefined),
                    type: fieldType,
                    formatted: null,
                    attributes: _attributes
                };
                parameters[property] = _property;
            }

        }
        else if (fieldType === DataType.WholeNone || fieldType === DataType.WholeDuration || fieldType === DataType.Decimal) {
            const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
            const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);
            const precision = field?.Precision ?? (bindings["Precision"]?.value ? +bindings["Precision"].value : null);
            let value = binding.isStatic ? binding.value : entity[binding.value];
            let error = false;
            if (typeof value === 'string') {
                error = true;
            }
            if (binding.isStatic) {
                parameters[property] = {
                    raw: value,
                    error: formError || error,
                    errorMessage: formErrorMessage ?? (error ? window.TALXIS.Portal.Translations.getLocalizedString('@ComponentFramework/PropertyClasses/Context') : undefined),
                    type: fieldType,
                    formatted: null,
                    attributes: {
                        Description: null,
                        DisplayName: null,
                        LogicalName: binding.value,
                        SourceType: null,
                        RequiredLevel: null,
                        IsSecured: null,
                        Precision: 2
                    } as ComponentFramework.PropertyHelper.FieldPropertyMetadata.Metadata
                };
            } else {
                parameters[property] = {
                    raw: value,
                    error: formError || error,
                    errorMessage: formErrorMessage ?? (error ? window.TALXIS.Portal.Translations.getLocalizedString('@ComponentFramework/PropertyClasses/Context') : undefined),
                    type: fieldType,
                    formatted: null,
                    attributes: {
                        Description: LocalizeLabel(field?.Description.LocalizedLabels),
                        DisplayName: LocalizeLabel(field?.DisplayName.LocalizedLabels),
                        LogicalName: field?.LogicalName ?? binding.value,
                        SourceType: null,
                        RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                        IsSecured: null,
                        Precision: precision
                    } as ComponentFramework.PropertyHelper.FieldPropertyMetadata.Metadata
                };
            }
        }
        else if (fieldType === DataType.Currency) {
            let currency: ITransactionCurrency = null;
            const currentCurrencyId = entity?.["transactioncurrencyid"]?.length > 0 && entity?.["transactioncurrencyid"]?.[0].id;
            if (currentCurrencyId) {
                currency = TransactionCurrencyDefinition.getCurrencyById(currentCurrencyId);
            }
            else {
                currency = TransactionCurrencyDefinition.getDefaultCurrency();
            }
            let value = binding.isStatic ? binding.value : entity[binding.value];
            let formattedValue = null;
            let error = false;
            //TODO: we need to unite the validation logic, this code is currently copied to three places: Context, Grid, Decimal control
            if (typeof value === 'number') {
                formattedValue = context.formatting.formatCurrency(value, currency.currencyprecision, currency.currencysymbol);
            }
            if (typeof value === 'string') {
                const _value = value.replace(/\s/g, '');
                const escapeRegExp = (string: string) => {
                    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                };
                const createCurrencyPattern = (pattern: string, numberPattern: string) => {
                    const escapedPattern = escapeRegExp(pattern);
                    const escapedCurrencySymbolPattern = `(${escapeRegExp(currency.currencysymbol)})?`;
                    const finalPattern = escapedPattern.replace('\\$', escapedCurrencySymbolPattern).replace('n', numberPattern);
                    return new RegExp(`^${finalPattern.replace(/\s/g, '')}$`);
                };
                Numeral.currency({
                    ...context.userSettings.numberFormattingInfo,
                    currencySymbol: currency.currencysymbol
                });
                const numberPattern = `\\d{1,}(${context.userSettings.numberFormattingInfo.currencyGroupSeparator}\\d{1,})*(\\${context.userSettings.numberFormattingInfo.currencyDecimalSeparator}\\d+)?`;
                const positivePattern = createCurrencyPattern(CURRENCY_POSITIVE_PATTERN[context.userSettings.numberFormattingInfo.currencyPositivePattern], numberPattern);
                const negativePattern = createCurrencyPattern(CURRENCY_NEGATIVE_PATTERN[context.userSettings.numberFormattingInfo.currencyNegativePattern], numberPattern);

                if (positivePattern.test(_value)) {
                    value = numeral(value).value();
                }
                else if (negativePattern.test(_value)) {
                    value = numeral(value).value();
                    if (value > 0) {
                        value = value * -1;
                    }
                }
                if (typeof value === 'string') {
                    error = true;
                    formattedValue = value;
                }
                else {
                    formattedValue = context.formatting.formatCurrency(value, currency.currencyprecision, currency.currencysymbol);
                }
            }
            if (binding.isStatic) {
                parameters[property] = {
                    raw: value,
                    error: formError || error,
                    errorMessage: formErrorMessage ?? (error ? window.TALXIS.Portal.Translations.getLocalizedString('@ComponentFramework/PropertyClasses/Context') : undefined),
                    type: fieldType ?? DataType.SingleLineText,
                    formatted: formattedValue,
                    attributes: {
                        Description: null,
                        DisplayName: null,
                        LogicalName: binding.value,
                        SourceType: null,
                        RequiredLevel: null,
                        IsSecured: null,
                        Precision: currency.currencyprecision
                    } as ComponentFramework.PropertyHelper.FieldPropertyMetadata.DecimalNumberMetadata
                };
            }
            else {
                const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
                const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);
                parameters[property] = {
                    raw: value,
                    type: fieldType,
                    error: formError || error,
                    errorMessage: formErrorMessage ?? (error ? window.TALXIS.Portal.Translations.getLocalizedString('@ComponentFramework/PropertyClasses/Context') : undefined),
                    formatted: formattedValue,
                    attributes: {
                        Description: LocalizeLabel(field?.Description.LocalizedLabels),
                        DisplayName: LocalizeLabel(field?.DisplayName.LocalizedLabels),
                        LogicalName: field?.LogicalName ?? binding.value,
                        SourceType: null,
                        RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                        IsSecured: null,
                        Precision: currency.currencyprecision

                    } as ComponentFramework.PropertyHelper.FieldPropertyMetadata.DecimalNumberMetadata
                };
            }
            if (entityChanges) {
                if (!binding.isStatic) {
                    entityChanges[binding.value] = value;
                }
                else {
                    entityChanges[controlProps.datafieldname] = value;
                }
            }
        }
        else if (fieldType === DataType.File) {
            if (!binding.isStatic) {
                const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
                const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);
                let fileObject: IFileObject | null = null;
                if (entity[entityDefinition.PrimaryIdAttribute] && entity[field.LogicalName]) {
                    const recordResponse = (await context.webAPI.retrieveRecord(entityName, entity[entityDefinition.PrimaryIdAttribute], `?$expand=${entityName}_FileAttachments($select=filesizeinbytes,filename,mimetype,regardingfieldname)`));
                    const apiFileObject = recordResponse?.[`${entityName}_FileAttachments`].find((x: { regardingfieldname: string; }) => x.regardingfieldname === field.LogicalName);
                    if (apiFileObject) {
                        fileObject = {
                            fileName: apiFileObject.filename,
                            fileSize: apiFileObject.filesizeinbytes,
                            mimeType: apiFileObject.mimetype,
                            fileId: entity[field.LogicalName]
                        };
                    }
                }
                parameters[property] = {
                    raw: fileObject,
                    type: fieldType,
                    error: formError,
                    errorMessage: formErrorMessage,
                    formatted: null,
                    attributes: {
                        Description: LocalizeLabel(field?.Description.LocalizedLabels),
                        DisplayName: LocalizeLabel(field?.DisplayName.LocalizedLabels),
                        LogicalName: field?.LogicalName ?? binding.value,
                        SourceType: null,
                        RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                        IsSecured: null,
                    }
                };
            }
        }
        else {
            const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
            const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);
            let error = false;
            let value = binding.isStatic ? binding.value : entity[binding.value];
            let typeOfField = fieldType;
            if (field?.FormatName?.Value === 'Phone') {
                typeOfField = DataType.SingleLinePhone;
            }
            if (typeof value === 'string') {
                switch (typeOfField) {
                    case DataType.SingleLineMail: {
                        if (!isEmail(value ?? "")) {
                            error = true;
                        }
                        break;
                    }
                    case DataType.SingleLineUrl: {
                        const len = value?.replace(/\s/g, '').length;
                        if (len === 0) {
                            error = true;
                            break;
                        }
                        if (!/^\w+:\/\//.test(value)) {
                            value = `https://${value}`;
                        }
                        break;
                    }
                }
            }
            if (binding.isStatic) {
                parameters[property] = {
                    raw: value,
                    error: formError || error,
                    errorMessage: formErrorMessage ?? (error ? window.TALXIS.Portal.Translations.getLocalizedString('@ComponentFramework/PropertyClasses/Context') : undefined),
                    type: typeOfField ?? DataType.SingleLineText,
                    formatted: binding.value,
                    attributes: {
                        Description: null,
                        DisplayName: null,
                        LogicalName: binding.value,
                        SourceType: null,
                        RequiredLevel: null,
                        IsSecured: null,
                    }
                };
            }
            else {
                const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
                const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);

                const formattedValue = entity[`${binding.value}@OData.Community.Display.V1.FormattedValue`];
                parameters[property] = {
                    raw: value,
                    error: formError || error,
                    errorMessage: formErrorMessage ?? (error ? window.TALXIS.Portal.Translations.getLocalizedString('@ComponentFramework/PropertyClasses/Context') : undefined),
                    type: typeOfField ?? DataType.SingleLineText,
                    formatted: formattedValue ?? entity[binding.value],
                    attributes: {
                        Description: LocalizeLabel(field?.Description.LocalizedLabels),
                        DisplayName: LocalizeLabel(field?.DisplayName.LocalizedLabels),
                        LogicalName: field?.LogicalName ?? binding.value,
                        SourceType: null,
                        RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                        IsSecured: null,
                        MaxLength: field?.MaxLength
                    } as ComponentFramework.PropertyHelper.FieldPropertyMetadata.StringMetadata
                };
            }
        }
        //we are currently only outputing errors for binding fields
        if (isBindingField) {
            onSetErrorMessage?.(parameters[property].errorMessage);
        }
    }

    // Add properties which weren't explicitly specified in form, but are in the control manifest
    const unboundProperties = manifest?.properties.filter(x => !Object.keys(parameters).includes(x.name));
    for (const unboundProperty of unboundProperties) {
        parameters[unboundProperty.name] = {
            raw: null,
            error: formError,
            errorMessage: formErrorMessage,
            type: unboundProperty.ofType,
            formatted: null,
        };
    }
    return parameters;
}
async function getDatasetParameters(
    grid: Grid,
    manifest: Manifest.Control
) {
    const parameters: { [propertyName: string]: ComponentFramework.PropertyTypes.DataSet } = {};
    for (const dataset of manifest.datasets) {
        const datasetParameter: ComponentFramework.PropertyTypes.DataSet = {
            refresh: () => grid.refresh(),
            clearSelectedRecordIds: () => grid.selectedRecordIds = [],
            getSelectedRecordIds: () => grid.selectedRecordIds,
            setSelectedRecordIds: (ids: string[]) => grid.selectedRecordIds = ids,
            getTitle: () => grid.title,
            getViewId: () => grid.viewId,
            getTargetEntityType: () => grid.entityName,
            openDatasetItem: (entityReference: ComponentFramework.EntityReference) => grid.openDatasetItem(entityReference),
            //@ts-ignore - not part of Xrm types
            retrieveRecordCommand: (...args) => grid.retrieveRecordCommand(...args),
            columns: grid.columns,
            error: false,
            errorMessage: null,
            filtering: grid.filtering,
            linking: grid.linking,
            loading: grid.loading,
            paging: grid.paging,
            records: grid.records,
            sortedRecordIds: grid.sortedRecordIds,
            get sorting(): ComponentFramework.PropertyHelper.DataSetApi.SortStatus[] {
                return grid.sorting;
            },
            set sorting(sortStatus: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[]) {
                grid.sorting = sortStatus;
            },
            addColumn: grid.addColumn
        };

        parameters[dataset.name] = datasetParameter;
    }
    return parameters;
}

export class Context implements ComponentFramework.Context<any> {
    client: ComponentFramework.Client;
    factory: ComponentFramework.Factory;
    formatting: ComponentFramework.Formatting;
    mode: ComponentFramework.Mode;
    navigation: ComponentFramework.Navigation;
    resources: ComponentFramework.Resources;
    userSettings: UserSettings;
    utils: ComponentFramework.Utility;
    webAPI: ComponentFramework.WebApi;
    parameters: { [propertyName: string]: ComponentFramework.PropertyTypes.Property | ComponentFramework.PropertyTypes.DataSet };
    updatedProperties: string[];
    device: ComponentFramework.Device;
    events: ComponentFramework.IEventBag;
    fluentDesignLanguage?: ComponentFramework.FluentDesignState;
    page: {
        entityTypeName: string;
        entityId: string;
    };

    constructor(props: IControlProps, controlDefinition: ICustomControl, entityName: string, state: State, render: () => void, entityId?: string, theme?: ComponentFramework.FluentDesignState) {
        this.resources = new Resources(props.name, controlDefinition);
        this.mode = new Mode(props, state);
        this.webAPI = new WebApi();
        this.navigation = new Navigation();
        this.utils = new Utility(props.formContext?.pageId);
        this.factory = new Factory(props.childeventlisteners, render, props.formContext?.pageId);
        this.client = new Client();
        this.userSettings = UserSettingsDefinition.getUserSettings();
        this.formatting = new Formatting(this.userSettings);
        this.fluentDesignLanguage = theme ?? ThemeDefinition.get().controlV9;
        this.page = {
            entityTypeName: entityName,
            entityId: entityId
        };
    }
    public static async createFieldContext(props: IControlProps, entityName: string, entity: ComponentFramework.WebApi.Entity, controlDefinition: ICustomControl, attributeConfiguration: { [name: string]: IAttributeConfiguration }, state: State, render: () => void, entityChanges?: ComponentFramework.WebApi.Entity, entityId?: string, updatedProperties?: string[], onSetErrorMessage?: (errorMessage: string) => void, errorMessage?: string, theme?: ComponentFramework.FluentDesignState): Promise<Context> {
        const context = new Context(props, controlDefinition, entityName, state, render, entityId, theme);
        context.parameters = await getBindingParameters(props, entityName, controlDefinition.manifest, props.bindings, entity, attributeConfiguration, context, entityChanges, onSetErrorMessage, errorMessage);
        context.updatedProperties = updatedProperties ?? [];

        return context;
    }
    public static async createRibbonContext(props: IControlProps) {
        const context = new Context(props, await props.definition.registration, null, null, () => {
            // ribbon does not currenlty support updateView 
            throw new Error('Not implemented!');
        }, undefined, ThemeDefinition.get().navbarControlV9);
        return context;
    }
    public static async createDatasetContext(
        grid: Grid,
        controlProps: IDatasetControlProps,
        controlDefinition: ICustomControl,
        state: State
    ): Promise<Context> {
        const context = new Context(controlProps, controlDefinition, grid.entityName, state, () => grid.render());
        const bindingParameters = await getBindingParameters(controlProps, grid.entityName, controlDefinition.manifest, controlProps.bindings, grid.formContext?.entity, {}, context);
        const datasetParameters = await getDatasetParameters(grid, controlDefinition.manifest);

        context.parameters = { ...bindingParameters, ...datasetParameters };
        return context;
    }

    //TODO: we need to create a proper implementation of this
    public static getBasicContext(): ComponentFramework.Context<any, any> {
        return {
            mode: {
                isControlDisabled: false
            },
            webAPI: window.Xrm.WebApi,
            navigation: window.Xrm.Navigation,
            utils: window.Xrm.Utility,
            userSettings: window.Xrm.Utility.getGlobalContext().userSettings,
            fluentDesignLanguage: ThemeDefinition.get().navbarControlV9,
            formatting: new Formatting(UserSettingsDefinition.getUserSettings())
        } as any;
    }
}