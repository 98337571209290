import { IWebManifest } from "@src/app/interfaces/manifest";
import { AppModule } from "../configuration/AppModule";
import { Manifest } from "../models/Manifest";
import { Theme } from "../models/Theme";

export interface IAppModuleManifest {
    baseAppModuleIcon: string;
    manifest: IWebManifest;
}

export class ManifestDefinition {
    private static _instance: Manifest;

    static getManifest() {
        if (!ManifestDefinition._instance) {
            throw new Error('No manifest has been created.');
        }
        return this._instance;
    }
    static isOpenedInPWA = (): boolean => {
        const standalone = '(display-mode: standalone)';
        const windowOverlay = '(display-mode: window-controls-overlay)';
        if (window.matchMedia(standalone).matches) {
            return true;
        }
        if (window.matchMedia(windowOverlay).matches) {
            return true;
        }
        return false;
    };
    static getLocallyStoredManifestForCurrentAppModule(): IAppModuleManifest {
        let appModuleUniqueName;
        if (ManifestDefinition.isOpenedInPWA()) {
            appModuleUniqueName = window.location.pathname.split('/')[1];
        }
        else {
            appModuleUniqueName = window.localStorage.getItem('appModuleName');
        }
        return JSON.parse(window.localStorage.getItem(`${appModuleUniqueName}_manifest`));
    }

    static createManifest(theme: Theme, currentAppModule: AppModule) {
        ManifestDefinition._instance = new Manifest(theme, currentAppModule);
    }
}