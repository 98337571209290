import React, { useEffect, useState, createContext, useRef } from 'react';
import { IComponent, IParameterDefinitionMap } from './interfaces/NestedPcf';
import { Form } from '@controls/native/Form/interfaces/form';
import { FormControlType } from '@controls/native/Form/interfaces/enums';
import { ControlLoader } from '@loaders/ControlLoader';
import { Control } from '../components/controls';
import { IFormContext } from '@controls/native/Form/interfaces/IFormContext';
import { IControlProps } from '@controls/interfaces';

interface INestedPcfWrapperProps {
    component: IComponent;
    pageId: string;
}

const mapParametersToBindings = (parameters: IParameterDefinitionMap): Form.ControlBindings => {
    const bindings: Form.ControlBindings = {};
    let TargetEntityType;
    let ViewId;
    for (const [key, value] of Object.entries(parameters)) {
        if (value.Type === 'Grid') {
            const _value = value as any;
            //Grid is a special type of parameter that does not follow typical typings
            bindings[key] = value as any;
            TargetEntityType = _value.TargetEntityType;
            ViewId = _value.ViewId;
            continue;
        }
        if (value.Static) {
            bindings[key] = {
                isStatic: value.Static,
                value: value.Value,
                type: value.Type,
                isRequired: false
            };
        }
        else {
            // This maps the value to either unknown type or entity bound field based on provided LogicalName in Attributes
            let mappedValue = value.Attributes?.LogicalName ?? key;
            bindings[key] = {
                isStatic: value.Static,
                value: mappedValue,
                type: value.Type,
                isRequired: false,
                attributes: {
                    targets: value.Attributes?.Targets
                }
            };
        }
    }
    //TODO: make it nicer
    if (!bindings.TargetEntityType && TargetEntityType) {
        bindings.TargetEntityType = {
            isStatic: true,
            value: TargetEntityType,
            type: "SingleLine.Text",
            isRequired: false
        };
    }
    if (!bindings.ViewId && ViewId) {
        bindings.ViewId = {
            isStatic: true,
            value: ViewId,
            type: "SingleLine.Text",
            isRequired: false
        };
    }

    return bindings;
};

const mapDynamicValuesFromParameters = (parameters: IParameterDefinitionMap): ComponentFramework.WebApi.Entity => {
    const entity: ComponentFramework.WebApi.Entity = {};

    for (const [key, value] of Object.entries(parameters)) {
        if (!value.Static) {
            entity[value.Attributes?.LogicalName ?? key] = value.Value;
        }
    }

    return entity;
};

const stripPcfNamePrefix = (name: string): string => {
    const delim = "_";
    return name.slice(name.indexOf(delim) + delim.length);
};

const getControlType = (parameters: IParameterDefinitionMap): FormControlType => {
    for (const [key, value] of Object.entries(parameters)) {
        if (value.Type === 'Grid') {
            return FormControlType.DataSet;
        }
    }
    return FormControlType.Field;
};

export const FormContext = createContext<IFormContext | null>(null);

export const NestedPcfWrapper: React.FC<INestedPcfWrapperProps> = (props) => {
    const [control, setControl] = useState<IControlProps>(null);
    const properties = props.component.getProperties();
    const [entityChanges, setEntityChanges] = useState<ComponentFramework.WebApi.Entity>({});
    const entityChangesRef = useRef<ComponentFramework.WebApi.Entity>({});

    useEffect(() => {
        const runAsync = async () => {
            const pcfName = stripPcfNamePrefix(props.component.getType());
            const control: IControlProps = {
                name: pcfName,
                type: getControlType(properties?.parameters),
                classId: null,
                id: props.component.getComponentId(),
                disabled: properties?.controlstates?.isControlDisabled ?? false,
                visible: true,
                isRequired: false,
                isUnbound: true,
                datafieldname: null,
                bindings: mapParametersToBindings(properties?.parameters),
                definition: await ControlLoader.getAsync(pcfName),
                childeventlisteners: properties?.childeventlisteners
            };

            entityChangesRef.current = mapDynamicValuesFromParameters(properties?.parameters);
            setEntityChanges(entityChangesRef.current);
            setControl(control);
        };

        runAsync();
    }, []);

    useEffect(() => {
        const parameters = properties?.parameters;
        if (!parameters || !control) return;
        for (const [key, value] of Object.entries(entityChanges)) {
            const parameter = parameters[key] || Object.values(parameters).find(x => x.Attributes?.LogicalName === key);
            if (parameter && parameter.Callback) {
                // TODO: We need to transform value to correct data type, since it all comes back as string
                parameter.Callback(value);
            }
        }
    }, [entityChanges]);

    const entityName = Object.values(properties?.parameters ?? {}).find(x => x.Primary === true)?.Attributes?.EntityLogicalName;
    return (
        <FormContext.Provider value={{
            entityId: null,
            entityName: entityName,
            entity: {},
            entityChanges: entityChangesRef.current,
            xrmExecutionContext: null,
            setEntityChanges: (entityChanges: ComponentFramework.WebApi.Entity) => {
                let changed = false;
                for (const [key, value] of Object.entries(entityChanges)) {
                    if (value != entityChangesRef.current[key]) {
                        changed = true;
                        break;
                    }
                }
                if (changed) {
                    entityChangesRef.current = { ...entityChangesRef.current, ...entityChanges };
                    setEntityChanges(entityChangesRef.current);
                }
            },
            onRecordSelect: null,
            onRecordsSelect: null,
            registerChildControl: null,
            isMobile: null,
            validate: false,
            attributeConfiguration: null,
            shouldRerender: 0,
            pageId: props.pageId
        }}>
            <div id={props.component.getComponentId()}>
                <FormContext.Consumer>
                    {(context) =>
                        control && <Control {...control} formContext={context} />
                    }
                </FormContext.Consumer>
            </div>
        </FormContext.Provider>
    );
};