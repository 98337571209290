import { UserSettingsDefinition } from "@src/app/classes/definitions/UserSettingsDefinition";

export interface ILocalizedLabel {
    LanguageCode: number;
    Label: string;
}

export const DEFAULT_LANGUAGE = 1029;
export const FALLBACK_LANGUAGE = 1033;

// TODO: This should be merged with TALXIX.Portal.Localization user language retrieval
export const LocalizeLabel = (localizedLabels: ILocalizedLabel[]): string | null => {
    if (!localizedLabels) return null;
    // TODO: This should also come from Xrm.Utility.getGlobalContext().userSettings.languageId but it is not initialized yet
    const userLanguage = UserSettingsDefinition.getUserSettings()?.languageId ?? DEFAULT_LANGUAGE;
    let label = localizedLabels.find(x => x.LanguageCode == userLanguage);

    if (!label) {
        label = localizedLabels.find(x => x.LanguageCode == FALLBACK_LANGUAGE);
    }
    if (!label) {
        label = localizedLabels[0];
    }

    return label?.Label;
};

export const getLocalizedString = (path: string): string => {
    return window.TALXIS.Portal.Translations.getLocalizedString(path);
};