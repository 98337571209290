export class Exception extends Error {
    constructor(msg: string, innerException?: Exception | Error) {
        super(msg);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, Exception.prototype);

        if (innerException) {
            if(innerException instanceof Exception) {
                this.InnerException = innerException;
            }
            else {
                this.InnerException = new Exception(innerException.message);
            }
        }
    }

    public InnerException: Exception;
    public GetInnerMostException(): Exception {
        if (this.InnerException) {
            return this.InnerException.GetInnerMostException();
        }
        return this;
    }

    public Print(): string {
        let output = this.message;
        if (this.InnerException) {
            output += "\r\n\r\nInner Exception>>    " + this.InnerException.Print();
        }
        return output;
    }
}